import CONFIG from '~/config'
import { MX_ERROR_STATE_WARNING_MESSAGES } from '~/features/BankAccountManagement/constants'

const createItemKey = item => `${item.uuid}-${item.errorCode}`

export const setBankIssueModalClosed = item => {
  localStorage.setItem(CONFIG.COOKIE_BANK_ISSUE_ALERT, createItemKey(item))
}

export const checkIsBankIssueModalClosed = item => {
  if (!item) return true

  return localStorage.getItem(CONFIG.COOKIE_BANK_ISSUE_ALERT) === createItemKey(item)
}

export const getBankIssueDetails = isBankLoading => {
  const title = 'Bank connection issue'
  let explanation

  if (isBankLoading) {
    explanation = ''
  } else {
    explanation = 'We encountered an issue with your bank account connection. Please relink your bank account to proceed.'
  }

  return { title, explanation }
}

export const getMXErrorStateWarningMessage = errorState => MX_ERROR_STATE_WARNING_MESSAGES[errorState]
