import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PROGRESS_BARS } from '~/constants'

import ProgressBar from '~/components/ui/ProgressBars'

const PageSteps = props => {
  const {
    currentStep,
    progress,
    hideOnMobile,
    steps,
    className,
    showRoundOnboardingPageSteps
  } = props

  return (
    <div
      className={classNames(
        `flex justify-center my-10 justify-between
        ${showRoundOnboardingPageSteps ? 'flex-col justify-center max-w-xs md:max-w-125 md:px-0 px-3' : ''} ${className}`,
        {
          'hidden md:flex': hideOnMobile
        }
      )}
      data-testid="stellar-page-steps"
    >
      {showRoundOnboardingPageSteps ? (
        <div className="w-full flex justify-center">
          <ProgressBar
            currentStep={currentStep}
            progress={progress}
            progressBar={PROGRESS_BARS.LINEAR_WITH_DOTS}
          />
        </div>
      ) : null}
      <div className={`${showRoundOnboardingPageSteps ? 'flex justify-between w-full' : 'flex justify-center w-full'}`}>
        {steps.map((step, index) => {
          const isActive = step.activeOnSteps.includes(currentStep)
          const isNextStepActive = index < steps.length - 1 && steps[index + 1].activeOnSteps.includes(currentStep)
          const lastStep = steps.length - 1 === index
          return showRoundOnboardingPageSteps ? (
            <span key={step.label} className={`md:text-sm text-xs text-deepspace-300 mt-1 ${lastStep ? 'mr-5' : ''}`}>
              {step.label}
            </span>
          ) : (
            <Fragment key={step.label}>
              <span className={`md:text-base text-sm ${isActive ? 'text-lightspeed font-semibold' : 'text-secondary'}`}>
                {step.label}
              </span>
              {index < steps.length - 1
                ? (
                  <FontAwesomeIcon
                    icon="fa-sharp fa-solid fa-play"
                    className={`md:mx-4 mx-2 md:mt-1.5 mt-1 text-xs font-semibold ${isActive || isNextStepActive ? 'text-lightspeed' : 'text-deepspace'}`}
                  />
                )
                : null}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
PageSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    activeOnSteps: PropTypes.arrayOf(PropTypes.number).isRequired,
  })).isRequired,
  progress: PropTypes.number,
  hideOnMobile: PropTypes.bool,
  className: PropTypes.string,
  showRoundOnboardingPageSteps: PropTypes.bool
}
PageSteps.defaultProps = {
  className: '',
  hideOnMobile: false,
  showRoundOnboardingPageSteps: false,
  progress: 0,
}

export default React.memo(PageSteps)
