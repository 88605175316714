import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import { ROUTES } from '~/pages/constants'
import { setRedirectUrl } from '~/store/modules/analytics'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'

const AuthRoute = props => {
  const { children } = props
  const location = useLocation()
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthorized)

  if (!isAuth && location.pathname !== ROUTES.CLOSE_ACCOUNT) {
    dispatch(setRedirectUrl(`${location.pathname}${location.hash}`))
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  }

  return children
}
AuthRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(AuthRoute)
