import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'

import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { FIRST_BILL_LINKED_NATIVE_APP_LAUNCH_MODAL_DISMISSED } from '~/services/segment'
import { CLOUDFLARE_IMAGE_IDS, MOBILE_APP_LINKS, MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'
import {
  getCloudflareImageURL,
  checkIsAndroidDevice,
  checkIsIphoneDevice,
  getMobileDeviceOS,
} from '~/utils'
import {
  useUpdateUserSettingsMutation,
} from '~/store/api'

import Modal from '~/components/ui/Modal/Desktop'
import Link from '~/components/ui/Link'

import { setHideFirstBillLinkedModalItem } from './utils'

const NativeAppLaunchModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()

  const trackFirstBillLinkedNativeAppLaunchModalDismissed = useSegmentEvents([FIRST_BILL_LINKED_NATIVE_APP_LAUNCH_MODAL_DISMISSED])

  const [updateUserSettings] = useUpdateUserSettingsMutation()

  const handleCloseModal = useCallback(async () => {
    const apiResult = await updateUserSettings({ dismissedDownloadAppModal: true })
    if (!apiResult.error) {
      trackFirstBillLinkedNativeAppLaunchModalDismissed({ os: getMobileDeviceOS() })
      setHideFirstBillLinkedModalItem()
      modal.remove()
      dispatch(removeModalFromQueue({ id: MODAL_IDS.NATIVE_APP_LAUNCH }))
    }
  }, [modal, dispatch, trackFirstBillLinkedNativeAppLaunchModalDismissed, updateUserSettings])

  const isAndroid = checkIsAndroidDevice()
  const isIphone = checkIsIphoneDevice()

  return (
    <Modal
      show
      size=""
      className="h-auto md:w-96 p-5"
      withCloseButton
      onClose={handleCloseModal}
      data-testid="stellar-dashboard-modals-native-app"
    >
      <h3 className="mb-1 mr-5 font-semibold text-center text-2xl">
        Congrats! You linked your first bill &#127881;
      </h3>
      <p className="mb-4">
        Download StellarFi in the Google Play store for an even better credit building experience!
      </p>
      {isAndroid ? (
        <>
          <div
            className="flex justify-center mb-4"
          >
            <Link
              href={MOBILE_APP_LINKS.GOOGLE_PLAY}
              rel="noreferrer"
              target="_blank"
              data-testid="stellar-dashboard-modals-native-app-android-link"
            >
              <img
                src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.GOOGLE_PLAY_BUTTON)}
                alt="google play btn"
                className="w-36 h-11"
              />
            </Link>
          </div>
          <Link
            href={MOBILE_APP_LINKS.GOOGLE_PLAY}
            rel="noreferrer"
            target="_blank"
            data-testid="stellar-dashboard-modals-native-app-google-link"
          >
            <img
              src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.MOBILE_PHONE_WITH_DASHBOARD_SCREEN_AND_BILLS)}
              alt="mobile phone"
              className="w-80 m-auto"
            />
          </Link>
        </>
      ) : null}
      {isIphone ? (
        <>
          <div className="flex justify-center mb-4">
            <Link
              href={MOBILE_APP_LINKS.APP_STORE}
              rel="noreferrer"
              target="_blank"
              data-testid="stellar-dashboard-modals-native-app-iphone-link"
            >
              <img
                src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.APP_STORE_BUTTON)}
                alt="google play btn"
                className="w-36 h-11"
              />
            </Link>
          </div>
          <Link
            href={MOBILE_APP_LINKS.APP_STORE}
            rel="noreferrer"
            target="_blank"
            data-testid="stellar-dashboard-modals-native-app-app-store-link"
          >
            <img
              src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.MOBILE_PHONE_WITH_DASHBOARD_SCREEN_AND_BILLS)}
              alt="mobile phone"
              className="w-80 m-auto"
            />
          </Link>
        </>
      ) : null}
    </Modal>
  )
}

export default NiceModal.create(NativeAppLaunchModal)
