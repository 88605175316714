import React from 'react'
import PropTypes from 'prop-types'

import { CLOUDFLARE_IMAGE_IDS, MOBILE_APP_LINKS } from '~/constants'
import {
  getCloudflareImageURL,
  checkIsAndroidDevice,
  checkIsIphoneDevice,
} from '~/utils'

import Card from '~/components/ui/Card'
import Link from '~/components/ui/Link'

const LaunchMobileAppCard = props => {
  const { inModal } = props

  const isAndroid = checkIsAndroidDevice()
  const isIphone = checkIsIphoneDevice()

  return (
    <Card
      className={`text-center ${inModal
        ? 'bg-white text-black shadow-white mb-0 pb-2 pt-3'
        : '!bg-deepspace-800 bg-center bg-pink-gradient bg-cover bg-no-repeat px-6 text-white mb-4 py-10'}`}
      id="mobile_app_launch"
      data-testid="stellar-launch-mobile-app-card"
    >
      <p className="text-4xl font-semibold leading-12">
        The StellarFi app is here
      </p>
      <p className="text-sm font-normal mt-2 mb-4 leading-6">
        Find StellarFi in the
        {isAndroid ? ' Google Play store ' : ''}
        {isIphone ? ' App Store ' : ''}
        for an even better credit building experience
      </p>
      {isAndroid ? (
        <Link
          href={MOBILE_APP_LINKS.GOOGLE_PLAY}
          rel="noreferrer"
          target="_blank"
          className="mb-5"
          data-testid="stellar-launch-mobile-app-android-link"
        >
          <img
            src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.GOOGLE_PLAY_BUTTON)}
            alt="google play btn"
            className="w-36 h-11"
          />
        </Link>
      ) : null}
      {isIphone ? (
        <Link
          href={MOBILE_APP_LINKS.APP_STORE}
          rel="noreferrer"
          target="_blank"
          className="mb-5"
          data-testid="stellar-launch-mobile-app-iphone-link"
        >
          <img
            src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.APP_STORE_BUTTON)}
            alt="app store btn"
            className="w-36 h-11"
          />
        </Link>
      ) : null}
    </Card>
  )
}

LaunchMobileAppCard.propTypes = {
  inModal: PropTypes.bool,
}

LaunchMobileAppCard.defaultProps = {
  inModal: false,
}

const Memoized = React.memo(LaunchMobileAppCard)
export default Memoized
