import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  useFetchUserQuery,
} from '~/store/api'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'
import { ROUTES } from '~/pages/constants'
import { checkIsLockedNotCollectable } from '~/utils'

export const useCheckUserAccountStatus = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isAuth = useSelector(selectIsUserAuthorized)

  const {
    data = {},
  } = useFetchUserQuery(undefined, { skip: !isAuth })
  const { user } = data

  const isLockedNotCollectable = checkIsLockedNotCollectable(user)

  // if user account is locked we force redirect to the corresponding page
  useEffect(() => {
    if (
      user
      && isLockedNotCollectable
      && location.pathname !== ROUTES.LOCKED_ACCOUNT
    ) {
      navigate(ROUTES.LOCKED_ACCOUNT)
    }
  }, [user, location.pathname, navigate, isLockedNotCollectable])

  return null
}
