import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.scss'

const RadioButton = props => {
  const {
    id,
    label,
    className,
    labelClassName,
    variant,
    checked,
    disabled,
    onChange,
    ...inputProps
  } = props

  const handleChange = useCallback(event => {
    onChange(event.target.value)
  }, [onChange])

  return (
    <label
      htmlFor={id}
      className={`
        ${styles.label} ${className}
        ${variant === 'outlined' ? styles.outlined : null}
        ${disabled ? styles.disabled : null}
      `}
    >
      <input
        type="radio"
        id={id}
        className={styles.radio}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        {...inputProps}
      />
      <span className={`${styles.text} ${labelClassName}`}>
        {label}
      </span>
    </label>
  )
}
RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
}
RadioButton.defaultProps = {
  className: '',
  labelClassName: '',
  variant: '',
  disabled: false,
  'data-testid': '',
}

export default React.memo(RadioButton)
