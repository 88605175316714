import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  BACKGROUNDS, NOTIFICATION_EVENTS,
} from '~/features/Headers/App/constants'
import { getDueDateDiff, getDateTime } from '~/utils'

const NotificationsListItem = props => {
  const {
    notification,
    hasReadNotifications,
    index,
  } = props
  const alertClassNames = classNames('w-1.5 h-1.5 inline-block rounded mr-1 -mt-1', {
    'bg-red': !hasReadNotifications && !notification.readAt
  })

  const { event } = notification

  const backgroundColor = event === NOTIFICATION_EVENTS.BILL_PAID ? BACKGROUNDS.lightspeed : BACKGROUNDS.orange

  return (
    <div
      className={classNames('py-3 flex items-center', { 'border-t  border-gray': index > 0 })}
      data-testid="stellar-notifications-item"
    >
      <span className={alertClassNames} />
      <div className="flex items-start flex-1">
        <div className={classNames('bg-opacity-10 flex items-center justify-center h-6 w-6 rounded-full mr-2 mt-1', backgroundColor)}>
          <FontAwesomeIcon
            icon={`far fa-${notification.iconName}`}
            className={classNames('w-4 h-4', {
              [`text-${notification.iconColorName}`]: Boolean(notification.iconColorName)
            })}
          />
        </div>
        <div className="mr-3">
          <span className="font-semibold text-sm block">{notification.title}</span>
          <span className="text-blue text-xs block">{notification.description}</span>
        </div>
        <div className="ml-auto">
          <span className="text-xs text-blue">
            {getDueDateDiff(notification.createdAt, getDateTime('now')).text}
          </span>
        </div>
      </div>
    </div>
  )
}
NotificationsListItem.propTypes = {
  notification: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readAt: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    event: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    iconColorName: PropTypes.string,
  }).isRequired,
  hasReadNotifications: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default React.memo(NotificationsListItem)
