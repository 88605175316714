import { uuidv4 } from '~/utils/uuid'
import CONFIG from '~/config'

const setAnonymousId = () => {
  const newId = uuidv4()
  localStorage.setItem(CONFIG.COOKIE_ANONYMOUS_ID, newId)
  return newId
}

export const getAnonymousId = () => localStorage.getItem(CONFIG.COOKIE_ANONYMOUS_ID) || setAnonymousId()
