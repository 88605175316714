export const checkChromeBrowser = () => navigator.userAgent.indexOf('Chrome') > -1

export const checkSafariBrowser = () => {
  // Detect Safari
  let safariAgent = navigator.userAgent.indexOf('Safari') > -1

  // Discard Safari if it also matches Chrome
  if (checkChromeBrowser()) {
    safariAgent = false
  }

  return safariAgent
}

export const checkIsAndroidDevice = () => (/android/).test(navigator.userAgent.toLowerCase())
export const checkIsIphoneDevice = () => (/iphone/).test(navigator.userAgent.toLowerCase())

export const getMobileDeviceOS = () => {
  if (checkIsAndroidDevice()) {
    return 'android'
  }

  if (checkIsIphoneDevice()) {
    return 'ios'
  }

  return 'unknown'
}
