import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import { useUpdateUserSettingsMutation } from '~/store/api'

import MobileModal from '~/components/ui/Modal/Mobile'
import Modal from '~/components/ui/Modal'

import Content from './Content'
import { setDismissedCardActivationSuccessModal } from './utils'

const CardActivationSuccessModal = () => {
  const modal = useModal()
  const location = useLocation()
  const navigate = useNavigate()
  const [updateUserSettings] = useUpdateUserSettingsMutation()

  const handleCloseModal = useCallback(async () => {
    setDismissedCardActivationSuccessModal()
    await updateUserSettings({ dismissedCardActivationSuccessModal: true })
    modal.remove()
    navigate(location.pathname)
  }, [modal, navigate, location, updateUserSettings])

  const handleScrollToAnchorTag = useCallback(async () => {
    setDismissedCardActivationSuccessModal()
    modal.remove()
    navigate(`${location.pathname}#linked_bills`)
  }, [modal, navigate, location])

  const isMobile = useSelector(selectIsMobile)

  return (
    <>
      {isMobile ? (
        <MobileModal
          show
          size="lg"
          withCloseButton
          onClose={handleCloseModal}
          className="rounded-xl p-6"
        >
          <Content handleScrollToAnchorTag={handleScrollToAnchorTag} handleCloseModal={handleCloseModal} />
        </MobileModal>
      ) : (
        <Modal
          show
          size="lg"
          withCloseButton
          onClose={handleCloseModal}
          className="rounded-xl p-6"
        >
          <Content handleScrollToAnchorTag={handleScrollToAnchorTag} handleCloseModal={handleCloseModal} />
        </Modal>
      )}
    </>
  )
}

export default NiceModal.create(CardActivationSuccessModal)
