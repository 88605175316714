import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LineController,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from 'chart.js'

// by registering only used elements we enable tree shaking to decrease the bundle size
ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
)
