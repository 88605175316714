import React from 'react'
import PropTypes from 'prop-types'

import Card from '~/components/ui/Card'

import {
  CLOUDFLARE_IMAGE_IDS
} from '~/constants'
import {
  getCloudflareImageURL,
} from '~/utils'

import Title from './Title'
import Chart from './Chart'

const CreditScoreProjection = props => {
  const {
    user,
    chartOnly,
    projections,
    isCreditScoreProjectionAllowed,
    inHeader
  } = props

  return isCreditScoreProjectionAllowed ? (
    <div data-testid="stellar-credit-score-projection">
      {chartOnly ? (
        <Chart
          user={user}
          projections={projections}
          headerMode
        />
      ) : (
        <Card
          className="mb-4 md:mb-5"
          data-testid="stellar-credit-score-projection-card"
        >
          <Title
            user={user}
          />
          <Chart
            user={user}
            projections={projections}
          />
        </Card>
      )}
    </div>
  ) : (
    <>
      {inHeader ? null : (
        <Card className="p-0">
          <img
            src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.EMPTY_SCORE_PROJECTION)}
            alt="score projection"
            className="mb-5"
          />
        </Card>
      )}
    </>
  )
}

CreditScoreProjection.propTypes = {
  user: PropTypes.shape({
    credit: PropTypes.shape({}),
    status: PropTypes.string.isRequired,
  }).isRequired,
  projections: PropTypes.arrayOf(PropTypes.shape({
    baseline: PropTypes.number.isRequired,
    lowerLimit: PropTypes.number.isRequired,
    upperLimit: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  chartOnly: PropTypes.bool,
  isCreditScoreProjectionAllowed: PropTypes.bool.isRequired,
  inHeader: PropTypes.bool.isRequired
}

CreditScoreProjection.defaultProps = {
  chartOnly: false,
}

export default React.memo(CreditScoreProjection)
