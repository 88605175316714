export const checkFeatureFlags = (myFlags = {}, flags = [], any = false) => {
  const requiredFlags = Array.isArray(flags) ? flags : Array.of(flags)

  if (!flags || !flags.length) {
    return true
  }

  return requiredFlags[any ? 'some' : 'every'](featureFlag => {
    const has = typeof featureFlag === 'function' ? featureFlag(myFlags) : Boolean(myFlags?.[featureFlag])
    return has
  })
}

export const checkPermissions = (myPermissions, permissions = {}, any = false) => {
  if (!permissions || !Object.keys(permissions).length) {
    return true
  }

  return Object.entries(permissions)[any ? 'some' : 'every'](([permissionFlagKey, permissionFlagValue]) => {
    if (typeof permissionFlagValue === 'function') {
      return permissionFlagValue(myPermissions)
    }

    if (typeof permissionFlagValue === 'string') {
      return myPermissions[permissionFlagKey] === permissionFlagValue
    }

    if (typeof permissionFlagValue === 'boolean') {
      return myPermissions[permissionFlagKey] === permissionFlagValue
    }

    return true
  })
}
