const getSpendLimitEndpoints = builder => ({
  getSpendLimitReport: builder.query({
    query: (params = {}) => ({
      url: '/spend_limits',
      params,
    }),
  }),
  getSpendLimitHistory: builder.query({
    query: (params = {}) => ({
      url: '/spend_limits/history',
      params,
    }),
  }),
  getSpendLimitReportingHistory: builder.query({
    query: (params = {}) => ({
      url: '/credit_reporting_history',
      params,
    }),
  }),
})

export default getSpendLimitEndpoints
