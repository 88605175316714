import React from 'react'
import { useSelector } from 'react-redux'

import { selectIsMobile } from '~/store/modules/dimensions/selectors'

import MobileFooter from './Mobile'

const AppFooter = () => {
  const isMobile = useSelector(selectIsMobile)

  return isMobile ? (
    <MobileFooter />
  ) : null
}

const Memoized = React.memo(AppFooter)
export default Memoized
