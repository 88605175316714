import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { CLOUDFLARE_IMAGE_IDS } from '~/constants'
import { ROUTES } from '~/pages/constants'
import { getCloudflareImageURL } from '~/utils'
import Link from '~/components/ui/Link'
import StandardLink from '~/components/ui/Links/StandardLink'

// TODO: refactor this component
const SiteHeader = props => {
  const {
    hasBg,
    className,
  } = props
  const location = useLocation()

  const {
    showSignupButton,
    hideLoginButton,
    showLogoutButton,
  } = useMemo(() => ({
    showSignupButton: location.pathname === ROUTES.LOGIN,
    hideLoginButton: [
      ROUTES.CLOSED_ACCOUNT,
      ROUTES.VERIFY_LOGIN,
      ROUTES.VERIFY_LOGIN_WITH_EMAIL,
    ].includes(location.pathname),
    showLogoutButton: [
      ROUTES.VERIFY_LOGIN,
      ROUTES.VERIFY_LOGIN_WITH_EMAIL
    ].includes(location.pathname),
  }), [location.pathname])

  return (
    <header
      className={
        classNames(className, {
          'md:py-4 py-6 px-6 w-full z-10 top-0 absolute': !className,
        })
      }
    >
      <div className="flex justify-between relative">
        <span className="mr-10" />
        <StandardLink to={ROUTES.ROOT} className="flex justify-center absolute left-0 right-0 -top-1.5 mx-auto">
          <img
            className="h-10"
            src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.LOGO)}
            alt="stellar logo"
          />
        </StandardLink>
        {showSignupButton ? (
          <StandardLink
            to={ROUTES.SIGNUP}
            size="md"
            className={`no-underline font-normal ${hasBg ? 'text-white' : 'text-default'} !font-bold`}
          >
            Sign up
          </StandardLink>
        ) : (
          <>
            {!hideLoginButton ? (
              <Link
                to={ROUTES.LOGIN}
                size="md"
                className={`no-underline font-normal ${hasBg ? 'text-white' : 'text-default'} !font-bold !text-default pl-4 z-20`}
              >
                Login
              </Link>
            ) : null}
          </>
        )}
        {showLogoutButton ? (
          <Link
            to={ROUTES.LOGOUT}
            size=""
            className="!font-bold !text-white absolute right-0"
          >
            Log Out
          </Link>
        ) : null}
      </div>
    </header>
  )
}

SiteHeader.defaultProps = {
  hasBg: true,
  className: '',
}

SiteHeader.propTypes = {
  hasBg: PropTypes.bool,
  className: PropTypes.string,
}

export default React.memo(SiteHeader)
