/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/**
 * Transforms object with dashed keys to camel keys
 */
export function toCamel(o) {
  let newO; let origKey; let newKey; let
    value
  if (o instanceof Array) {
    return o.map(value => {
      if (typeof value === 'object') {
        value = toCamel(value)
      }
      return value
    })
  }
  newO = {}
  for (origKey in o) {
    if (o.hasOwnProperty(origKey)) {
      newKey = origKey.replace(/([-_][a-z])/g, group => group
        .toUpperCase()
        .replace('_', ''))

      value = o[origKey]
      if (value instanceof Array || (value !== null && value.constructor === Object)) {
        value = toCamel(value)
      }
      newO[newKey] = value
    }
  }

  return newO
}
