import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CollapsibleCard = props => {
  const { title, expandByDefault, children, className, iconClassName, titleClassName } = props

  const [isExpanded, setIsExpanded] = useState(expandByDefault)

  const toggleExpanded = useCallback(() => setIsExpanded(prev => !prev), [setIsExpanded])

  return (
    <div
      role="button"
      tabIndex={0}
      aria-hidden="true"
      onClick={toggleExpanded}
      className={`border-b border-gray last:border-none py-4 ${className}`}
      data-testid={props['data-testid']}
    >
      <div className={`flex items-center ${titleClassName}`}>
        {title}
        <span className={`${classNames('transform ml-auto', { 'rotate-180': isExpanded })} ${iconClassName}`}>
          <FontAwesomeIcon icon="far fa-angle-down" />
        </span>

      </div>
      {isExpanded ? (
        <div className="mt-4">{children}</div>
      ) : null}
    </div>
  )
}

CollapsibleCard.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  expandByDefault: PropTypes.bool,
  'data-testid': PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  titleClassName: PropTypes.string,
}

CollapsibleCard.defaultProps = {
  title: 'Add a title',
  children: null,
  expandByDefault: false,
  'data-testid': '',
  className: '',
  iconClassName: '',
  titleClassName: '',
}

const Memoized = React.memo(CollapsibleCard)
export default Memoized
