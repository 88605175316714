import { COLORS } from '~/constants'
import { getDateTime, changeRgbaAlpha } from '~/utils'

// Determine the background color based on the mode
const getBackgroundColor = (headerMode, realData) => {
  if (headerMode) {
    return realData ? COLORS.GRADIENT_START : COLORS.FAKE_DATA_XAXIS
  }
  return COLORS.TRANSPARENT
}

export const getChartOptions = (realData = false, headerMode = false) => ({
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: 0,
  },
  scales: {
    y: {
      display: !headerMode,
      grid: {
        display: false,
      },
      ticks: {
        callback: (value, index) => (index % 2 ? '' : value),
        color: COLORS.DEEPSPACE_DEFAULT,
        font: {
          weight: 600,
        }
      },
      title: {
        text: '580',
      },
    },
    x: {
      backgroundColor: getBackgroundColor(headerMode, realData),
      grid: {
        borderWidth: headerMode ? 0 : 3,
        tickColor: COLORS.TRANSPARENT,
      },
      ticks: headerMode ? {
        align: 'center',
        padding: 10,
        color: COLORS.WHITE,
        font: {
          weight: 600,
        },
      } : {
        color: COLORS.DEEPSPACE_DEFAULT,
        font: {
          weight: 600,
          size: 11
        },
      },
    },
  },
  elements: {
    point: {
      pointStyle: 'dash',
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    chartAreaBorder: {
      borderColor: COLORS.BORDER,
      borderWidth: headerMode ? 0 : 2,
    },
    chartAreaBorderBottom: {
      borderBottomColor: 'rgba(0,0,0,1.0)',
      borderBottomWidth: 1,
    },
  },
})

const calculatePreAndPost = arr => {
  const pre = arr[0] - (arr[1] - arr[0])
  const post = arr[arr.length - 1] + arr[arr.length - 1] - arr[arr.length - 2]
  return [pre, ...arr, post]
}

export const getScoreProjectionChartData = (projections, gradients, pointPositionIndex, realData = false, headerMode = false) => {
  const projectionBaseline = calculatePreAndPost(projections.map(projection => projection.baseline))
  const projectionLowerLimit = calculatePreAndPost(projections.map(projection => projection.lowerLimit))
  const projectionUpperLimit = calculatePreAndPost(projections.map(projection => projection.upperLimit))

  const labels = projectionBaseline.map((_, index) => {
    if (index === 0 || index === projectionBaseline.length - 1) return ' '

    const dateTime = getDateTime('now').plus({ month: index - 1 })
    return dateTime.monthShort.substring(0, 3).toUpperCase()
  })

  function determineValues(isData, isMobile, options) {
    if (isData) {
      return isMobile ? options.realDataHeaderMode : options.realData
    }
    return options.default
  }

  const upperLimitBorderColorOptions = {
    realDataHeaderMode: changeRgbaAlpha(COLORS.UPPER_LIMIT_HEADER, 0.7),
    realData: changeRgbaAlpha(COLORS.UPPER_LIMIT, 0),
    default: COLORS.STEEL_DEFAULT
  }

  const pointBackgroundColorOptions = {
    realDataHeaderMode: changeRgbaAlpha(COLORS.POINT_BG_HEADER, 1),
    realData: changeRgbaAlpha(COLORS.POINT_BG, 1),
    default: COLORS.WHITE
  }

  const lowerLimitBorderColorOptions = {
    realDataHeaderMode: COLORS.TRANSPARENT,
    realData: changeRgbaAlpha(COLORS.GRADIENT, 0.0),
    default: COLORS.TRANSPARENT
  }

  const borderColorOptions = {
    realDataHeaderMode: changeRgbaAlpha(COLORS.LINE_HEADER, 1),
    realData: changeRgbaAlpha(COLORS.LINE, 1),
    default: COLORS.FAKE_DATA
  }

  const radius = headerMode ? 6 : 5

  let lowerLimitBackground
  if (headerMode) {
    if (realData) {
      lowerLimitBackground = gradients.lowerMobile
    } else {
      lowerLimitBackground = COLORS.STEEL_DEFAULT
    }
  } else if (realData) {
    lowerLimitBackground = changeRgbaAlpha(COLORS.WHITE, 0.4)
  } else {
    lowerLimitBackground = COLORS.STEEL_DEFAULT
  }

  return {
    labels,
    datasets: [
      // actual projection line with light background
      {
        type: 'line',
        fill: true,
        label: 'Score projection line',
        data: projectionBaseline,
        borderColor: determineValues(realData, headerMode, borderColorOptions),
        borderWidth: headerMode ? 2 : 3,
        backgroundColor: realData ? gradients.mobile : COLORS.STEEL_DEFAULT,
        pointStyle: 'circle',
        pointRadius: projectionBaseline.map((projection, index) => (pointPositionIndex(projection, index) ? radius : 0)),
        pointBackgroundColor: determineValues(realData, headerMode, pointBackgroundColorOptions),
        pointBorderWidth: projectionBaseline.map(() => 3.5)
      },
      // lower limit (area)
      {
        type: 'line',
        fill: true,
        label: 'Score projection lower limit',
        data: projectionLowerLimit,
        borderColor: determineValues(realData, headerMode, lowerLimitBorderColorOptions),
        backgroundColor: lowerLimitBackground
      },
      // upper limit line (area)
      {
        type: 'line',
        fill: true,
        label: 'Score projection upper limit',
        data: projectionUpperLimit,
        borderColor: determineValues(realData, headerMode, upperLimitBorderColorOptions),
        backgroundColor: realData ? gradients.upperMobile : COLORS.STEEL_DEFAULT,
        borderWidth: headerMode ? 1 : 0,
      },
    ]
  }
}
