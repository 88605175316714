import { ROUTES } from '~/pages/constants'

export const getHeaderPadding = pathname => {
  if (pathname.split('/').includes('goals')) {
    if (!pathname.split('/').includes('new')) return true
  }

  if (pathname.split('/').includes('credit-tools')) return true
  if (pathname.split('/').includes('settings')) return true

  if ([
    ROUTES.DASHBOARD,
    ROUTES.SPEND_LIMIT,
    ROUTES.REWARDS,
    ROUTES.REWARD_BADGES,
    ROUTES.REWARD_REDEEM,
    ROUTES.REWARD_REDEEM_CONFIRM,
    ROUTES.REWARD_REDEEM_SUCCESS,
    ROUTES.PAYMENT_HISTORY,
    ROUTES.MANAGE_ACCOUNT,
    ROUTES.PAUSE_ACCOUNT,
    ROUTES.CLOSE_ACCOUNT,
    ROUTES.BANK_BALANCE
  ].includes(pathname)) return true

  return false
}
