import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import NiceModal from '@ebay/nice-modal-react'

import { selectFirstModalInQueue } from '~/store/modules/ui/selectors'

export const useModalManagement = () => {
  const location = useLocation()
  const firstModalInQueue = useSelector(selectFirstModalInQueue)

  // hash-based modals are top priority to show
  useEffect(() => {
    if (location.hash) {
      const hashValue = location.hash.split('#')[1]
      if (hashValue && hashValue.startsWith('/')) {
        NiceModal.show(hashValue)
      }
    }
  }, [location.hash])

  useEffect(() => {
    if (firstModalInQueue && !location.hash) {
      NiceModal.show(firstModalInQueue.id, firstModalInQueue.props)
    }
  }, [firstModalInQueue, location.hash])

  return null
}
