import React from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from '~/utils'

const AccountSelectOptionLabel = props => {
  const { account } = props

  return (
    <div className="flex justify-between font-normal">
      <div>
        {account.accountName}
        <span className="ml-2 font-semibold">
          <span className="text-sm">&#9679;&#9679;&#9679;&#9679;</span>
          {account.accountMask}
        </span>
      </div>

      <div className="justify-self-end font-semibold">
        {formatCurrency(account.balance)}
      </div>
    </div>
  )
}

AccountSelectOptionLabel.propTypes = {
  account: PropTypes.shape({
    accountName: PropTypes.string,
    accountMask: PropTypes.string,
    balance: PropTypes.number
  }).isRequired
}

const Memoized = React.memo(AccountSelectOptionLabel)
export default Memoized
