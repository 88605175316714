import {
  USER_TAG_TYPE,
  INACTIVE_BANK_ACCOUNTS
} from '~/store/api/constants'

const getBankAccountEndpoints = builder => ({
  getLinkToken: builder.query({ // TODO: was createLinkToken
    query: (params = {}) => ({
      url: '/bank_accounts/link_token',
      params
    }),
  }),
  linkBankAccount: builder.mutation({
    query: body => ({
      url: '/bank_accounts',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, INACTIVE_BANK_ACCOUNTS]),
  }),
  relinkBankAccount: builder.mutation({
    query: plaidItemUuid => ({
      url: `/bank_accounts/${plaidItemUuid}/relink`,
      method: 'PUT',
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, INACTIVE_BANK_ACCOUNTS]),
  }),
  getWidgetUrl: builder.query({
    query: (params = {}) => ({
      url: '/mx_bank_accounts/widget_url',
      params
    })
  }),
  fetchAccountTypesRequest: builder.query({
    query: () => '/mx_bank_accounts'
  }),
  linkMxBankAccount: builder.mutation({
    query: body => ({
      url: '/mx_bank_accounts',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, INACTIVE_BANK_ACCOUNTS]),
  }),
  connectPrimaryAccount: builder.mutation({
    query: primaryAccountUuid => ({
      url: `mx_bank_accounts/${primaryAccountUuid}/primary`,
      method: 'PUT'
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, INACTIVE_BANK_ACCOUNTS]),
  }),
  fetchInactiveBankAccounts: builder.query({
    query: () => 'bank_accounts/inactive',
    providesTags: [INACTIVE_BANK_ACCOUNTS],
  }),
})

export default getBankAccountEndpoints
