import { UNLEASH_URL_SEARCH_PARAM_PREFIX } from '~/constants/experiments'

export const getExperimentURLSearchParams = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const params = {}
  searchParams.forEach((v, k) => {
    if (k.startsWith(UNLEASH_URL_SEARCH_PARAM_PREFIX)) params[k] = v
  })
  return params
}
