export const prepareSnackbarMessage = (message, isMobile) => {
  const position = isMobile ? {
    ...message.position,
    vertical: 'bottom',
    horizontal: 'center'
  } : {
    ...message.position,
    vertical: 'top',
    horizontal: 'right',
  }

  return ({
    ...message,
    position
  })
}
