import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { EXPERIMENTS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'

import { useFetchUserQuery } from '~/store/api'
import RelinkPlaidLink from '~/features/SnackbarMessageQueue/CustomMessages/RelinkBankAccount/RelinkPlaidLink'
import RelinkMxLink from '~/features/SnackbarMessageQueue/CustomMessages/RelinkBankAccount/RelinkMxLink'
import { dismissSnackbarMessage } from '~/store/modules/snackbar'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'
import { checkIsMXBankProvider, getBankProvider } from '~/utils/user'

const RelinkBankAccount = props => {
  const {
    snackbarMessage,
  } = props

  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsUserAuthorized)

  const {
    data = {},
  } = useFetchUserQuery(undefined, { skip: !isAuth })
  const { user = {} } = data

  const { enabled: isMxM2Enabled } = useExperiment(EXPERIMENTS.MX_M2)
  const { enabled: isMxShutdownEnabled } = useExperiment(EXPERIMENTS.MX_SHUTDOWN, false)
  const isMXBankProvider = checkIsMXBankProvider(getBankProvider(isMxShutdownEnabled, user.bankProviderOverride, isMxM2Enabled))

  const onPlaidSuccess = useCallback(() => {
    dispatch(dismissSnackbarMessage(snackbarMessage.key))
  }, [snackbarMessage.key, dispatch])

  return (
    <span>
      Please
      {' '}
      {isMXBankProvider ? (
        <RelinkMxLink
          className="text-deepspace hover:underline bg-transparent"
          title="relink your bank account."
        />
      ) : (
        <RelinkPlaidLink
          onSuccess={onPlaidSuccess}
          plaidItemUuid={user?.accounts?.[0]?.blocked ? '' : user?.accounts?.[0]?.uuid}
          className="text-deepspace hover:underline bg-transparent"
          title="relink your bank account."
        />
      )}

    </span>
  )
}
RelinkBankAccount.propTypes = {
  snackbarMessage: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    accounts: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string
    }))
  }).isRequired,
}

const Memoized = React.memo(RelinkBankAccount)
export default Memoized
