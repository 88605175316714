import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import Tippy from '@tippyjs/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { MODAL_IDS } from '~/constants'
import { addModalToQueue } from '~/store/modules/ui'
import Button from '~/components/ui/Button'

const Tooltip = props => {
  const {
    header,
    description,
    tooltipLabel,
    tipPlacement,
    children,
    interactive,
    ctaProps,
    'data-testid': testId,
    onOpen,
  } = props

  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handleTooltipModal = useCallback(() => {
    if (onOpen) {
      onOpen()
    }
    dispatch(addModalToQueue({ id: MODAL_IDS.TOOLTIP_MODAL, props: { header, description, children, ctaProps } }))
  }, [onOpen, dispatch, header, description, children, ctaProps])

  return (
    <>
      {isMobile ? (
        <>
          {tooltipLabel ? (
            <Button
              variant=""
              className="underline !font-bold my-7"
              onClick={handleTooltipModal}
            >
              {tooltipLabel}
            </Button>
          ) : (
            <FontAwesomeIcon
              icon="far fa-circle-info"
              className={`text-deepspace ml-1 h-4 cursor-pointer ${props.className}`}
              data-testid={`${testId}-mobile`}
              onClick={handleTooltipModal}
            />
          )}
        </>
      ) : (
        <Tippy
          arrow={false}
          interactive={interactive}
          className="bg-white border-moonrock border p-1 rounded-md"
          placement={tipPlacement}
          onShow={onOpen}
          content={React.isValidElement(description) ? description : (
            <>
              {description ? (
                <p className="text-black font-normal w-72">
                  {description}
                </p>
              ) : children }
            </>
          )}
        >
          {tooltipLabel ? (
            <Button
              variant=""
              className="underline !font-bold my-7"
              onClick={handleTooltipModal}
            >
              {tooltipLabel}
            </Button>
          ) : (
            <FontAwesomeIcon
              icon="far fa-circle-info"
              className="ml-1 h-4"
              data-testid={testId}
            />
          )}
        </Tippy>
      )}
    </>
  )
}

Tooltip.propTypes = {
  tooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  header: PropTypes.string,
  tipPlacement: PropTypes.string,
  interactive: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  ctaProps: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func
  }),
  'data-testid': PropTypes.string,
  onOpen: PropTypes.func,
}

Tooltip.defaultProps = {
  tooltipLabel: '',
  description: '',
  header: '',
  tipPlacement: 'bottom',
  className: '',
  children: '',
  interactive: false,
  ctaProps: null,
  'data-testid': '',
  onOpen: () => null,
}

export default Tooltip
