import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const getContainerClassNames = (position, isMobile) => (
  classNames(
    'fixed w-screen flex z-10',
    isMobile ? 'px-2' : 'px-20',
    {
      // positions
      'top-24': position?.vertical === 'top' || !position?.vertical,
      'bottom-3.5 md:bottom-36 lg:bottom-11': position?.vertical === 'bottom',
      'justify-end': (position?.horizontal === 'right' || !position?.horizontal) && position?.horizontal !== 'center',
      'justify-center': position?.horizontal === 'center',
      'justify-start': position?.horizontal === 'left',
    }
  )
)

export const getMessageClassNames = (status, isMobile) => (
  classNames(
    'border rounded-lg flex items-center text-normal',
    isMobile ? 'py-3 px-4' : 'py-4 px-6',
    {
      // colors
      'border-deepspace bg-deepspace-50': status === 'information',
      'border-orange-600 bg-orange-10': status === 'error',
      'border-lightspeed bg-lightspeed-10': status === 'success' || !status
    }
  )
)
export const getIcon = status => {
  switch (status) {
    case 'information':
    case 'paused':
      return (
        <FontAwesomeIcon
          icon="far fa-circle-info"
          className="w-6 h-6 mr-2.5 text-deepspace"
        />
      )
    case 'error':
      return (
        <FontAwesomeIcon
          icon="far fa-triangle-exclamation"
          className="w-6 h-6 mr-2.5 text-orange-600"
        />
      )
    default:
      return (
        <FontAwesomeIcon
          icon="far fa-circle-check"
          className="w-6 h-6 mr-2.5 text-lightspeed"
        />
      )
  }
}
