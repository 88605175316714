import { isRejectedWithValue } from '@reduxjs/toolkit'
// import { captureMessage as captureSentryMessage } from '@sentry/react'

const rtkQueryErrorSentryLogger = () => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers
  if (isRejectedWithValue(action)) {
    // TODO: in case of need use this place to send specific API errors to Sentry
    // captureSentryMessage(
    //   `API call error response: ${action.meta.baseQueryMeta.request.url}`,
    //   { extra: action },
    // )
  }

  return next(action)
}

export default rtkQueryErrorSentryLogger
