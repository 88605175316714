export const USER_TAG_TYPE = 'User'
export const GOALS_TAG_TYPE = 'Goals'
export const USER_SUGGESTED_BILLS_TAG_TYPE = 'UserSuggestedBills'
export const USER_LINKED_BILLS_TAG_TYPE = 'UserLinkedBills'
export const USER_CLOSING_INFO_TAG_TYPE = 'UserClosingInfo'
export const REWARDS_TRANSACTIONS_TAG_TYPE = 'RewardsTransactions'
export const NOTIFICATIONS_TAG_TYPE = 'Notifications'
export const CREDIT_SCORE_PROJECTIONS_TAG_TYPE = 'CreditScoreProjections'
export const PAYMENTS_TAG_TYPE = 'Payments'
export const USER_AGREEMENTS_TAG_TYPE = 'UserAgreements'
export const SUBSCRIPTION_PLAN_BILL_TAG_TYPE = 'SubscriptionPlanBills'
export const INACTIVE_BANK_ACCOUNTS = 'InactiveBankAccounts'
export const QUESTIONNAIRE_TAG_TYPE = 'Questionnaire'
export const USER_DOCUMENTS_TAG_TYPE = 'UserDocuments'
