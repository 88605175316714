import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as widgetSdk from '@mxenabled/web-widget-sdk'

import { MODAL_HASH_BASED_IDS } from '~/constants'
import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'

import {
  useLazyGetWidgetUrlQuery,
  useLinkMxBankAccountMutation
} from '~/store/api'
import {
  BANK_ACCOUNT_LINKED,
  BANK_ACCOUNT_LINK_ERRORED,
  MX_CONNECT_POST_MESSAGE_CHANGED
} from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { addSnackbarMessage } from '~/store/modules/snackbar'

import { MX_POST_MESSAGE_EVENTS_TO_FILTER } from '../../constants'

export const useMxConnect = props => {
  const {
    onModalClose,
    user
  } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const widgetRef = useRef(null)
  const bankDetails = user.accounts?.[0]

  const [
    trackBankAccountLinked,
    trackBankAccountLinkFailed,
    trackMxConnectPostMessageChanged
  ] = useSegmentEvents([
    BANK_ACCOUNT_LINKED,
    BANK_ACCOUNT_LINK_ERRORED,
    MX_CONNECT_POST_MESSAGE_CHANGED
  ])

  const [getWidgetUrl, getMxWidgetApiResult] = useLazyGetWidgetUrlQuery()
  const [linkMxBankAccount, linkMxBankAccountApiResult] = useLinkMxBankAccountMutation()

  const isLoading = getMxWidgetApiResult.isLoading || linkMxBankAccountApiResult.isLoading

  const dispatchMxConnectErrorMessage = useCallback(() => {
    dispatch(addSnackbarMessage({
      message: 'There was an issue connecting your bank account',
      status: 'error',
    }))
  }, [dispatch])

  const handlePostMessageEvent = useCallback(event => {
    if (event.data.mx && !MX_POST_MESSAGE_EVENTS_TO_FILTER[event.data.type]) {
      trackMxConnectPostMessageChanged({
        type: event.data.type,
        bankAccountUuid: bankDetails?.uuid,
        initialInstitutionName: bankDetails?.bankName,
        ...event.data.metadata
      })
    }
  }, [trackMxConnectPostMessageChanged, bankDetails])

  useEffect(() => {
    window.addEventListener('message', handlePostMessageEvent)

    return () => window.removeEventListener('message', handlePostMessageEvent)
  }, [handlePostMessageEvent])

  const handleOnMemberConnectedEvent = useCallback(async payload => {
    widgetRef.current.unmount()

    const linkData = {
      member: {
        uuid: payload.member_guid
      }
    }

    const apiResult = await linkMxBankAccount(linkData)

    if (apiResult.error) {
      dispatchMxConnectErrorMessage()
    } else {
      const bankAccount = apiResult.data?.user?.accounts?.[0]
      const eventData = {
        accountType: bankAccount?.accountName,
        institutionName: bankAccount?.bankName,
        bankAccountUuid: bankAccount?.uuid,
        provider: BANK_ACCOUNT_PROVIDERS.MX
      }

      onModalClose()
      trackBankAccountLinked(eventData)
      navigate(`${location.pathname}${location.search}#${MODAL_HASH_BASED_IDS.ACCOUNT_TYPE_SELECT}`)
    }
  }, [
    linkMxBankAccount, onModalClose, navigate, location.pathname, location.search,
    trackBankAccountLinked, dispatchMxConnectErrorMessage
  ])

  const handleMemberConnectError = useCallback(async payload => {
    const metadata = payload.metadata
    const eventData = {
      mxUserGuid: metadata.user_guid,
      institutionName: metadata.institution_code,
      institutionId: metadata.institution_guid,
      linkSessionId: metadata.session_guid,
      provider: BANK_ACCOUNT_PROVIDERS.MX
    }

    onModalClose()
    trackBankAccountLinkFailed(eventData)
    dispatchMxConnectErrorMessage()
    widgetRef.current.unmount()
  }, [onModalClose, trackBankAccountLinkFailed, dispatchMxConnectErrorMessage])

  const handleMxWidgetOpen = useCallback(async () => {
    const apiResult = await getWidgetUrl()
    const widgetUrl = apiResult.data.url

    if (widgetUrl) {
      widgetRef.current = new widgetSdk.ConnectWidget({
        container: '#mx-widget-root',
        url: widgetUrl,
        onMemberConnected: handleOnMemberConnectedEvent,
        onCreateMemberError: handleMemberConnectError
      })
    } else {
      dispatchMxConnectErrorMessage()
    }
  }, [widgetRef, getWidgetUrl, handleOnMemberConnectedEvent, handleMemberConnectError, dispatchMxConnectErrorMessage])

  return {
    handleMxWidgetOpen,
    isLoading
  }
}
