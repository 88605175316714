import { useSelector } from 'react-redux'

import { useFetchUserQuery } from '~/store/api'
import {
  checkPermissions,
} from '~/utils'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'

export const useCan = (permissions, any = false) => {
  const isAuth = useSelector(selectIsUserAuthorized)
  const {
    data = {},
  } = useFetchUserQuery(undefined, { skip: !isAuth })
  const { user = {} } = data
  const userPermissions = {
    // ...user.permissions, // TODO: may be added some time later
    experianStatus: user.experianStatus,
    emailVerified: user.emailVerified,
    mtStatus: user.mtStatus,
    status: user.status,
    registrationNextStep: user.registrationNextStep,
    subscriptionPlan: user.subscriptionPlan?.name,
    riskLevel: user.riskLevel,
    // NOTE: add more fields if you need to check them as well
  }

  return checkPermissions(userPermissions, permissions, any)
}
