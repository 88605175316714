const getNotificationEndpoints = builder => ({
  getNotifications: builder.query({
    query: (params = {}) => ({
      url: '/alerts',
      params,
    }),
    transformResponse: response => {
      if (response.alerts && response.pagination) {
        return {
          list: response.alerts,
          ...response.pagination
        }
      }

      return response
    },
  }),
  readAllNotifications: builder.mutation({
    query: body => ({
      url: '/alerts/read_all',
      method: 'POST',
      body,
    }),
  }),
})

export default getNotificationEndpoints
