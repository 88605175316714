import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import RadioButton from '~/components/ui/RadioButton'
import AccountSelectOptionLabel from '../AccountSelectOptionLabel'

const AccountSelectOption = props => {
  const {
    account,
    isSelected,
    isDisabled,
    onSelectAccount
  } = props

  const handleSelectAccount = useCallback(
    () => onSelectAccount(account),
    [account, onSelectAccount]
  )

  return (
    <RadioButton
      id={`account-${account.uuid}`}
      className="w-full"
      variant="outlined"
      checked={isSelected}
      disabled={isDisabled}
      onChange={handleSelectAccount}
      label={(
        <AccountSelectOptionLabel account={account} />
      )}
    />
  )
}

AccountSelectOption.propTypes = {
  account: PropTypes.shape({
    uuid: PropTypes.string,
    accountName: PropTypes.string,
    accountMask: PropTypes.string,
    balance: PropTypes.number
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSelectAccount: PropTypes.func.isRequired
}

const Memoized = React.memo(AccountSelectOption)
export default Memoized
