export const mapRoutesObjToArray = routesObj => Object.values(routesObj).reduce((acc, path) => {
  if (typeof path === 'string') {
    return [...acc, { path }]
  }

  if (typeof path === 'object') {
    return [
      ...acc,
      ...mapRoutesObjToArray(path)
    ]
  }

  return acc
}, [])
