import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as SharedLink } from 'stellar-web-shared'

import { useSetPrevRoute } from '~/hooks/useSetPrevRoute'
import { getActionColor } from '../../utils'

const StandardLink = props => {
  const {
    action,
    storePrevRoute,
    onClick,
    ...otherProps
  } = props

  const handleClick = useSetPrevRoute(onClick, storePrevRoute)

  const color = getActionColor(action)

  return (
    <SharedLink
      LinkComponent={ReactRouterLink}
      onClick={handleClick}
      color={color}
      {...otherProps}
    />
  )
}
StandardLink.propTypes = {
  action: PropTypes.string,
  storePrevRoute: PropTypes.bool,
  onClick: PropTypes.func,
}
StandardLink.defaultProps = {
  action: 'standard',
  storePrevRoute: false,
  onClick: () => null,
}

// TODO: do we need memoization here?
export default StandardLink
