import React from 'react'
import PropTypes from 'prop-types'

import Card from '~/components/ui/Card'
import Link from '~/components/ui/Link'
import Loader from '~/components/ui/Loader'
import BankAccountLink from '~/features/BankAccountManagement/Link'
import {
  STELLAR_CS_EMAIL,
  CLOUDFLARE_IMAGE_IDS,
} from '~/constants'
import { getCloudflareImageURL } from '~/utils'

const AddDifferentBankAccount = props => {
  const {
    accounts,
    isLoading,
    hasSucceeded,
    onLoading,
    onSuccess,
  } = props

  return (
    <Card className="mb-5 md:flex md:flex-col lg:flex-row md:gap-6 md:items-center">
      <div>
        {hasSucceeded ? (
          <p>
            We&apos;re in the process of reviewing your account, please wait, this can take a minute
          </p>
        ) : (
          <>
            <h3 className="text-2xl font-bold mb-2">
              Your bank account is not eligible for StellarFi.
            </h3>
            <p className="mb-2">
              To use StellarFi, please link the bank account that you use to pay bills. After that you can begin paying bills and improving your credit score.
            </p>
            {isLoading ? (
              <Loader data-testid="stellar-link-bank-accounts-loader-v2" />
            ) : (
              <>
                <BankAccountLink
                  accounts={accounts}
                  onLoading={onLoading}
                  onSuccess={onSuccess}
                  btnClassName="mt-0 mb-2"
                  btnText="Add different bank account"
                />
                <p className="text-center">
                  Recently opened a new bank account?
                  We can help,
                  {' '}
                  <Link
                    href={STELLAR_CS_EMAIL}
                  >
                    contact support.
                  </Link>
                </p>
              </>
            )}
          </>
        )}
      </div>
      <img
        src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.ON_TIME_PAYMENTS)}
        alt="on time payments"
        className="hidden lg:block max-w-66 max-h-71"
      />
    </Card>
  )
}
AddDifferentBankAccount.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasSucceeded: PropTypes.bool.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

const Memoized = React.memo(AddDifferentBankAccount)
export default Memoized
