import { QUESTIONNAIRE_TAG_TYPE } from '~/store/api/constants'

const getQuestionnaireEndpoints = builder => ({
  getQuestions: builder.query({
    query: () => '/questions',
    providesTags: [QUESTIONNAIRE_TAG_TYPE],
  }),
  postQuestions: builder.mutation({
    query: body => ({
      url: '/questions',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [QUESTIONNAIRE_TAG_TYPE]),
  })
})

export default getQuestionnaireEndpoints
