import React from 'react'
import PropTypes from 'prop-types'

import PlaidLink from '~/features/BankAccountManagement/Plaid/Link'
import MxLink from '~/features/BankAccountManagement/Mx/Link'

import { EXPERIMENTS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'
import { useFetchUserQuery } from '~/store/api'
import { checkIsMXBankProvider, getBankProvider } from '~/utils/user'
import { LINK_ACCOUNT_VARIANTS } from '../constants'

const BankAccountLink = props => {
  const {
    accounts,
    onLoading,
    onSuccess,
    btnClassName,
    btnText,
    variant,
    reason,
    disabled,
    forceUsePlaid,
    onClick,
  } = props

  const {
    data = {},
  } = useFetchUserQuery()
  const { user = {} } = data

  const {
    enabled: isMxM2Enabled,
    isLoaded: isMxM2Loaded
  } = useExperiment(EXPERIMENTS.MX_M2)

  const { enabled: isMxShutdownEnabled } = useExperiment(EXPERIMENTS.MX_SHUTDOWN, false)

  const isMXBankProvider = !forceUsePlaid
    && checkIsMXBankProvider(getBankProvider(isMxShutdownEnabled, user.bankProviderOverride, isMxM2Enabled))

  return (
    isMxM2Loaded ? (
      <>
        {isMXBankProvider ? (
          <MxLink
            btnText={btnText}
            btnClassName={btnClassName}
            variant={variant}
            reason={reason}
          />
        ) : (
          <PlaidLink
            accounts={accounts}
            onLoading={onLoading}
            onSuccess={onSuccess}
            btnText={btnText}
            btnClassName={btnClassName}
            variant={variant}
            disabled={disabled}
            onClick={onClick}
          />
        )}
      </>
    ) : null
  )
}

BankAccountLink.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  onLoading: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  btnClassName: PropTypes.string,
  variant: PropTypes.string,
  reason: PropTypes.string,
  disabled: PropTypes.bool,
  forceUsePlaid: PropTypes.bool,
  onClick: PropTypes.func,
}

BankAccountLink.defaultProps = {
  btnText: 'Connect Bank',
  btnClassName: '',
  variant: LINK_ACCOUNT_VARIANTS.V1,
  reason: '',
  disabled: false,
  onLoading: () => null,
  accounts: [],
  forceUsePlaid: false,
  onClick: () => {},
}

export default BankAccountLink
