import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '~/components/ui/Tooltip'

const CreditScoreProjectionTooltip = props => {
  const {
    user,
    placement,
  } = props

  const {
    credit,
  } = user

  const description = credit ? (
    `This example projection is based on average historical results from StellarFi members. 
    On-time payments through StellarFi can positively impact your credit score. Results may vary.`
  ) : (
    'This example projection is based on historical results of StellarFi members with a 580 credit score and $1000 in linked bills.'
  )

  return (
    <Tooltip
      header="Score Projection"
      description={description}
      tipPlacement={placement}
      className={props.className}
      data-testid="stellar-credit-score-projection-tooltip-icon"
    />
  )
}
CreditScoreProjectionTooltip.propTypes = {
  user: PropTypes.shape({
    credit: PropTypes.shape({}),
  }).isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
}
CreditScoreProjectionTooltip.defaultProps = {
  placement: 'auto',
  className: '',
}

const Memoized = React.memo(CreditScoreProjectionTooltip)
export default Memoized
