import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

import Button from '~/components/ui/Button'
import Link from '~/components/ui/Link'

const UserMenuFullScreenListItem = props => {
  const {
    routeObj,
    onMenuClose
  } = props
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    onMenuClose()
    navigate(routeObj.route)
  }, [navigate, routeObj.route, onMenuClose])

  return (
    <li
      className="mb-8 last:mb-0"
    >
      {routeObj.href ? (
        <Link
          size="lg"
          className="flex w-full !justify-start items-center no-underline font-semibold"
          data-testid={routeObj.testId}
          href={routeObj.href}
          target={routeObj.target}
        >
          <FontAwesomeIcon
            icon={routeObj.iconName}
            className="w-6 h-6 mr-2"
          />
          {routeObj.label}
        </Link>
      ) : (
        <Button
          size=""
          className="flex w-full !justify-start items-center no-underline font-semibold"
          data-testid={routeObj.testId}
          onClick={routeObj.onClick || handleClick}
        >
          <FontAwesomeIcon
            icon={routeObj.iconName}
            className="w-6 h-6 mr-2"
          />
          {routeObj.label}
        </Button>
      )}
    </li>
  )
}
UserMenuFullScreenListItem.propTypes = {
  routeObj: PropTypes.shape({
    label: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    testId: PropTypes.string.isRequired,
    isButton: PropTypes.bool,
    href: PropTypes.string,
    target: PropTypes.string,
    route: PropTypes.string,
    onClick: PropTypes.func,
    navigateToRoute: PropTypes.string,
  }).isRequired,
  onMenuClose: PropTypes.func.isRequired,
}

const Memoized = React.memo(UserMenuFullScreenListItem)
export default Memoized
