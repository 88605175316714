import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ROUTES } from '~/pages/constants'
import { setPreviousRoute } from '~/store/modules/ui'
import { useFetchUserQuery } from '~/store/api'
import { checkIsUserAllowedToAddBill, checkUserCardState } from '~/utils'
import Link from '~/components/ui/Link'
import { CARD_STATES } from '~/constants'
import { FOOTER_NAVIGATION_ROUTES } from './constants'

const MobileFooter = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const {
    data = {},
  } = useFetchUserQuery()

  const { user = {} } = data
  const isCardUnlocked = checkUserCardState(user, CARD_STATES.OPEN)

  const isAllowedToAddBill = checkIsUserAllowedToAddBill(user)
  const isAllowed = isCardUnlocked && isAllowedToAddBill

  const handlePrevRoute = useCallback(() => {
    if (isAllowed) {
      dispatch(setPreviousRoute(pathname))
    }
  }, [dispatch, pathname, isAllowed])

  return (
    <footer
      className="flex justify-between bg-white w-full px-5 pb-6 pt-3 shadow-indigoDye fixed bottom-0 z-10"
      data-testid="stellar-footer"
    >
      {FOOTER_NAVIGATION_ROUTES.map(routeObj => {
        const isAddBill = routeObj.route === ROUTES.NEW_BILL
        const selectedIcon = pathname === routeObj.route

        return isAddBill ? (
          <div
            key={routeObj.route}
            className="bg-white rounded-5xl w-14 -mt-9 flex justify-center"
          >
            <Link
              to={routeObj.route}
              size="lg"
              className="flex flex-col items-center no-underline pt-1"
              disabled={!isAllowed}
              data-testid={routeObj.testId}
              onClick={handlePrevRoute}
            >
              <FontAwesomeIcon
                icon={routeObj.iconName}
                className={`fa-2x py-2 px-2.5 rounded-5xl mb-4.5 text-3xl ${isAllowed ? 'bg-lightspeed text-white' : 'bg-deepspace-300 text-white'}`}
              />
              <div className="text-xs font-medium">
                {routeObj.label}
              </div>
            </Link>
          </div>
        ) : (
          <Link
            key={routeObj.route}
            to={routeObj.route}
            size="lg"
            className={`
              flex flex-col items-center no-underline
              ${selectedIcon ? 'font-semibold !text-lightspeed' : ''}
            `}
            data-testid={routeObj.testId}
          >
            <FontAwesomeIcon
              icon={selectedIcon ? routeObj.iconNameSelected : routeObj.iconName}
              className="mb-2 fa-2x text-2xl"
            />
            <div className="text-xs font-medium">
              {routeObj.label}
            </div>
          </Link>
        )
      })}
    </footer>
  )
}

const Memoized = React.memo(MobileFooter)
export default Memoized
