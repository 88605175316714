import { ROUTES } from '~/pages/constants'

export const getOnboardingProgress = pathname => {
  switch (pathname) {
    case ROUTES.CHOOSE_GOALS:
      return { currentStep: 1, progress: 9 }
    case ROUTES.ACTIVATE_CREDIT_SCORE:
      return { currentStep: 1, progress: 18 }
    case ROUTES.CONFIRM_IDENTITY.IDENTITY:
      return { currentStep: 1, progress: 27 }
    case ROUTES.CONFIRM_IDENTITY.ADDRESS:
      return { currentStep: 1, progress: 36 }
    case ROUTES.CONFIRM_IDENTITY.VERIFY_PHONE_NUMBER:
      return { currentStep: 1, progress: 45 }
    case ROUTES.CONFIRM_IDENTITY.SUMMARY:
      return { currentStep: 1, progress: 54 }
    case ROUTES.ONBOARDING_CREDIT_SCORE:
      return { currentStep: 1, progress: 63 }
    case ROUTES.LINK_BANK_ACCOUNT:
      return { currentStep: 2, progress: 71 }
    case ROUTES.CHOOSE_SUBSCRIPTION:
      return { currentStep: 2, progress: 80 }
    case ROUTES.SUBSCRIPTION:
      return { currentStep: 2, progress: 89 }
    case ROUTES.CONFIRMATION:
      return { currentStep: 3, progress: 100 }
    default:
      return { currentStep: 3, progress: 100 }
  }
}
