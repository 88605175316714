import {
  USER_TAG_TYPE,
  USER_LINKED_BILLS_TAG_TYPE,
  USER_DOCUMENTS_TAG_TYPE,
  CREDIT_SCORE_PROJECTIONS_TAG_TYPE,
} from '~/store/api/constants'
import { getExperimentURLSearchParams } from '~/utils/experiments'

const getUserEndpoints = builder => ({
  registerUser: builder.mutation({
    query: body => ({
      url: '/registrations',
      method: 'POST',
      body: {
        ...body,
        passwordConfirmation: body.password,
      }
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  postHeardFrom: builder.mutation({
    query: body => ({
      url: '/registrations/heard_from',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  loginUser: builder.mutation({
    query: body => ({
      url: '/sessions',
      method: 'POST',
      body,
    }),
  }),
  loginUserByToken: builder.mutation({
    query: body => ({
      url: '/sessions/verify_token',
      method: 'POST',
      body,
    }),
  }),
  verifyUser: builder.mutation({
    query: body => ({
      url: '/sessions/verify',
      method: 'POST',
      body,
    }),
  }),
  resendVerification: builder.mutation({
    query: body => ({
      url: '/sessions/resend_verification',
      method: 'POST',
      body,
    }),
  }),
  signInWithGoogle: builder.mutation({
    query: ({ jwtToken }) => ({
      url: '/oauth/google/sign_in',
      method: 'POST',
      body: { jwtToken },
    }),
  }),
  signUpWithGoogle: builder.mutation({
    query: ({ jwtToken, promoCode, withCreditScoreGuarantee }) => ({
      url: '/oauth/google/sign_up',
      method: 'POST',
      body: { jwtToken, promoCode, withCreditScoreGuarantee },
    }),
  }),
  connectWithGoogle: builder.mutation({
    query: ({ jwtToken }) => ({
      url: '/oauth/google/connect',
      method: 'POST',
      body: { jwtToken },
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  signInWithApple: builder.mutation({
    query: ({ jwtToken, code, user }) => ({
      url: '/oauth/apple/sign_in',
      method: 'POST',
      body: { jwtToken, code, user },
    }),
  }),
  signUpWithApple: builder.mutation({
    query: ({ jwtToken, code, user, promoCode, withCreditScoreGuarantee }) => ({
      url: '/oauth/apple/sign_up',
      method: 'POST',
      body: { jwtToken, code, user, promoCode, withCreditScoreGuarantee },
    }),
  }),
  connectWithApple: builder.mutation({
    query: ({ jwtToken, code, user }) => ({
      url: '/oauth/apple/connect',
      method: 'POST',
      body: { jwtToken, code, user },
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  impersonateUser: builder.mutation({
    query: body => ({
      url: '/sessions/impersonate_user',
      method: 'POST',
      body,
    }),
  }),
  fetchUser: builder.query({
    query: () => '/sessions/me',
    providesTags: [USER_TAG_TYPE],
  }),
  fetchUserContext: builder.query({
    query: () => {
      const experimentOverrideParams = getExperimentURLSearchParams()
      return {
        url: '/sessions/user_context',
        method: 'GET',
        params: experimentOverrideParams
      }
    }
  }),
  updateUser: builder.mutation({
    query: user => ({
      url: `/users/${user.uuid}`,
      method: 'PUT',
      body: { ...user },
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  updateUserSettings: builder.mutation({
    query: body => ({
      url: '/settings',
      method: 'PUT',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  updatePassword: builder.mutation({
    query: body => ({
      url: '/sessions/change_password',
      method: 'POST',
      body,
    }),
  }),
  uploadAvatar: builder.mutation({
    query: ({ userUuid, file }) => {
      const formData = new FormData()
      formData.append('avatar', file)

      return {
        url: `/users/${userUuid}`,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
      }
    },
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  sendVerificationEmail: builder.mutation({
    query: () => ({
      url: '/verifications/email',
      method: 'POST',
      body: {},
    }),
  }),
  confirmEmail: builder.mutation({
    query: body => ({
      url: '/email_confirmations',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  resendEmailConfirmation: builder.query({
    query: () => '/email_confirmations/new',
  }),
  sendPhonePinCode: builder.mutation({
    query: body => ({
      url: '/phone_verifications/send_code',
      method: 'POST',
      body,
    }),
  }),
  verifyPhonePinCode: builder.mutation({
    query: body => ({
      url: '/phone_verifications/verify',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  confirmIdentity: builder.mutation({
    query: body => ({
      url: '/users/confirm_identity',
      method: 'PUT',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, USER_LINKED_BILLS_TAG_TYPE, CREDIT_SCORE_PROJECTIONS_TAG_TYPE]),
  }),
  confirmAccountSetup: builder.mutation({
    query: ({ uuid }) => ({
      url: `/users/${uuid}/confirm`,
      method: 'PUT',
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, CREDIT_SCORE_PROJECTIONS_TAG_TYPE]),
  }),
  requestPassword: builder.mutation({
    query: email => ({
      url: '/password_resets',
      method: 'POST',
      body: { email },
    }),
  }),
  resetPassword: builder.mutation({
    query: body => ({
      url: '/password_resets/reset',
      method: 'POST',
      body,
    }),
  }),
  verifyEmail: builder.mutation({
    query: body => ({
      url: '/verifications',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  uploadDocument: builder.mutation({
    query: ({ file }) => {
      const formData = new FormData()
      formData.append('document', file)

      return {
        url: '/user_documents',
        method: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
      }
    },
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  deleteDocument: builder.mutation({
    query: docUuid => ({
      url: `/user_documents/${docUuid}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'multipart/form-data' },
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  fetchUserAgreements: builder.query({
    query: userUuid => `/users/${userUuid}/agreements`,
    providesTags: [USER_DOCUMENTS_TAG_TYPE],
  }),
  fetchDocumentRequest: builder.query({
    query: () => '/user_documents/user_document_request',
  }),
  fetchHighnoteDocumentUploadToken: builder.query({
    query: () => '/user_documents/highnote_document_upload_token',
  }),
  completeHighnoteDocumentUpload: builder.mutation({
    query: body => ({
      url: '/user_documents/complete_highnote_document_upload',
      method: 'POST',
      body,
    }),
  }),
})

export default getUserEndpoints
