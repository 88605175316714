import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'

import { useUpdateGoalMutation } from '~/store/api'

import StandardButton from '~/components/ui/Buttons/StandardButton'
import Modal from '~/components/ui/Modal'
import FormTextField from '~/components/form/TextField'

const RenameGoalModal = props => {
  const { initialName, onUpdateGoal, goal } = props

  const modal = useModal()
  const dispatch = useDispatch()

  const [updateGoal, updateGoalApiResult] = useUpdateGoalMutation()

  const {
    control,
    formState: {
      isValid,
      isSubmitting,
    },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    defaultValues: { name: initialName },
  })

  const handleCloseModal = useCallback(async () => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.RENAME_GOAL }))
  }, [modal, dispatch])

  const handleUpdateGoal = useCallback(async newName => {
    const apiResult = await updateGoal({ id: goal.uuid, body: { name: newName.name } })
    if (!apiResult.error) {
      onUpdateGoal(apiResult.data)
      handleCloseModal()
    }
  }, [updateGoal, goal.uuid, handleCloseModal, onUpdateGoal])

  return (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      data-testid="stellar-dashboard-goals-modal-update-goal"
    >
      <form onSubmit={handleSubmit(handleUpdateGoal)}>
        <div className="p-4">
          <div className="flex justify-between items-start mb-4">
            <p className="text-lg font-semibold">Edit goal title</p>
          </div>
          <p>Enter the new title of your goal below.</p>
          <FormTextField
            control={control}
            label="Goal title"
            className="mt-4 mb-6"
            name="name"
            validate={value => (value !== '')}
            inputProps={{ 'data-testid': 'stellar-dashboard-goals-modal-update-goal-title' }}
          />
          <div className="flex justify-end">
            <StandardButton
              variant="outlined"
              onClick={handleCloseModal}
              data-testid="stellar-dashboard-goals-modal-update-goal-cancel-btn"
            >
              Cancel
            </StandardButton>
            <StandardButton
              type="submit"
              variant="contained"
              className="ml-2"
              disabled={!isValid || isSubmitting}
              data-testid="stellar-dashboard-goals-modal-update-goal-save-btn"
            >
              Save
            </StandardButton>
          </div>
        </div>
        {updateGoalApiResult.error ? (
          <p
            data-testid="stellar-dashboard-goals-modal-update-goal-error"
            className="text-red mb-4"
          >
            {updateGoalApiResult?.error?.data?.errors?.[0]?.message}
          </p>
        ) : null}
      </form>
    </Modal>
  )
}

RenameGoalModal.propTypes = {
  goal: PropTypes.shape({
    uuid: PropTypes.string
  }).isRequired,
  initialName: PropTypes.string.isRequired,
  onUpdateGoal: PropTypes.func.isRequired
}

export default NiceModal.create(RenameGoalModal)
