import {
  createSlice,
} from '@reduxjs/toolkit'

const initialState = {
  messages: [],
}

let counter = 0

// TODO: think about how to set the message obj with a unique key
const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    addSnackbarMessage(state, action) {
      counter += 1
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            key: `snackbar_${counter}`,
            message: action.payload.message,
            componentName: action.payload.componentName,
            status: action.payload.status,
            mode: action.payload.mode,
            position: action.payload.position,
            containerClassName: action.payload.containerClassName,
          },
        ]
      }
    },
    dismissSnackbarMessage(state, action) {
      return {
        ...state,
        messages: state.messages.filter(messageObj => messageObj.key !== action.payload)
      }
    },
    dismissSnackbarMessageByComponentName(state, action) {
      return {
        ...state,
        messages: state.messages.filter(messageObj => messageObj.componentName !== action.payload)
      }
    }
  },
})

export const {
  addSnackbarMessage,
  dismissSnackbarMessage,
  dismissSnackbarMessageByComponentName,
} = snackbarSlice.actions

export default snackbarSlice.reducer
