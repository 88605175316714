import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Popover from '~/components/ui/Popover'
import Loader from '~/components/ui/Loader'
import ListItem from '../ListItem'

const NotificationsPopover = props => {
  const {
    notificationsList,
    hasReadNotifications,
    hasUnreadNotifications,
    isFetchingNotifications,
    error,
    onScroll,
    onOpen,
    onClose,
  } = props

  return (
    <Popover
      target={(
        <div
          className="relative md:mx-5"
          data-testid="stellar-notifications-popover-target"
        >
          {hasUnreadNotifications && !hasReadNotifications && (
            <span
              className="right-0 w-2 h-2 inline-block rounded bg-red absolute"
              data-testid="stellar-notifications-read-all"
            />
          )}
          <FontAwesomeIcon icon="fa-regular fa-bell" className="text-white h-6 mt-1" />
        </div>
      )}
      containerProps={{
        className: 'max-w-xs max-h-96 overflow-y-auto',
        onScroll
      }}
      onOpen={onOpen}
      onClose={onClose}
      data-testid="stellar-notifications-popover"
    >
      {notificationsList.map((notification, i) => (
        <ListItem
          key={notification.uuid}
          notification={notification}
          hasReadNotifications={hasReadNotifications}
          index={i}
        />
      ))}

      {isFetchingNotifications ? (
        <Loader size="small" />
      ) : null}

      {!isFetchingNotifications && !notificationsList.length ? (
        <p data-testid="stellar-notifications-empty">
          No notifications at the moment
        </p>
      ) : null}

      {error ? (
        <p data-testid="stellar-notifications-error">
          {JSON.stringify(error.data)}
        </p>
      ) : null}
    </Popover>
  )
}
NotificationsPopover.propTypes = {
  notificationsList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readAt: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    iconColorName: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
  })).isRequired,
  isFetchingNotifications: PropTypes.bool.isRequired,
  hasUnreadNotifications: PropTypes.bool.isRequired,
  hasReadNotifications: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
  onScroll: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
NotificationsPopover.defaultProps = {
  error: null,
}

export default NotificationsPopover
