import CONFIG from '~/config'

const RECAPTCHA_V3 = 'v3'

const isReCaptchaV3Enabled = CONFIG.RECAPTCHA_V3_ENABLED === 'true'

const getReCaptcha3Token = async (action = 'submit') => new Promise(resolve => {
  window.grecaptcha.enterprise.ready(async () => {
    const token = await window.grecaptcha.enterprise.execute(CONFIG.RECAPTCHA_V3_SITE_KEY, { action })
    resolve(token)
  })
})

export {
  isReCaptchaV3Enabled,
  getReCaptcha3Token,
  RECAPTCHA_V3,
}
