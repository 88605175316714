import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useSelector } from 'react-redux'

import {
  DOCV_STATUSES,
  REGISTRATION_STEPS,
} from '~/constants'
import { useInterval } from '~/hooks/useInterval'
import {
  selectIsMobile,
  selectIsTablet
} from '~/store/modules/dimensions/selectors'
import {
  useFetchDocumentRequestQuery,
  useFetchUserQuery,
} from '~/store/api'
import Modal from '~/components/ui/Modal'
import Button from '~/components/ui/Button'
import Link from '~/components/ui/Link'
import Loader from '~/components/ui/Loader'
import SocureSoftFail from '~/features/DocumentsManagement/SocureSoftFail'

// TODO: revisit modal appearance after merging funnel_3.0 exp cleanup
const SocureDocumentsUploadModal = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const modal = useModal()

  const {
    data: { user = {} } = {},
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useFetchUserQuery()

  const {
    data: documentRequestData = {},
    isLoading: isLoadingDocumentRequest,
    error: documentRequestError,
  } = useFetchDocumentRequestQuery()
  const { userDocumentRequest = {} } = documentRequestData

  const isMobile = useSelector(selectIsMobile)
  const isTablet = useSelector(selectIsTablet)
  const displayDirectSocureLink = isMobile || isTablet

  useEffect(() => {
    if (
      user.registrationNextStep
      && user.registrationNextStep !== REGISTRATION_STEPS.UPLOAD_DOCUMENTS_TO_SOCURE
    ) {
      modal.resolve()
      modal.remove()
      navigate(location?.pathname)
    }
  }, [modal, navigate, location?.pathname, user.registrationNextStep])

  const [shouldUseInterval, setShouldUseInterval] = useState(true)

  useInterval(() => { refetchUser() }, shouldUseInterval ? 5 : null, 600)

  const handleCancel = useCallback(() => {
    setShouldUseInterval(false)
    modal.remove()
    navigate(location?.pathname)
  }, [modal, location?.pathname, navigate, setShouldUseInterval])

  return (
    <Modal
      show
      onClose={handleCancel}
      size="lg"
      className="m-5 p-5"
    >
      {isLoadingUser || isLoadingDocumentRequest ? (
        <Loader data-testid="stellar-submit-socure-docs-loader" />
      ) : (
        <>
          {user.docvStatus === DOCV_STATUSES.SOFT_FAILED ? (
            <SocureSoftFail className="max-w-175 mx-auto" />
          ) : (
            <>
              <p className="text-2xl font-semibold">
                We need to verify your identity
              </p>
              {displayDirectSocureLink ? (
                <>
                  <p className="mb-9">
                    To process your verification, please have the following documents ready -
                    US Drivers License/State ID or a US Passport. Press the button to be safely redirected
                    to StellarFi&apos;s verification service through Socure. After you&apos;ve finished uploading your
                    documents you will get redirected back to StellarFi
                  </p>
                  {documentRequestError ? (
                    <p className="text-red text-sm pb-2">
                      {documentRequestError.data?.errors?.[0]?.message}
                    </p>
                  ) : null}
                  <div className="flex justify-end">
                    {userDocumentRequest.url ? (
                      <Link
                        to={userDocumentRequest.url}
                        variant="contained"
                        data-testid="stellar-modal-upload-socure-docs-mobile"
                      >
                        Proceed
                      </Link>
                    ) : null}

                    <Button
                      variant="outlined"
                      className="mx-3"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className="mb-9">
                    To process your verification, please have the following documents ready - US Drivers License/State ID or a US Passport.
                    Scan the QR code on your mobile phone to be safely redirected to StellarFi&apos;s verification service through Socure.
                    After you&apos;ve finished uploading your documents, the screen will automatically refresh.
                  </p>
                  {documentRequestError ? (
                    <p className="text-red text-sm pb-2">
                      {documentRequestError.data?.errors?.[0]?.message}
                    </p>
                  ) : null}
                  <div className="flex justify-center">
                    <img
                      src={userDocumentRequest.qrcode}
                      alt="socure_document_verification"
                      className="max-w-66 place-self-center mb-12"
                    />
                  </div>
                  <div className="flex justify-end">
                    <Button
                      variant="outlined"
                      className="mr-3"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </Modal>
  )
}

const Memoized = React.memo(SocureDocumentsUploadModal)
export default NiceModal.create(Memoized)
