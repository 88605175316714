import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, matchRoutes } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '~/components/ui/Button'
import {
  clearPreviousRoute,
  setClearRouteOnUserMenuBackButtonClick,
  SPECIAL_ROUTES,
} from '~/store/modules/ui'
import { ROUTES_LIST } from '~/pages/constants'

import { FLOW_NAMES } from './constants'

const BackButtonHeader = props => {
  const {
    backButtonRoute,
    onOpenUserMenu,
    onClick,
    delayBackButtonClick
  } = props

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const matchArr = matchRoutes(ROUTES_LIST, location)

  const handleBackBtnNavigation = useCallback(() => {
    if (backButtonRoute === SPECIAL_ROUTES.USER_MENU) {
      onOpenUserMenu()
      dispatch(setClearRouteOnUserMenuBackButtonClick(true))
    } else {
      navigate(backButtonRoute)
    }
    dispatch(clearPreviousRoute())
  }, [onOpenUserMenu, navigate, backButtonRoute, dispatch])

  const handleBackBtnClick = useCallback(() => {
    if (delayBackButtonClick) {
      onClick()
      setTimeout(() => (
        handleBackBtnNavigation()
      ), delayBackButtonClick)
    } else {
      handleBackBtnNavigation()
    }
  }, [handleBackBtnNavigation, onClick, delayBackButtonClick])

  return (
    <div
      className="relative py-4 flex justify-center"
      data-testid="stellar-headers-app-mobile-back-button-header"
    >
      <Button
        size=""
        variant=""
        color=""
        className="absolute left-0 w-7.5 h-7.5"
        onClick={handleBackBtnClick}
        data-testid="stellar-headers-app-mobile-back-button-header-btn"
      >
        <FontAwesomeIcon
          icon="far fa-chevron-left"
          size="xl"
          className="text-white mr-2"
        />
      </Button>
      <p
        className="text-white font-semibold text-lg"
        data-testid="stellar-headers-app-mobile-back-button-header-route"
      >
        {FLOW_NAMES[matchArr?.[0].route.path]}
      </p>
    </div>
  )
}
BackButtonHeader.propTypes = {
  backButtonRoute: PropTypes.string.isRequired,
  onOpenUserMenu: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  delayBackButtonClick: PropTypes.number
}

BackButtonHeader.defaultProps = {
  onClick: () => null,
  delayBackButtonClick: 0
}

const Memoized = React.memo(BackButtonHeader)
export default Memoized
