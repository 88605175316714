import React from 'react'

const LinkBankAccountEducationModalContent = () => (
  <div className="mt-3">
    <h3 className="font-semibold leading-8">
      Why connect your primary checking account?
    </h3>
    <p className="text-base font-normal mt-3 text-deepspace-400">
      StellarFi works best when you connect the
      primary checking account that you use for the majority of your transactions.
      We&apos;ll safely sync with your bank to recommend the best bills to connect,
      and we&apos;ll check your available funds before we pay a bill to help protect you from overdrafts.
    </p>
  </div>
)

export default LinkBankAccountEducationModalContent
