import React from 'react'
import PropTypes from 'prop-types'

import Button from '~/components/ui/Button'
import withPlaidLink from '~/features/BankAccountManagement/Plaid/hocs/withPlaidLink'

const RelinkPlaidLink = props => {
  const {
    openPlaid,
    disabled,
    title,
    className
  } = props

  return (
    <Button
      size=""
      className={className}
      onClick={openPlaid}
      disabled={disabled}
    >
      {title}
    </Button>
  )
}

RelinkPlaidLink.propTypes = {
  openPlaid: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
}

RelinkPlaidLink.defaultProps = {
  className: 'hover:underline bg-transparent',
  title: 'Relink your bank account'
}

export default withPlaidLink(RelinkPlaidLink)
