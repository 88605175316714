export const selectDimensions = state => state.dimensions
export const selectIsMobile = state => state.dimensions.width < 768
export const selectIsTablet = state => state.dimensions.width >= 768 && state.dimensions.width < 1024
export const selectScreenType = state => {
  const { dimensions: { width } } = state
  if (width < 768) {
    return 'mobile'
  }

  if (width >= 768 && width < 1024) {
    return 'tablet'
  }

  return 'desktop'
}
