import { useEffect } from 'react'

export const useNonScrollablePage = freeze => {
  useEffect(() => {
    let timer
    if (freeze) {
      timer = setTimeout(() => {
        document.body.style.height = '100vh'
        document.body.style.overflow = 'hidden'
      }, 500)
    }

    return () => {
      clearTimeout(timer)
      document.body.style.height = 'unset'
      document.body.style.overflow = 'unset'
    }
  }, [freeze])

  return null
}
