import React, { useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSTransition } from 'react-transition-group'

import Button from '~/components/ui/Button'
import UserAvatar from '~/components/ui/UserAvatar'
import { getAvailableLinks } from '~/pages/Settings/Sidebar/utils'
import {
  clearPreviousRoute,
  setClearRouteOnUserMenuBackButtonClick,
} from '~/store/modules/ui'
import { selectShouldClearRouteOnUserMenuBackButtonClick, selectBackButtonRoute } from '~/store/modules/ui/selectors'
import { getUserFirstName } from '~/utils'

import ListItem from '../ListItem'
import { MOBILE_NAVIGATION_ROUTES, ANIMATION_TIME, MOBILE_NAVIGATION_ROUTES_NEW_FUNNEL } from '../constants'
import './styles.scss'

const UserMenuFullScreenAnimatedPage = props => {
  const {
    user,
    isOpen,
    onClose,
    showLimitedHeader
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const shouldClearPrevRoute = useSelector(selectShouldClearRouteOnUserMenuBackButtonClick)
  const backButtonRoute = useSelector(selectBackButtonRoute)

  const nodeRef = useRef(null)

  const availableLinks = getAvailableLinks(user, showLimitedHeader ? MOBILE_NAVIGATION_ROUTES_NEW_FUNNEL : MOBILE_NAVIGATION_ROUTES)

  const handleCloseUserMenu = useCallback(() => {
    onClose(false)
    if (shouldClearPrevRoute) {
      navigate(backButtonRoute)
      dispatch(clearPreviousRoute())
      dispatch(setClearRouteOnUserMenuBackButtonClick(false))
    }
  }, [
    onClose,
    shouldClearPrevRoute,
    navigate,
    backButtonRoute,
    dispatch,
  ])

  const firstName = getUserFirstName(user)

  return (
    <CSSTransition
      in={isOpen}
      nodeRef={nodeRef}
      timeout={ANIMATION_TIME}
      classNames="user-menu"
      unmountOnExit
    >
      <div
        ref={nodeRef}
        className="bg-white w-full h-screen absolute top-0 left-0 px-5 pt-7 pb-25 z-30 overflow-y-scroll"
        data-testid="stellar-headers-app-mobile-user-menu-animated-page"
      >
        <div className="relative mb-14">
          <Button
            size=""
            variant=""
            className="px-2 py-0 absolute top-0 left-0"
            onClick={handleCloseUserMenu}
            data-testid="stellar-headers-app-mobile-user-menu-animated-close-btn"
          >
            <FontAwesomeIcon
              icon="far fa-chevron-left"
              size="xl"
              className="mr-2"
            />
          </Button>
          <p className="text-center font-semibold text-lg flex-1">Profile</p>
        </div>
        <UserAvatar
          user={user}
          className="mb-5"
          data-testid="stellar-app-header-menu-popover-avatar"
        />
        {firstName ? (
          <p className="font-semibold text-3xl mb-5">
            Hi,
            {' '}
            {firstName}
          </p>
        ) : (
          <p className="font-semibold text-3xl mb-5">
            Hi!
          </p>
        )}
        <div className="mb-5 border-b border-moonrock-400" />
        <ul>
          {availableLinks.map(routeObj => (
            <ListItem
              key={routeObj.label}
              routeObj={routeObj}
              onMenuClose={onClose}
            />
          ))}
        </ul>
      </div>
    </CSSTransition>
  )
}
UserMenuFullScreenAnimatedPage.propTypes = {
  user: PropTypes.shape({
    identityFirstName: PropTypes.string,
    firstName: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showLimitedHeader: PropTypes.bool.isRequired
}

export default UserMenuFullScreenAnimatedPage
