import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import TextField from '~/components/ui/TextField'

const FormTextField = React.forwardRef((props, ref) => {
  const {
    control,
    name,
    validate,
    defaultValue,
    ...otherProps
  } = props

  return (
    <Controller
      control={control}
      name={name}
      rules={{ validate }}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          {...field}
          {...otherProps}
          ref={ref}
          defaultValue={defaultValue}
        />
      )}
    />
  )
})

FormTextField.propTypes = {
  control: PropTypes.shape({
    register: PropTypes.func.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  defaultValue: PropTypes.string,
  'data-testid': PropTypes.string,
}

FormTextField.defaultProps = {
  validate: () => true,
  defaultValue: '',
  'data-testid': '',
}

export default React.memo(FormTextField)
