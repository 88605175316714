export const formatCurrency = num => {
  if (!num) return '$0'
  return `$${Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
}

export const formatCentsToDollars = (cents, options = { dropCents: false }) => {
  const dollars = options.dropCents ? Math.trunc(cents / 100) : cents / 100

  const styleOptions = {
    style: 'currency',
    currency: 'USD',
    ...options,
  }

  const dollarString = dollars.toLocaleString('en-US', styleOptions)

  if (dollars > 0 && options.withPlusSign) {
    return `+${dollarString}`
  }
  return dollarString
}
