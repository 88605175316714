import { CREDIT_SCORE_PROJECTIONS_TAG_TYPE } from './constants'

const getCreditEndpoints = builder => ({
  getUserToken: builder.query({
    query: (params = {}) => ({
      url: '/sessions/user_token',
      params,
    }),
  }),
  getCreditReport: builder.query({
    query: uuid => `/users/${uuid}/credit_report`
  }),
  getLatestCreditReport: builder.query({
    query: () => ({
      url: '/credit_reports/latest',
      method: 'GET',
    }),
  }),
  getLatestStellarTradeline: builder.query({
    query: () => ({
      url: '/credit_reports/latest_stellar_tradeline',
      method: 'GET'
    })
  }),
  getCreditScoreProjections: builder.query({
    query: () => '/credit_score_projections',
    providesTags: [CREDIT_SCORE_PROJECTIONS_TAG_TYPE],
  }),
})

export default getCreditEndpoints
