import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import lottie from 'lottie-web'

import {
  selectIsMobile,
} from '~/store/modules/dimensions/selectors'
import rocket_loader from '~/lotties/rocket_loader.json'

const AppLoading = props => {
  const {
    loadingMessage,
  } = props
  const isMobile = useSelector(selectIsMobile)

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector('#app-loading-animation'),
      renderer: 'svg',
      animationData: rocket_loader,
      autoplay: true,
      loop: true,
    })

    return () => {
      lottie.destroy()
    }
  }, [isMobile])

  return (
    <div className="relative h-screen w-screen">
      <div
        id="app-loading-animation"
        className="max-w-2/5 max-h-4/5 m-auto"
      />
      <p className="text-center">
        {loadingMessage}
      </p>
    </div>
  )
}
AppLoading.propTypes = {
  loadingMessage: PropTypes.string,
}
AppLoading.defaultProps = {
  loadingMessage: '',
}

const Memoized = React.memo(AppLoading)
export default Memoized
