import React from 'react'
import PropTypes from 'prop-types'

import ErrorFallback from '~/components/ui/ErrorFallback'

const Fallback = props => {
  const { error, resetErrorBoundary } = props

  return (
    <ErrorFallback
      message="Something went wrong. Please try again later."
      error={error}
      onReset={resetErrorBoundary}
    />
  )
}
Fallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
}

export default React.memo(Fallback)
