import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as SharedLinkComponent } from 'stellar-web-shared'

import { useSetPrevRoute } from '~/hooks/useSetPrevRoute'

const Link = props => {
  const {
    storePrevRoute,
    onClick,
    ...otherProps
  } = props

  const handleClick = useSetPrevRoute(onClick, storePrevRoute)

  return (
    <SharedLinkComponent
      LinkComponent={ReactRouterLink}
      onClick={handleClick}
      {...otherProps}
    />
  )
}
Link.propTypes = {
  storePrevRoute: PropTypes.bool,
  onClick: PropTypes.func,
}
Link.defaultProps = {
  storePrevRoute: false,
  onClick: () => null,
}

// TODO: do we need memoization here?
export default Link
