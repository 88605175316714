import {
  USER_TAG_TYPE,
} from '~/store/api/constants'

const getSweepstakesEndpoints = builder => ({
  enterSweepstakes: builder.mutation({
    query: body => ({
      url: '/sweepstakes/enter',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
})

export default getSweepstakesEndpoints
