import {
  PAYMENT,
  SUBSCRIPTION_PLAN_BILL,
} from '~/constants'

import {
  PAYMENTS_TAG_TYPE,
  SUBSCRIPTION_PLAN_BILL_TAG_TYPE,
} from '~/store/api/constants'

const getPayableEndpoints = builder => ({
  repayPayable: builder.mutation({
    query: ({ id, payableType, body }) => ({
      url: `/payables/${payableType}/${id}/repay`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (response, error) => {
      if (error) return []
      if (response.payableType === PAYMENT) {
        return [PAYMENTS_TAG_TYPE]
      }
      if (response.payableType === SUBSCRIPTION_PLAN_BILL) {
        return [SUBSCRIPTION_PLAN_BILL_TAG_TYPE]
      }
      return []
    }
  }),
})

export default getPayableEndpoints
