import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '~/components/ui/Modal'
import Loader from '~/components/ui/Loader'
import { useRepayPayableMutation } from '~/store/api'
import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'

const RepaymentErrorModal = props => {
  const {
    initialErrorMessage,
  } = props

  const dispatch = useDispatch()
  const [_, repayPayableApiResult] = useRepayPayableMutation()

  const errorMessage = repayPayableApiResult.error?.data?.errors?.[0]?.message || initialErrorMessage

  const modal = useModal()
  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.REPAYMENT_ERROR }))
  }, [modal, dispatch])

  return (
    <Modal
      show
      className="p-5"
      size="xs"
      withCloseButton
      onClose={handleCloseModal}
    >
      {repayPayableApiResult.isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col">
          <FontAwesomeIcon
            icon="far fa-triangle-exclamation"
            className="mt-2 mb-8 text-orange text-5xl justify-self-center"
          />
          <p className="text-2xl font-semibold text-center mb-2">
            Insufficient funds
          </p>
          <p className="text-center text-base ">
            {errorMessage}
          </p>
        </div>
      )}
    </Modal>
  )
}

RepaymentErrorModal.propTypes = {
  payable: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }).isRequired,
  initialErrorMessage: PropTypes.string
}

RepaymentErrorModal.defaultProps = {
  initialErrorMessage: ''
}

export default NiceModal.create(RepaymentErrorModal)
