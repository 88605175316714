import React from 'react'
import PropTypes from 'prop-types'
import { Button as SharedButton } from 'stellar-web-shared'

import { useSetPrevRoute } from '~/hooks/useSetPrevRoute'

const Button = props => {
  const {
    storePrevRoute,
    onClick,
    ...otherProps
  } = props

  const handleClick = useSetPrevRoute(onClick, storePrevRoute)

  return (
    <SharedButton
      onClick={handleClick}
      {...otherProps}
    />
  )
}
Button.propTypes = {
  storePrevRoute: PropTypes.bool,
  onClick: PropTypes.func,
}
Button.defaultProps = {
  storePrevRoute: false,
  onClick: () => null,
}

export default Button
