/* eslint-disable consistent-return */
import { useState, useEffect, useRef } from 'react'

// can be used to make interval function calls in a more declarative and convenient hook-based way
// passing null interval value will clear the interval
// passing new interval value will clear the old and start a new interval
export function useInterval(callback, interval = 1, duration = 10) {
  const savedCallback = useRef()
  const [hasIntervalEnded, setHasIntervalEnded] = useState(false)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (interval !== null) {
      const intervalId = setInterval(tick, interval * 1000)
      setHasIntervalEnded(false)

      // if duration provided - we set up a timeout to stop interval calls on time, otherwise - infinite calls (or until null for interval passed)
      const stopIntervalId = duration ? setTimeout(() => {
        clearInterval(intervalId)
        setHasIntervalEnded(true)
      }, duration * 1000) : null

      return () => {
        clearInterval(intervalId)
        if (stopIntervalId) {
          clearTimeout(stopIntervalId)
        }
        setHasIntervalEnded(true)
      }
    }
  }, [interval, duration, setHasIntervalEnded])

  return {
    hasIntervalEnded,
  }
}
