import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useFetchUserQuery } from '~/store/api'
import {
  CLOUDFLARE_IMAGE_IDS,
  MODAL_HASH_BASED_IDS,
} from '~/constants'
import { getCloudflareImageURL } from '~/utils'

import StandardButton from '~/components/ui/Buttons/StandardButton'

import { getPromptDetais } from './utils'

const SwitchPaymentProviderPrompt = props => {
  const { isModal } = props

  const navigate = useNavigate()
  const location = useLocation()

  const {
    data: userData = {},
  } = useFetchUserQuery()
  const { user = {} } = userData

  const handleActivateNewCard = useCallback(async () => {
    navigate(`${location.pathname}#${MODAL_HASH_BASED_IDS.HIGHNOTE_TERMS_AND_CONDITIONS}`)
  }, [navigate, location.pathname])

  return (
    <>
      {isModal ? (
        <h1 className="text-32 font-bold mt-8">
          Your new rewards card is here!
        </h1>
      ) : null}
      <div className={`flex flex-col ${isModal ? 'justify-around h-4/5 md:h-auto' : 'lg:flex-row justify-between'}`}>
        <img
          src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.ACTIVATE_CARD_WITH_PHONE)}
          alt="connexpay to highnote transition"
          className="w-[260px] mx-auto"
        />
        <div className={`mx-auto w-full ${isModal ? 'md:mt-6' : 'md:w-[330px]'}`}>
          {!isModal ? (
            <h1 className="text-32 font-bold my-3">
              Your new rewards card is here!
            </h1>
          ) : null}
          <div className="flex flex-col">
            {getPromptDetais(user?.subscriptionPlan?.name).map(detail => (
              <div className="flex font-normal text-base items-center mb-1.5">
                <FontAwesomeIcon icon={detail.icon} color="blue" className="mr-2 w-4 h-4" />
                {detail.text}
              </div>
            ))}
          </div>
          <StandardButton
            variant="contained"
            className={`w-full mt-4 py-3 ${isModal ? 'mt-16 md:mt-6' : ''}`}
            color="blue"
            onClick={handleActivateNewCard}
            data-testid="stellar-dashboard-switch-payment-provider-prompt-btn"
          >
            Activate new card
          </StandardButton>
        </div>
      </div>
    </>
  )
}
SwitchPaymentProviderPrompt.propTypes = {
  isModal: PropTypes.bool
}
SwitchPaymentProviderPrompt.defaultProps = {
  isModal: false
}

const Memoized = React.memo(SwitchPaymentProviderPrompt)
export default Memoized
