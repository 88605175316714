import {
  USER_TAG_TYPE,
  REWARDS_TRANSACTIONS_TAG_TYPE,
} from './constants'

const getRewardsEndpoints = builder => ({
  // TODO: remove endpoint below after introducing new UI for rewards history section
  fetchCreditEntries: builder.query({
    query: () => '/credit_entries',
    providesTags: [REWARDS_TRANSACTIONS_TAG_TYPE],
  }),
  fetchRewards: builder.query({
    query: () => '/rewards',
    providesTags: [REWARDS_TRANSACTIONS_TAG_TYPE],
  }),
  redeemRewardPoints: builder.mutation({
    query: body => ({
      url: '/rewards/redeem_points',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, REWARDS_TRANSACTIONS_TAG_TYPE]),
  }),
  getRewardsTransactions: builder.query({
    query: () => '/rewards/transactions',
    providesTags: [REWARDS_TRANSACTIONS_TAG_TYPE],
  }),
  redeemPromoCode: builder.mutation({
    query: body => ({
      url: '/promo_codes/redeem',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, REWARDS_TRANSACTIONS_TAG_TYPE]),
  }),
})

export default getRewardsEndpoints
