/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
export function camelToSnakeCaseStr(str) {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

export function camelToSnakeCaseObj(obj) {
  if (!obj) {
    return {}
  }

  if (obj instanceof Array) {
    return obj.map(val => {
      if (typeof val === 'object') {
        val = camelToSnakeCaseObj(val)
      }
      return val
    })
  }

  const newObj = {}
  for (const origKey in obj) {
    if (obj.hasOwnProperty(origKey)) {
      const newKey = camelToSnakeCaseStr(origKey)

      let value = obj[origKey]
      if (value instanceof Array || (value !== null && value?.constructor === Object)) {
        value = camelToSnakeCaseObj(value)
      }
      newObj[newKey] = value
    }
  }

  return newObj
}
