import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import { MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'
import { useDeleteGoalMutation } from '~/store/api'
import { ROUTES } from '~/pages/constants'

import StandardButton from '~/components/ui/Buttons/StandardButton'
import Modal from '~/components/ui/Modal'

const CloseGoalModal = props => {
  const { goal } = props

  const modal = useModal()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [deleteGoal, deleteGoalApiResult] = useDeleteGoalMutation()

  const handleCloseModal = useCallback(async () => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.CLOSE_GOAL }))
  }, [modal, dispatch])

  const handleDeleteGoal = useCallback(async () => {
    const apiResult = await deleteGoal(goal.uuid)
    if (!apiResult.error) {
      navigate(ROUTES.DASHBOARD)
      handleCloseModal()
    }
  }, [deleteGoal, goal.uuid, navigate, handleCloseModal])

  return (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      data-testid="stellar-dashboard-goals-modal-close-goal"
    >
      <div className="p-4">
        <div className="flex justify-between items-start mb-4">
          <p className="text-lg font-semibold">
            Delete goal
          </p>
        </div>
        <p>Are you sure you want to delete this goal?</p>
        <div className="flex items-center mt-6 flex-col">
          <StandardButton
            variant="contained"
            className="!bg-orange-300 text-deepspace-700 w-full mb-3"
            onClick={handleDeleteGoal}
            data-testid="stellar-dashboard-goals-modal-close-goal-delete-btn"
          >
            Delete goal
          </StandardButton>

          <StandardButton
            variant="outlined"
            onClick={handleCloseModal}
            className="w-full"
            data-testid="stellar-dashboard-goals-modal-close-goal-cancel-btn"
          >
            Cancel
          </StandardButton>
        </div>
        {deleteGoalApiResult.error ? (
          <p
            data-testid="stellar-dashboard-goals-modal-close-goal-error"
            className="text-red mb-4"
          >
            {deleteGoalApiResult?.error?.data?.errors?.[0]?.message}
          </p>
        ) : null}
      </div>
    </Modal>
  )
}

CloseGoalModal.propTypes = {
  goal: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
}

export default NiceModal.create(CloseGoalModal)
