import { ROUTES } from '~/pages/constants'

export const FOOTER_NAVIGATION_ROUTES = [
  {
    route: ROUTES.DASHBOARD,
    testId: 'stellar-app-navigation-home-link',
    iconName: 'far fa-house',
    iconNameSelected: 'fa-solid fa-house',
    label: 'Home',
  },
  {
    route: ROUTES.PAYMENT_HISTORY,
    testId: 'stellar-app-navigation-payments-history-link',
    iconName: 'far fa-file-invoice-dollar',
    iconNameSelected: 'fa-sharp fa-solid fa-file-invoice-dollar',
    label: 'Payments',
  },
  {
    route: ROUTES.NEW_BILL,
    testId: 'stellar-app-navigation-add-bill-link',
    iconName: 'far fa-plus',
    label: 'Add bill',
  },
  {
    route: ROUTES.CREDIT_TOOLS,
    testId: 'stellar-app-navigation-credit-tools-link',
    iconName: 'far fa-file-invoice',
    iconNameSelected: 'fa-solid fa-file-invoice',
    label: 'Credit',
  },
  {
    route: ROUTES.REWARDS,
    testId: 'stellar-app-navigation-rewards-link',
    iconName: 'far fa-trophy',
    iconNameSelected: 'fa-solid fa-trophy',
    label: 'Rewards',
  }
]
