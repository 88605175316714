import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '~/components/ui/Modal/Desktop'
import Button from '~/components/ui/Button'
import Loader from '~/components/ui/Loader'
import { CLOUDFLARE_IMAGE_IDS, MODAL_IDS } from '~/constants'
import {
  usePostSubscriptionPlanMutation,
} from '~/store/api'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { MEMBER_SUBSCRIBED } from '~/services/segment'
import {
  formatCurrency,
  getCloudflareImageURL,
} from '~/utils'
import { removeModalFromQueue } from '~/store/modules/ui'

const UpgradeMembershipModal = props => {
  const {
    currentMembership,
    nextMembership,
  } = props

  const [hasUpgraded, setHasUpgraded] = useState(false)
  const [postSubscriptionPlan, postSubscriptionPlanApiResult] = usePostSubscriptionPlanMutation()
  const trackMemberSubscription = useSegmentEvents([MEMBER_SUBSCRIBED])
  const modal = useModal()
  const dispatch = useDispatch()

  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.UPGRADE_MEMBERSHIP_MODAL }))
  }, [modal, dispatch])

  const handleConfirmMembershipUpgrade = useCallback(async () => {
    const subscriptionResponse = await postSubscriptionPlan({ subscriptionId: nextMembership.uuid })

    if (!subscriptionResponse.error) {
      const { data: { user: { subscriptionPlan } } } = subscriptionResponse
      const eventData = {
        oldPlanName: currentMembership.name,
        oldPlanAmount: currentMembership.amount,
        oldPlanId: currentMembership.uuid,
        renewalType: subscriptionPlan.renewalType,
        renewalPeriod: subscriptionPlan.renewalPeriod,
        planName: subscriptionPlan.name,
        planAmount: subscriptionPlan.amount,
        planId: subscriptionPlan.subscriptionPlanUuid || subscriptionPlan.uuid
      }
      trackMemberSubscription(eventData)
      setHasUpgraded(true)
    }
  }, [currentMembership, nextMembership, postSubscriptionPlan, trackMemberSubscription, setHasUpgraded])

  return (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      data-testid="stellar-upgrade-membership-modal"
    >
      {hasUpgraded ? (
        <div className="p-5" data-testid="stellar-upgrade-membership-modal-upgraded-block">
          <img
            src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.HUMAN_IN_SPACE)}
            alt="human in space"
            className="mx-auto mb-5"
          />
          <p className="text-2xl font-semibold mb-2">
            Woohoo!
          </p>
          <p className="mb-5">
            Thank you for upgrading, you&apos;re ready for launch.
          </p>
          <Button
            variant="contained"
            className="w-full"
            onClick={handleCloseModal}
            data-testid="stellar-upgrade-membership-modal-save-btn"
          >
            Awesome!
          </Button>
        </div>
      ) : (
        <div className="p-5" data-testid="stellar-upgrade-membership-modal-not-upgraded-block">
          <div className="flex justify-between items-start mb-4">
            <p className="text-lg font-semibold">
              Confirm update to
              <span className="mx-1">
                {nextMembership?.name}
              </span>
              membership. This will update your monthly fee to
              <span className="mx-1" data-testid="stellar-upgrade-membership-modal-amount">
                {formatCurrency(nextMembership?.amount)}
              </span>
            </p>
            <Button
              size=""
              className="p-2"
              onClick={handleCloseModal}
              data-testid="stellar-upgrade-membership-modal-close-btn"
            >
              <FontAwesomeIcon
                icon="far fa-xmark"
                className="h-5 w-5"
              />
            </Button>
          </div>
          {postSubscriptionPlanApiResult.isLoading ? (
            <Loader data-testid="stellar-upgrade-membership-modal-loader" />
          ) : null}
          {postSubscriptionPlanApiResult.error ? (
            <p className="mb-4 text-red" data-testid="stellar-upgrade-membership-modal-error">
              {postSubscriptionPlanApiResult.error.data?.errors?.[0]?.message}
            </p>
          ) : null}
          <div className="flex justify-end items-center">
            <Button
              disabled={postSubscriptionPlanApiResult.isLoading}
              variant="outlined"
              className="mr-2.5"
              onClick={handleCloseModal}
              data-testid="stellar-upgrade-membership-modal-cancel-btn"
            >
              Cancel
            </Button>
            <Button
              disabled={postSubscriptionPlanApiResult.isLoading}
              variant="contained"
              onClick={handleConfirmMembershipUpgrade}
              data-testid="stellar-upgrade-membership-modal-confirm-btn"
            >
              Confirm
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}
UpgradeMembershipModal.propTypes = {
  currentMembership: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
  }),
  nextMembership: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
  }),
}
UpgradeMembershipModal.defaultProps = {
  currentMembership: null,
  nextMembership: null,
}

export default NiceModal.create(UpgradeMembershipModal)
