import {
  createSlice,
} from '@reduxjs/toolkit'

export const SPECIAL_ROUTES = {
  USER_MENU: 'user-menu',
}

const initialState = {
  previousRoutes: [],
  clearRouteOnUserMenuBackButtonClick: false,
  modalQueue: [],
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setPreviousRoute(state, action) {
      return {
        ...state,
        previousRoutes: [action.payload, ...state.previousRoutes],
      }
    },
    clearPreviousRoute(state) {
      return {
        ...state,
        previousRoutes: [...state.previousRoutes.slice(1)],
      }
    },
    clearAllPreviousRoutes(state) {
      return {
        ...state,
        previousRoutes: [],
        clearRouteOnUserMenuBackButtonClick: false,
      }
    },
    setClearRouteOnUserMenuBackButtonClick(state, action) {
      return {
        ...state,
        clearRouteOnUserMenuBackButtonClick: action.payload,
      }
    },
    addModalToQueue(state, action) {
      const isAlreadyInQueue = Boolean(state.modalQueue.find(modal => modal.id === action.payload.id))
      return isAlreadyInQueue ? state : {
        ...state,
        modalQueue: [...state.modalQueue, action.payload],
      }
    },
    removeModalFromQueue(state, action) {
      return {
        ...state,
        modalQueue: [...state.modalQueue.filter(modal => modal.id !== action.payload.id)],
      }
    },
    clearModalQueue(state) {
      return {
        ...state,
        modalQueue: [],
      }
    },
  },
})

export const {
  setPreviousRoute,
  clearPreviousRoute,
  clearAllPreviousRoutes,
  setClearRouteOnUserMenuBackButtonClick,
  addModalToQueue,
  removeModalFromQueue,
  clearModalQueue,
} = uiSlice.actions

export default uiSlice.reducer
