import {
  USER_TAG_TYPE,
  USER_SUGGESTED_BILLS_TAG_TYPE,
  USER_LINKED_BILLS_TAG_TYPE,
  REWARDS_TRANSACTIONS_TAG_TYPE,
} from '~/store/api/constants'

const getBillEndpoints = builder => ({
  getLinkedBills: builder.query({
    query: (params = {}) => ({
      url: '/bills',
      params,
    }),
    providesTags: [USER_LINKED_BILLS_TAG_TYPE],
  }),
  getSuggestedBills: builder.query({
    query: (params = {}) => ({
      url: '/user_suggested_bills',
      params,
    }),
    providesTags: [USER_SUGGESTED_BILLS_TAG_TYPE],
  }),
  dismissSuggestedBill: builder.mutation({
    query: billUuid => ({
      url: `/user_suggested_bills/${billUuid}/dismiss`,
      method: 'POST',
      // body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_SUGGESTED_BILLS_TAG_TYPE]),
  }),
  unlinkBill: builder.mutation({
    query: billUuid => ({
      url: `/bills/${billUuid}/unlink`,
      method: 'POST',
      // body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, USER_LINKED_BILLS_TAG_TYPE]),
  }),
  getBill: builder.query({
    query: billUuid => ({
      url: `/bills/${billUuid}`,
    }),
  }),
  createBill: builder.mutation({
    query: body => ({
      url: '/bills',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, USER_LINKED_BILLS_TAG_TYPE, USER_SUGGESTED_BILLS_TAG_TYPE, REWARDS_TRANSACTIONS_TAG_TYPE]),
  }),
  confirmBill: builder.mutation({
    query: ({ billUuid, body }) => ({
      url: `/bills/${billUuid}/confirm`,
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, USER_LINKED_BILLS_TAG_TYPE]),
  }),
  getBillPaymentOptions: builder.query({
    query: billUuid => ({
      url: `/bills/${billUuid}/payment_options`,
    }),
  }),
  migrateBillPayCard: builder.mutation({
    query: () => ({
      url: '/bill_pay_cards/migrate',
      method: 'POST',
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  getCreditCardClientToken: builder.query({
    query: () => ({
      url: '/bill_pay_cards/client_token',
    }),
  }),
})

export default getBillEndpoints
