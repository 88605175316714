import { REGISTRATION_STEPS } from '~/constants'
import { ROUTES } from '~/pages/constants'

export const getNextStepRoute = (user, isPreMembershipPlanEducationEnabled) => {
  if (user.registrationNextStep === REGISTRATION_STEPS.CONFIRM_IDENTITY) {
    return ROUTES.ACTIVATE_CREDIT_SCORE
  }

  if (user.registrationNextStep === REGISTRATION_STEPS.SET_SUBSCRIPTION) {
    return isPreMembershipPlanEducationEnabled ? ROUTES.CHOOSE_SUBSCRIPTION : ROUTES.SUBSCRIPTION
  }

  if (user.registrationNextStep === REGISTRATION_STEPS.LINK_BANK_ACCOUNT) {
    return ROUTES.DASHBOARD
  }

  if (user.registrationNextStep === REGISTRATION_STEPS.CONFIRM_ACCOUNT) {
    return ROUTES.CONFIRMATION
  }

  return ROUTES.DASHBOARD
}

export const getCompleteAccountSetupButtonLabel = user => {
  switch (user.registrationNextStep) {
    case REGISTRATION_STEPS.LINK_BANK_ACCOUNT:
      return 'Connect bank'
    case REGISTRATION_STEPS.SET_SUBSCRIPTION:
      return 'Choose subscription'
    default:
      return 'Complete account setup'
  }
}
