import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'
import classNames from 'classnames'

const Loader = props => {
  const {
    size,
    className,
    spinnerClassName,
    'data-testid': testId
  } = props

  return (
    <div
      className={size === 'xs' ? className : `flex justify-center items-center ${className}`}
      data-testid={testId}
    >
      <div
        className={classNames('mt-5 mb-6 spinner', {
          spinner: size === 'medium',
          'spinner-small': size === 'small',
          'spinner-xs': size === 'xs',
          [spinnerClassName]: spinnerClassName
        })}
        role="status"
      />
    </div>
  )
}

Loader.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  spinnerClassName: PropTypes.string,
  'data-testid': PropTypes.string,
}

Loader.defaultProps = {
  size: 'medium',
  className: '',
  spinnerClassName: '',
  'data-testid': 'stellar-loading-indicator',
}

const Memoized = React.memo(Loader)
export default Memoized
