import React from 'react'
import PropTypes from 'prop-types'

const DisclaimerContent = props => {
  const { isScoreProjectionAvailable, isMobile } = props

  return (
    <div
      data-testid="stellar-onboarding-credit-score-disclaimer-modal-content"
      className={`pb-8 px-0 md:px-5 ${isMobile ? '' : 'pt-5'}`}
    >
      <h2 className="text-2xl font-semibold mt-1 mb-3">
        Disclaimer
      </h2>
      {isScoreProjectionAvailable
        ? `This example projection is based on average historical results from StellarFi members.
         On-time payments through StellarFi can positively impact your credit score. Results may vary.`
        : 'This example projection is based on historical results of StellarFi members with a 580 credit score and $1000 in linked bills.'}
    </div>
  )
}

DisclaimerContent.propTypes = {
  isScoreProjectionAvailable: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

const Memoized = React.memo(DisclaimerContent)
export default Memoized
