import React from 'react'
import PropTypes from 'prop-types'

import FullScreen from './FullScreen'
import Popover from './Popover'

const UserMenu = props => {
  const {
    showLimitedHeader,
    isMobile,
    user,
    limitedAccess,
    ...otherProps
  } = props

  return isMobile ? (
    <FullScreen
      user={user}
      {...otherProps}
    />
  ) : (
    <Popover
      showLimitedHeader={showLimitedHeader}
      limitedAccess={limitedAccess}
      user={user}
    />
  )
}
UserMenu.propTypes = {
  showLimitedHeader: PropTypes.bool,
  isMobile: PropTypes.bool,
  user: PropTypes.shape({
    status: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  limitedAccess: PropTypes.bool.isRequired,
}
UserMenu.defaultProps = {
  isMobile: false,
  showLimitedHeader: false
}

const Memoized = React.memo(UserMenu)
export default Memoized
