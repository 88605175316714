import React from 'react'

export const LINK_ACCOUNT_VARIANTS = {
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
  V4: 'v4',
  V5: 'v5',
  V6: 'v6',
  V7: 'v7',
}

export const PROVIDER_TYPES = {
  PLAID: 'plaid',
  MX: 'mx'
}

export const BANK_LINK_REASONS = {
  ACCOUNT_SETUP: 'account_setup',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  HIGH_RISK: 'high_risk'
}

const MX_POST_MESSAGE_PING_EVENT = 'mx/ping'

export const MX_POST_MESSAGE_EVENTS_TO_FILTER = {
  [MX_POST_MESSAGE_PING_EVENT]: true,
}

export const MX_CONNECTION_ERRORS = {
  ACCOUNT_NUMBERS_UNAVAILABLE: 'ACCOUNT_NUMBERS_UNAVAILABLE'
}

export const MX_ERROR_STATE_WARNING_MESSAGES = {
  DENIED: 'The login credentials you entered are incorrect. Please re-enter your login information to try again.',
  CHALLENGED: 'Please complete the following authentication challenge to continue.',
  REJECTED: 'One or more of your authentication responses was incorrect. Please try again.',
  LOCKED: 'We were unable to complete the connection. Please log in to your online banking portal and follow the intructions to allow setup to continue.',
  IMPEDED: 'We were unable to complete the connection. Please log in to your online banking portal and follow the steps to resolve the issue.',
  DEGRADED: 'We were unable to complete the connection. Please try again later.',
  EXPIRED: 'The authentication answer(s) were not provided in time. Please try again.',
  ACCOUNT_NUMBERS_UNAVAILABLE: (
    <span>
      We were unable to connect to your bank account because we did not receive your account number.
      Please try again.
      <br />
      If the problem persists, please reach out to your financial institution to ensure
      that we have permission to connect to your bank account.
    </span>
  )
}
