import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Outlet, useSearchParams } from 'react-router-dom'
import { ROUTES } from '~/pages/constants'

import { buildUrlCurrentWithParams } from '~/utils/misc'

const PartnerRedirect = props => {
  const { children } = props

  const [searchParams] = useSearchParams()
  const partnerOnboardingId = searchParams.get('partner_onboarding_id')

  if (partnerOnboardingId) {
    const path = ROUTES.PARTNERS.SIGNUP.replace(':onboardingToken', partnerOnboardingId)
    const url = buildUrlCurrentWithParams(path, searchParams, ['partner_onboarding_id'])

    return (
      <Navigate to={url} replace />
    )
  }

  return children || <Outlet />
}

PartnerRedirect.propTypes = {
  children: PropTypes.node,
}

PartnerRedirect.defaultProps = {
  children: null,
}

export default React.memo(PartnerRedirect)
