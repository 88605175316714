import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { checkUserCardState, getIsCreditScoreProjectionAllowed } from '~/utils'
import { useGetCreditScoreProjectionsQuery } from '~/store/api'

import Button from '~/components/ui/Button'
import Loader from '~/components/ui/Loader'
import Popover from '~/components/ui/Popover'

import { CARD_STATES } from '~/constants'
import { CREDIT_VIEWS } from './constants'

const CreditViews = ({ user, defaultView, inHeader, chartOnly }) => {
  const [activeView, setActiveView] = useState(defaultView)
  const {
    data: projectionData = {},
    isLoading,
    error,
  } = useGetCreditScoreProjectionsQuery()

  const {
    projections,
  } = projectionData

  const isCreditScoreProjectionAllowed = getIsCreditScoreProjectionAllowed(user)
  const isCardUnlocked = checkUserCardState(user, CARD_STATES.OPEN)
  const isAllowed = isCardUnlocked && isCreditScoreProjectionAllowed

  const { component: ActiveView } = CREDIT_VIEWS[activeView]

  return isAllowed ? (
    <>
      {isLoading ? (
        <Loader
          className="text-gray-dark"
          data-testid="stellar-credit-score-projection-loader"
        />
      ) : null}
      {!isLoading && !error ? (
        <div className="relative" data-testid="stellar-credit-views">
          {!chartOnly && isAllowed ? (
            <Popover
              target={(
                <button type="button" className="grid place-items-center">
                  <FontAwesomeIcon
                    icon="far fa-chevron-down"
                    className="inline w-4 h-4 group-[.visible]:-rotate-180 transition-transform"
                  />
                  <span className="sr-only">Open available charts menu</span>
                </button>
              )}
              className="inline absolute right-[calc(1.25rem+6px)] top-[calc(1.25rem+6px)] group"
              containerProps={{
                className: 'px-0',
              }}
              data-testid="stellar-credit-views-popover"
            >
              {Object.values(CREDIT_VIEWS).map(({ popoverLabel, popoverTarget }) => (
                <Button
                  key={popoverLabel}
                  onClick={() => setActiveView(popoverTarget)}
                  className="!flex !justify-start w-full min-w-[200px] !font-normal text-base hover:bg-deepspace-50 hover:rounded px-4 py-3"
                  variant=""
                >
                  {popoverLabel}
                </Button>
              ))}
            </Popover>
          ) : null}
          {inHeader && isAllowed ? (
            <div className="flex gap-2 mx-5">
              {Object.values(CREDIT_VIEWS).map(({ popoverLabel, popoverTarget }) => (
                <Button
                  key={popoverTarget}
                  onClick={() => setActiveView(popoverTarget)}
                  className={classNames('!px-2 !text-white !text-sm', {
                    '!bg-deepspace-400/50': activeView === popoverTarget,
                    '!bg-transparent': activeView !== popoverTarget,
                    '!font-bold': activeView === popoverTarget,
                    '!font-normal': activeView !== popoverTarget
                  })}
                >
                  {popoverLabel}
                </Button>
              ))}
            </div>
          ) : null}
          <ActiveView
            user={user}
            projections={projections}
            chartOnly={chartOnly}
            inHeader={inHeader}
            isCreditScoreProjectionAllowed={isAllowed}
          />
        </div>
      ) : null}
    </>
  ) : null
}

CreditViews.propTypes = {
  user: PropTypes.shape({
    credit: PropTypes.shape({}),
    status: PropTypes.string.isRequired,
  }).isRequired,
  defaultView: PropTypes.oneOf(['Score', 'ScoreProjection']),
  chartOnly: PropTypes.bool,
  inHeader: PropTypes.bool
}

CreditViews.defaultProps = {
  defaultView: 'Score',
  chartOnly: false,
  inHeader: false,
}

export default CreditViews
