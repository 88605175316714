export const HEROKU_ENVS = {
  REVIEW: 'review',
  STAGING: 'staging',
  PRODUCTION: 'production',
}

export const NODE_ENVS = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
}
