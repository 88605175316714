import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useInfiniteScroll from '~/hooks/useInfiniteScroll'
import {
  useLazyGetNotificationsQuery,
  useReadAllNotificationsMutation,
} from '~/store/api'
import {
  selectIsMobile,
} from '~/store/modules/dimensions/selectors'

import StandardButton from '~/components/ui/Buttons/StandardButton'
import FullScreenNotificationsList from './FullScreen'
import NotificationsPopover from './Popover'

const Notifications = () => {
  const isMobile = useSelector(selectIsMobile)

  const {
    listData: notificationsList,
    loadMore,
    isFetching: isFetchingNotifications,
    error,
  } = useInfiniteScroll(
    useLazyGetNotificationsQuery,
    useMemo(() => ({}), [])
  )

  const [readAllNotifications] = useReadAllNotificationsMutation()

  const [isListOpen, setIsListOpen] = useState(false)
  const handleOpenNotifications = useCallback(() => setIsListOpen(true), [setIsListOpen])
  const handleCloseNotifications = useCallback(() => setIsListOpen(false), [setIsListOpen])
  const toggleNotificationsList = useCallback(() => setIsListOpen(prev => !prev), [setIsListOpen])
  const [hasReadNotifications, setHasReadNotifications] = useState(false)

  useEffect(() => {
    if (isListOpen) {
      setTimeout(async () => {
        try {
          await readAllNotifications()
          setHasReadNotifications(true)
        } catch (err) {
          // TODO: add error handling
        }
      }, 2000)
    }
  }, [isListOpen, readAllNotifications])

  const handleListScroll = useCallback(event => {
    const {
      scrollTop,
      clientHeight,
      scrollHeight,
    } = event.target

    if (scrollTop + clientHeight > scrollHeight - 200) {
      loadMore()
    }
  }, [loadMore])

  const hasUnreadNotifications = Boolean(notificationsList?.find(notification => !notification.readAt))

  return isMobile ? (
    <div>
      <StandardButton
        color=""
        variant=""
        size=""
        className="relative md:mx-5"
        data-testid="stellar-notifications-popover-target"
        onClick={toggleNotificationsList}
      >
        {hasUnreadNotifications && !hasReadNotifications && (
          <span
            className="right-0 top-0 w-2 h-2 inline-block rounded bg-red absolute"
            data-testid="stellar-notifications-read-all"
          />
        )}
        <FontAwesomeIcon icon="fa-regular fa-bell" className="text-white h-6 mt-1" />
      </StandardButton>
      <FullScreenNotificationsList
        isListOpen={isListOpen}
        notificationsList={notificationsList}
        hasReadNotifications={hasReadNotifications}
        isFetchingNotifications={isFetchingNotifications}
        error={error}
        onScroll={handleListScroll}
        onClose={handleCloseNotifications}
      />
    </div>
  ) : (
    <NotificationsPopover
      notificationsList={notificationsList}
      hasReadNotifications={hasReadNotifications}
      hasUnreadNotifications={hasUnreadNotifications}
      isFetchingNotifications={isFetchingNotifications}
      error={error}
      onScroll={handleListScroll}
      onOpen={handleOpenNotifications}
      onClose={handleCloseNotifications}
    />
  )
}

export default React.memo(Notifications)
