import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { MODAL_IDS } from '~/constants'
import { useGetUserAgreementQuery } from '~/store/api'
import { addModalToQueue } from '~/store/modules/ui'

function UserAgreement() {
  const dispatch = useDispatch()
  const { data = {} } = useGetUserAgreementQuery()
  const { newVersionRequired, newVersion } = data

  useEffect(() => {
    if (newVersionRequired) {
      dispatch(addModalToQueue({
        id: MODAL_IDS.USER_AGREEMENT,
        props: {
          newVersion,
        }
      }))
    }
  }, [newVersionRequired, dispatch, newVersion])

  return null
}

export default React.memo(UserAgreement)
