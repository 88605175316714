import {
  USER_TAG_TYPE,
} from '~/store/api/constants'

const getEnrollmentEndpoints = builder => ({
  fetchEnrollmentUser: builder.query({
    query: (params = {}) => ({
      url: 'enrollment_users',
      params,
    }),
  }),
  registerEnrollmentUser: builder.mutation({
    query: body => ({
      url: '/enrollment_users',
      method: 'POST',
      body: {
        ...body,
        passwordConfirmation: body.password,
      },
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
})

export default getEnrollmentEndpoints
