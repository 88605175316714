import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  updateDimensions,
} from '~/store/modules/dimensions'

export const useWindowResizeListener = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const onResize = event => {
      dispatch(updateDimensions({
        width: event.target.innerWidth,
        height: event.target.innerHeight,
      }))
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [dispatch])

  return null
}
