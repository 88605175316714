export const HIGHNOTE_PRODUCT_APPLICATION_STATUSES = {
  PENDING: 'pending',
  IN_REVIEW: 'in_review',
  DENIED: 'denied',
  APPROVED: 'approved',
  CLOSED: 'closed'
}

export const HIGHNOTE_PRODUCT_APPLICATION_UNDERWRING_STATUSES = {
  APPROVED: 'approved',
  PENDING: 'pending',
  DENIED: 'denied'
}

export const HIGHNOTE_ESIGN_AGREEMENT_LINK = 'https://stellar-agreement-templates.s3.amazonaws.com/Electronic+Signature+Acknowledgement.pdf'
export const HIGHNOTE_ACH_AGREEMENT_LINK = 'https://stellar-agreement-templates.s3.amazonaws.com/ACH+Agreement.pdf'
export const HIGHNOTE_CREDIT_CARD_AGREEMENT_LINK = 'https://stellar-agreement-templates.s3.amazonaws.com/Credit+Card+Agreement.pdf'
