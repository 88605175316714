export const ONBOARDING_PROGRESS_STEPS = [
  {
    label: 'Get credit score',
    activeOnSteps: [1]
  },
  {
    label: 'Find bills',
    activeOnSteps: [2],
  },
  {
    label: 'Build credit',
    activeOnSteps: [3],
  },
]
