import { ROUTES } from '~/pages/constants'

export const SETTINGS_PAGE_LINKS = [
  {
    label: 'Personal Information',
    route: ROUTES.SETTINGS.PERSONAL_INFORMATION,
    value: 1,
  },
  {
    label: 'Membership',
    route: ROUTES.SETTINGS.MEMBERSHIP,
    value: 3,
  },
  {
    label: 'Password',
    route: ROUTES.SETTINGS.PASSWORD,
    value: 4,
  },
  {
    label: 'Linked Bank',
    route: ROUTES.SETTINGS.LINKED_ACCOUNTS,
    value: 5,
  },
  {
    label: 'Legal',
    route: ROUTES.SETTINGS.LEGAL,
    value: 6,
  },
  {
    label: 'Documents',
    route: ROUTES.SETTINGS.DOCUMENTS,
    value: 7,
  },
  // {
  //   label: 'Notifications',
  //   route: ROUTES.SETTINGS.NOTIFICATIONS,
  //   value: 8,
  // },
  // {
  //   label: 'Virtual card and ACH',
  //   route: ROUTES.SETTINGS.PAYMENT_INFORMATION,
  //   value: 9,
  // },
]
