import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Outlet, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTES } from '~/pages/constants'
import SiteHeader from '~/features/Headers/Site'

// TODO: refactor layout styles logic
const SiteLayout = props => {
  const { children } = props
  const location = useLocation()

  const hasBg = useMemo(() => {
    if ([
      ROUTES.LOGIN,
      ROUTES.VERIFY_LOGIN,
      ROUTES.REQUEST_PASSWORD,
      ROUTES.SECURITY_PASSWORD_RESET,
    ].includes(location.pathname)) return false

    if (location.pathname.split('/').includes('reset-password')) return false
    if ([
      ROUTES.ROOT,
      ROUTES.LOGIN,
      ROUTES.VERIFY_LOGIN,
      ROUTES.SIGNUP,
      ROUTES.REQUEST_PASSWORD,
      ROUTES.SUBSCRIPTION,
      ROUTES.CLOSED_ACCOUNT,
      ROUTES.SECURITY_PASSWORD_RESET,
    ].includes(location.pathname)) return true
    return false
  }, [location.pathname])

  return (
    <div className={classNames('relative', {
      'landing text-default': hasBg,
      'w-screen': !hasBg,
      'h-screen': true,
    })}
    >
      <SiteHeader hasBg={hasBg} />
      {children || <Outlet />}
    </div>
  )
}
SiteLayout.propTypes = {
  children: PropTypes.node,
}
SiteLayout.defaultProps = {
  children: null,
}

export default React.memo(SiteLayout)
