import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'

import MobileModal from '~/components/ui/Modal/Mobile'
import DesktopModal from '~/components/ui/Modal/Desktop'

import Content from './Content'

const PrimeGuaranteeReferFriendModal = props => {
  const { onReferFriend } = props

  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handlePrimeGuaranteeModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.REFER_FRIEND }))
  }, [modal, dispatch])

  const handleReferPrimeGuaranteeFriend = useCallback(() => {
    handlePrimeGuaranteeModalClose()
    onReferFriend()
  }, [handlePrimeGuaranteeModalClose, onReferFriend])

  return isMobile
    ? (
      <MobileModal
        show
        size="md"
        className="p-5"
        onClose={handlePrimeGuaranteeModalClose}
        withButton
        withCloseButton
      >
        <Content onPrimeGuaranteeReferFriend={handleReferPrimeGuaranteeFriend} />
      </MobileModal>
    ) : (
      <DesktopModal
        show
        size="md"
        className="p-5"
        onClose={handlePrimeGuaranteeModalClose}
        withCloseButton
      >
        <Content onPrimeGuaranteeReferFriend={handleReferPrimeGuaranteeFriend} />
      </DesktopModal>
    )
}

PrimeGuaranteeReferFriendModal.propTypes = {
  onReferFriend: PropTypes.func.isRequired
}

const Memoized = React.memo(PrimeGuaranteeReferFriendModal)
export default NiceModal.create(Memoized)
