import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useFetchUserQuery } from '~/store/api'
import { selectIsMobile, selectIsTablet } from '~/store/modules/dimensions/selectors'
import { REGISTRATION_STEPS, USER_STATUSES, EXPERIMENTS, HIGHNOTE_PRODUCT_APPLICATION_STEPS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'
import { ROUTES } from '~/pages/constants'
import { checkIsClosedAccount, checkIsLockedAccount, checkIsLockedNotCollectable } from '~/utils'

import MobileAppHeader from './Mobile'
import DesktopAppHeader from './Desktop'
import { getHeaderPadding } from './utils'

const AppHeader = props => {
  const location = useLocation()
  const isMobile = useSelector(selectIsMobile)
  const isTablet = useSelector(selectIsTablet)

  const {
    data = {},
  } = useFetchUserQuery()
  const { user = {} } = data

  const { enabled: isShutdownCreateAccountEnabled } = useExperiment(EXPERIMENTS.SHUTDOWN_CREATE_ACCOUNT)

  const withPadding = useMemo(() => getHeaderPadding(location.pathname), [location.pathname])
  const isAllSet = user.registrationNextStep === REGISTRATION_STEPS.ALL_SET
  const isUnverifiedUser = user?.status === USER_STATUSES.UNVERIFIED
  const isClosedAccount = checkIsClosedAccount(user)
  const isLockedAccount = checkIsLockedAccount(user)
  const isLockedNotCollectable = checkIsLockedNotCollectable(user)
  const hideLogo = isTablet && location.pathname === ROUTES.DASHBOARD
  const limitedAccess = isShutdownCreateAccountEnabled && isUnverifiedUser
  const limitedHeaderForLockedAccount = isLockedAccount && isLockedNotCollectable
  const isNewHighnoteCardIssued = isUnverifiedUser && user?.productCardRegistrationStep === HIGHNOTE_PRODUCT_APPLICATION_STEPS.ISSUE_CARD
  const showLimitedHeader = (!isAllSet && !isClosedAccount) || limitedAccess || limitedHeaderForLockedAccount || isNewHighnoteCardIssued

  return (
    <header
      className="relative bg-deepspace-700"
      data-testid="stellar-app-header"
    >
      {isMobile ? (
        <MobileAppHeader
          user={user}
          withPadding={withPadding}
          showLimitedHeader={showLimitedHeader}
          limitedAccess={limitedAccess}
          {...props}
        />
      ) : (
        <DesktopAppHeader
          user={user}
          withPadding={withPadding}
          showLimitedHeader={showLimitedHeader}
          hideLogo={hideLogo}
          limitedAccess={limitedAccess}
        />
      )}
    </header>
  )
}

const Memoized = React.memo(AppHeader)
export default Memoized
