import React from 'react'
import PropTypes from 'prop-types'

import ReactivateAccount from './ReactivateAccount'
import RelinkBankAccount from './RelinkBankAccount'

const componentNames = {
  ReactivateAccount,
  RelinkBankAccount,
}

const CustomMessages = props => {
  const {
    snackbarMessage
  } = props

  const Component = componentNames[snackbarMessage.componentName]

  return Component ? <Component {...props} /> : null
}
CustomMessages.propTypes = {
  snackbarMessage: PropTypes.shape({
    key: PropTypes.string.isRequired,
    componentName: PropTypes.string,
  }).isRequired,
}

export default React.memo(CustomMessages)
