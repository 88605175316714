import React from 'react'
import PropTypes from 'prop-types'

import { EXPERIAN_STATUSES } from '~/constants'

const CreditScoreTitle = props => {
  const {
    user,
  } = props

  const {
    credit,
    experianStatus,
  } = user

  const isScoreAvailable = Boolean(credit)
  const isSubscribed = experianStatus === EXPERIAN_STATUSES.SUBSCRIBED

  return isSubscribed ? (
    <>
      <p className="text-lg font-semibold">
        {isScoreAvailable ? 'Credit score' : 'Sample credit score'}
      </p>
      {isScoreAvailable ? null : (
        <p className="mb-2.5">
          <span className="text-sm font-normal leading-6">
            Your credit score will appear here shortly.
          </span>
        </p>
      )}
    </>
  ) : (
    <p className="text-lg font-semibold">
      Sample credit score
    </p>
  )
}

CreditScoreTitle.propTypes = {
  user: PropTypes.shape({
    credit: PropTypes.shape({
    }),
    experianStatus: PropTypes.string.isRequired,
    registrationNextStep: PropTypes.string,
    spendLimitCents: PropTypes.number.isRequired,
  }).isRequired,
}

export default React.memo(CreditScoreTitle)
