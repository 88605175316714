export const chartAreaBorderPlugin = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const { ctx, chartArea: { left, top, width, height } } = chart
    ctx.save()
    ctx.strokeStyle = options.borderColor
    ctx.lineWidth = options.borderWidth
    ctx.setLineDash(options.borderDash || [])
    ctx.lineDashOffset = options.borderDashOffset
    ctx.strokeRect(left, top, width, height)
    ctx.restore()
  }
}

export const chartAreaBorderBottomPlugin = {
  id: 'chartAreaBorderBottom',
  beforeDraw(chart, args, options) {
    const { ctx, chartArea: { left, bottom, right, height } } = chart

    if (options.borderBottomColor) {
      ctx.beginPath()
      ctx.moveTo(left, bottom)
      ctx.lineTo(right, height + 10)
      ctx.stroke()
    }

    ctx.restore()
  }
}
