import React from 'react'

import { CREDIT_SCORE_INCREASE_TERMS_AND_CONDITIONS, UNLINK_BILLS, UNLINK_BILL_INSTRUCTIONS } from '~/constants'

import Link from '~/components/ui/Link'

import {
  ADD_BILL_STEP,
  BILL_PAYMENT_STEP,
  CREDIT_SCORE_INCREASE,
} from '~/pages/Dashboard/Banner/OnboardingProgress/constants'

export const MODAL_CONTENT = {
  [ADD_BILL_STEP]: {
    key: 'add_first_bill',
    header: 'Link your first bill',
    getDescription: () => (
      <div
        className="mt-3 mb-6"
        data-testid="stellar-dashboard-modals-learn-more-modal-description"
      >
        More bills linked = more credit score growth potential. Link a bill by using your StellarFi card as the payment method.
        <span className="mx-1">
          Each month, we&apos;ll increase your reported limit by the total amount of bills you paid with your StellarFi card that month.
        </span>
        Link and pay $100 in bills, get a $100 increase. Link and pay $400 in bills, get a $400 increase.
      </div>
    ),
  },
  [BILL_PAYMENT_STEP]: {
    key: 'one_time_payment',
    header: 'Easy, on-time bill payments',
    getDescription: () => (
      <div
        className="mt-3 mb-6"
        data-testid="stellar-dashboard-modals-learn-more-modal-description"
      >
        When your bill is due, we&apos;ll make the payment (if you have enough funds in your checking account)
        then make a pre-authorized withdrawal of the payment amount from your checking account.
        With StellarFi, you easily can view and manage all your linked bills, including upcoming due dates and payment status, in one easy dashboard.
      </div>
    ),
  },
  [CREDIT_SCORE_INCREASE]: {
    key: 'credit_score_increase',
    header: 'Guaranteed credit score increase',
    getDescription: () => (
      <div
        className="mt-3 mb-6"
        data-testid="stellar-dashboard-modals-learn-more-modal-description"
      >
        We report to the credit bureaus biweekly, and your credit report will update in your dashboard once a month.
        Because StellarFi optimizes all of the most influential factors that make up your credit score,
        we guarantee that you&apos;ll see your credit score increase during the first three months of using StellarFi,
        or we&apos;ll refund your membership fees! See full terms
        {' '}
        <Link
          variant=""
          target="_blank"
          href={CREDIT_SCORE_INCREASE_TERMS_AND_CONDITIONS}
          data-testid="stellar-dashboard-modals-learn-more-modal-description-link"
        >
          here.
        </Link>
      </div>
    ),
  },
  [UNLINK_BILLS]: {
    key: UNLINK_BILLS,
    header: 'How to unlink your bill',
    getDescription: () => (
      <div className="flex-col text-base flex-wrap my-4">
        {UNLINK_BILL_INSTRUCTIONS.map(instruction => (
          <div className="font-semibold flex" key={instruction.title}>
            <p className="inline-block">{instruction.title}</p>
            <span className="ml-2 mb-1 font-normal">
              {instruction.description}
            </span>
          </div>
        ))}
      </div>
    ),

  }
}
