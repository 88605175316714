import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from '~/components/ui/Button'
import Modal from '~/components/ui/Modal'
import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'

const AccountReopenSuccessModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()

  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.ACCOUNT_REOPEN_SUCCESS }))
  }, [modal, dispatch])

  return (
    <Modal
      show
      className="p-5"
      size="xs"
      onClose={handleCloseModal}
      data-testid="stellar-returned-payments-modal"
    >
      <div className="flex flex-col">
        <FontAwesomeIcon
          icon="far fa-circle-check"
          className="mt-2 mb-8 text-green text-5xl justify-self-center"
        />
        <p className="text-2xl font-semibold text-center mb-2">
          You&apos;re back on track
        </p>
        <p className="text-center text-base ">
          We cancelled your request to close your account.
        </p>
        <div className="flex items-center mt-6 justify-self-center">
          <Button
            variant="outlined"
            className="w-full"
            onClick={handleCloseModal}
            data-testid="stellar-returned-payments-modal-great-btn"
          >
            Great!
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NiceModal.create(AccountReopenSuccessModal)
