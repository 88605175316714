import React from 'react'
import PropTypes from 'prop-types'

import Button from '~/components/ui/Button'

const ConfirmLinkPaymentModalBody = props => {
  const { isMobile, handleConfirmLinkPaymentCardModalClose } = props

  return (
    <div>
      <h2 className="font-semibold mb-5 mt-2">
        How StellarFi pays bills and builds your credit
      </h2>
      <div>
        <p className="mb-5">
          We pay your bills using your StellarFi Bill Pay Card, then we withdraw the amount from your bank between
          {' '}
          <span className="font-bold">1-3 business days.</span>
        </p>
        <p className="mb-5">
          StellarFi will only pay the bill if you have enough funds in your linked bank account.
          {' '}
          <span className="font-bold">We never overdraft you.</span>
        </p>
        <p className="mb-5">
          <span className="font-bold">We report each successful payment</span>
          {' '}
          to the credit bureaus, helping increase your score.
        </p>
      </div>
      {!isMobile ? (
        <Button
          variant="outlined"
          className="w-full mb-2 !text-lightspeed-600 !border-lightspeed-600"
          onClick={handleConfirmLinkPaymentCardModalClose}
        >
          Ok, got it
        </Button>
      ) : null}
    </div>
  )
}
ConfirmLinkPaymentModalBody.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  handleConfirmLinkPaymentCardModalClose: PropTypes.func.isRequired
}

const Memoized = React.memo(ConfirmLinkPaymentModalBody)
export default Memoized
