import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'
import { addSnackbarMessage } from '~/store/modules/snackbar'
import { useDeleteDocumentMutation } from '~/store/api'

import Button from '~/components/ui/Button'
import Modal from '~/components/ui/Modal'

const DeleteDocumentModal = props => {
  const {
    docUuid,
  } = props

  const modal = useModal()
  const dispatch = useDispatch()
  const [deleteDocument] = useDeleteDocumentMutation()

  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.DELETE_DOCUMENT }))
  }, [modal, dispatch])

  const handleDeleteDocument = useCallback(async () => {
    const apiResult = await deleteDocument(docUuid)

    if (apiResult.error) {
      dispatch(addSnackbarMessage({
        message: 'There was an issue deleting your file, please try again or contact support.',
        status: 'error',
      }))
    }

    modal.resolve()
    handleCloseModal()
  }, [modal, dispatch, docUuid, deleteDocument, handleCloseModal])

  return (
    <Modal
      show
      size="sm"
      onClose={handleCloseModal}
      data-testid="stellar-modals-delete-document"
    >
      <div className="p-3">
        <div className="flex justify-between items-start mb-4">
          <p className="text-lg font-semibold">Delete document</p>
          <Button
            size=""
            onClick={handleCloseModal}
          >
            <FontAwesomeIcon
              icon="far fa-xmark"
              className="h-5 w-5"
            />
          </Button>
        </div>
        <p>Are you sure you want to delete this document?</p>
        <div className="flex items-center mt-6 flex-col">
          <Button
            variant="contained"
            color="red"
            className="flex-grow w-full mb-3"
            onClick={handleDeleteDocument}
            data-testid="stellar-modals-delete-document-submit-btn"
          >
            Delete document
          </Button>
          <Button
            variant="outlined"
            className="w-full"
            onClick={handleCloseModal}
            data-testid="stellar-modals-delete-document-cancel-btn"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

DeleteDocumentModal.propTypes = {
  docUuid: PropTypes.string.isRequired,
}

export default NiceModal.create(DeleteDocumentModal)
