import React from 'react'
import { useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'

import { selectSnackbarMessages } from '~/store/modules/snackbar/selectors'
import Message from '~/features/SnackbarMessageQueue/Message'

const SnackbarMessageQueue = () => {
  const snackbarMessages = useSelector(selectSnackbarMessages)

  return (
    <AnimatePresence mode={snackbarMessages.length <= 3 ? 'wait' : 'sync'}>
      {snackbarMessages.map(snackbarMessage => (
        <Message
          key={snackbarMessage.key}
          snackbarMessage={snackbarMessage}
        />
      ))}
    </AnimatePresence>
  )
}

export default React.memo(SnackbarMessageQueue)
