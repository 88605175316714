import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ROUTES } from '~/pages/constants'
import { useUpdateUserSettingsMutation } from '~/store/api'
import { ANIMATIONS, MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'

import Modal from '~/components/ui/Modal'
import CTALink from '~/components/ui/Links/CTALink'

const CreditScoreIncreaseModal = props => {
  const { name, creditScoreIncrease, creditUuid } = props

  const modal = useModal()
  const dispatch = useDispatch()

  const [updateUserSettings, updateUserSettingsApiResult] = useUpdateUserSettingsMutation()

  const handleCloseModal = useCallback(async () => {
    const apiResult = await updateUserSettings({ latestScoreChangeViewed: creditUuid })

    if (!apiResult.error) {
      modal.remove()
      dispatch(removeModalFromQueue({ id: MODAL_IDS.CREDIT_SCORE_INCREASE }))
    }
  }, [modal, updateUserSettings, creditUuid, dispatch])

  const title = `Hey ${name}, your score went up ${creditScoreIncrease} ${creditScoreIncrease === 1 ? 'point' : 'points'} \u{1F680}`

  return (
    <Modal
      show
      size="md"
      withCloseButton
      onClose={handleCloseModal}
      className="p-6"
      animation={ANIMATIONS.EXPLODING_CONFETTI}
      data-testid="stellar-dashboard-modals-credit-score-increase"
    >
      <h1
        data-testid="stellar-dashboard-modals-credit-score-increase-title"
        className="font-semibold text-2xl mb-4 mr-6"
      >
        {title}
      </h1>
      <p className="text-base font-normal">
        Keep it up! Add more bills to increase your score even more next month.
      </p>
      <CTALink
        to={ROUTES.NEW_BILL}
        className="w-full mt-5"
        onClick={handleCloseModal}
        variant="contained"
        storePrevRoute
        data-testid="stellar-dashboard-modals-credit-score-increase-link"
      >
        Link bill
        <FontAwesomeIcon
          icon="far fa-link"
          size="sm"
          className="ml-2"
        />
      </CTALink>
      {updateUserSettingsApiResult.error ? (
        <p
          data-testid="stellar-dashboard-modals-credit-score-increase-error"
          className="mt-2 text-red text-sm"
        >
          {updateUserSettingsApiResult.error?.data?.errors?.[0]?.message}
        </p>
      ) : null}
    </Modal>
  )
}

CreditScoreIncreaseModal.propTypes = {
  name: PropTypes.string.isRequired,
  creditScoreIncrease: PropTypes.number.isRequired,
  creditUuid: PropTypes.string.isRequired
}

export default NiceModal.create(CreditScoreIncreaseModal)
