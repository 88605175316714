import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import withPlaidLink from '~/features/BankAccountManagement/Plaid/hocs/withPlaidLink'
import Button from '~/components/ui/Button'
import { ADD_BANK_CLICKED } from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'

import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'
import { LINK_ACCOUNT_VARIANTS } from '../../constants'

const PlaidLink = props => {
  const {
    accounts,
    openPlaid,
    disabled,
    variant,
    btnClassName,
    btnText,
    onClick,
  } = props

  const trackAddBankClicked = useSegmentEvents([ADD_BANK_CLICKED])

  const handleOpenPlaid = useCallback(e => {
    onClick(e)
    openPlaid(e)
    trackAddBankClicked({ provider: BANK_ACCOUNT_PROVIDERS.PLAID })
  }, [openPlaid, trackAddBankClicked, onClick])

  return (
    <div
      data-testid="stellar-link-bank-account-plaid-link"
    >
      {variant === LINK_ACCOUNT_VARIANTS.V1 ? (
        <Button
          variant="contained"
          size=""
          className={`w-full text-base py-4 my-5 ${btnClassName}`}
          onClick={handleOpenPlaid}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn"
        >
          <FontAwesomeIcon
            icon="far fa-plus"
            size="sm"
            className="mr-2 text-white"
          />
          <span
            className="text-white"
            data-testid="stellar-link-bank-account-plaid-btn-text"
          >
            {accounts.length > 0 ? 'Add another account' : 'Add checking account'}
          </span>
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V2 ? (
        <Button
          variant="contained"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          onClick={handleOpenPlaid}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v2"
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V4 ? (
        <Button
          variant="outlined"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          onClick={handleOpenPlaid}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v4"
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V5 ? (
        <Button
          variant="contained"
          color="green"
          size="sm"
          className={`w-full ${btnClassName}`}
          onClick={handleOpenPlaid}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v5"
        >
          {btnText || 'Connect bank'}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V6 ? (
        <Button
          variant=""
          size=""
          className={`w-full text-deepspace hover:text-lightspeed underline font-bold text-base ${btnClassName}`}
          onClick={handleOpenPlaid}
          disabled={disabled}
        >
          {btnText}
        </Button>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V7 ? (
        <Button
          variant=""
          size="md"
          className="bg-deepspace-50 w-full text-deepspace underline font-semibold text-lg hover:text-lightspeed p-3"
          onClick={handleOpenPlaid}
          disabled={disabled}
          data-testid="stellar-link-bank-account-plaid-btn-v7"
        >
          {btnText || 'Connect bank'}
        </Button>
      ) : null}
    </div>
  )
}
PlaidLink.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  openPlaid: PropTypes.func.isRequired,
  variant: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  btnClassName: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
}
PlaidLink.defaultProps = {
  variant: LINK_ACCOUNT_VARIANTS.V1,
  btnClassName: '',
  btnText: 'Connect bank',
  onClick: () => {},
}

export default withPlaidLink(PlaidLink)
