/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import { DateTime } from 'luxon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'react-datepicker/dist/react-datepicker.css'

import { DATE_FORMATS, NUMBERS_ONLY_REGEX, formatDate, getDateTime } from '~/utils'

import Button from '~/components/ui/Button'

import { formatDateWithSlash } from './utils'
import './styles.scss'

const DatePickerNew = props => {
  const {
    value,
    isModal,
    maxDate,
    showIcon,
    onChange,
    onIconClick,
    showYearDropdown,
    calendarClassName,
    showMonthDropdown,
    dateFormatCalendar,
    scrollableYearDropdown,
    yearDropdownItemNumber,
    isDisplayedWithCalendarPopper,
  } = props

  const maxYear = DateTime.fromISO(maxDate).year

  const handleChangeRaw = useCallback(event => {
    const inputDate = event.target.value

    if (inputDate) {
      let formattedInputDate = inputDate.replace(NUMBERS_ONLY_REGEX, '') // Remove non-numeric characters

      if (formattedInputDate?.length === 1 && value?.length > formattedInputDate?.length) {
        onChange('')  // allow to remove single character from input comment
      } else {
        formattedInputDate = formatDateWithSlash(formattedInputDate, maxYear)
      }
      getDateTime(new Date(formattedInputDate)).toISODate() > maxDate && formattedInputDate.length === 10
        ? onChange(formatDate(maxDate, DATE_FORMATS.TWO_DIGIT_MONTH_DAY))
        : onChange(formattedInputDate)
    }
  }, [maxYear, value, onChange, maxDate])

  return (
    <DatePicker
      inline={isModal}
      selected={value}
      value={value}
      onChange={date => onChange(date, false)}
      onChangeRaw={handleChangeRaw}
      showMonthDropdown={showMonthDropdown}
      showYearDropdown={showYearDropdown}
      dateFormatCalendar={dateFormatCalendar}
      yearDropdownItemNumber={yearDropdownItemNumber}
      scrollableYearDropdown={scrollableYearDropdown}
      maxDate={maxDate}
      placeholderText="mm/dd/yyyy"
      calendarClassName={`w-full ${calendarClassName}`}
      showIcon={showIcon}
      dropdownMode="scroll"
      className="w-full focus-visible:outline-0 hover:bg-steel-50 rounded slide-down"
      popperClassName={isDisplayedWithCalendarPopper ? 'block' : 'hidden'}
      toggleCalendarOnIconClick={isDisplayedWithCalendarPopper}
      onClick={onIconClick}
      icon={(
        <Button onClick={onIconClick} variant="" className="!p-0">
          <FontAwesomeIcon
            icon="far fa-calendar"
            className="!h-[15px] !w-[15px] p-0 pt-2"
          />
        </Button>
      )}
    />
  )
}

DatePickerNew.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  dateFormatCalendar: PropTypes.string,
  showYearDropdown: PropTypes.bool,
  yearDropdownItemNumber: PropTypes.number,
  scrollableYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  showIcon: PropTypes.bool,
  maxDate: PropTypes.string,
  isDisplayedWithCalendarPopper: PropTypes.bool,
  isModal: PropTypes.bool,
  onIconClick: PropTypes.func.isRequired,
  calendarClassName: PropTypes.string
}

DatePickerNew.defaultProps = {
  value: '',
  dateFormatCalendar: 'MMMM d, yyyy',
  showMonthDropdown: false,
  showYearDropdown: false,
  yearDropdownItemNumber: 15,
  scrollableYearDropdown: true,
  showIcon: false,
  maxDate: null,
  isDisplayedWithCalendarPopper: true,
  isModal: false,
  calendarClassName: ''
}
const Memoized = React.memo(DatePickerNew)
export default Memoized
