import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import PageSteps from '~/components/ui/PageSteps'

import { ONBOARDING_PROGRESS_STEPS } from './constants'
import { getOnboardingProgress } from './utils'

const OnboardingProgressSteps = props => {
  const { showRoundOnboardingPageSteps } = props
  const { pathname } = useLocation()

  const { currentStep, progress } = getOnboardingProgress(pathname)

  return (
    <PageSteps
      currentStep={currentStep}
      progress={progress}
      steps={ONBOARDING_PROGRESS_STEPS}
      className="mb-4 mt-5 max-w-122.5 mx-auto md:mt-7 mt-8"
      showRoundOnboardingPageSteps={showRoundOnboardingPageSteps}
    />
  )
}
OnboardingProgressSteps.propTypes = {
  showRoundOnboardingPageSteps: PropTypes.bool
}

OnboardingProgressSteps.defaultProps = {
  showRoundOnboardingPageSteps: false,
}

const Memoized = React.memo(OnboardingProgressSteps)
export default Memoized
