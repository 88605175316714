import React from 'react'
import PropTypes from 'prop-types'
import { EXPERIAN_STATUSES } from '~/constants'
import ToolTip from '../ToolTip'

const CreditScoreProjectionTitle = props => {
  const {
    user,
  } = props

  const {
    credit,
    experianStatus,
  } = user

  const isScoreAvailable = Boolean(credit)
  const isSubscribed = experianStatus === EXPERIAN_STATUSES.SUBSCRIBED

  return isSubscribed ? (
    <>
      <p className="text-lg font-semibold mb-1">
        {isScoreAvailable ? 'Score projection' : 'Sample score projection'}
        <ToolTip user={user} />
      </p>
      {isScoreAvailable ? null : (
        <p
          className="text-sm mb-2.5"
          data-testid="stellar-credit-score-projection-delayed-projection-title"
        >
          It may take a few minutes for your score projection to appear.
        </p>
      )}
    </>
  ) : (
    <p className="text-lg font-semibold">
      Sample score projection
    </p>
  )
}
CreditScoreProjectionTitle.propTypes = {
  user: PropTypes.shape({
    credit: PropTypes.shape({}),
    registrationNextStep: PropTypes.string,
    experianStatus: PropTypes.string.isRequired,
  }).isRequired,
}

const Memoized = React.memo(CreditScoreProjectionTitle)
export default Memoized
