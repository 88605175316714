import {
  SUBSCRIPTION_PLAN_BILL_TAG_TYPE,
  USER_TAG_TYPE,
} from '~/store/api/constants'

const getSubscriptionEndpoints = builder => ({
  getSubscriptionPlans: builder.query({
    query: () => '/subscription_plans',
  }),
  postSubscriptionPlan: builder.mutation({
    query: body => ({
      url: '/subscription_plans',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  getSubscriptionPlanBills: builder.query({
    query: (params = {}) => ({
      url: '/subscription_plan_bills',
      params,
    }),
    providesTags: [SUBSCRIPTION_PLAN_BILL_TAG_TYPE],
  }),
})

export default getSubscriptionEndpoints
