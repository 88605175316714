import { ROUTES } from '~/pages/constants'

export const ANIMATION_TIME = 600

export const MOBILE_NAVIGATION_ROUTES = [
  {
    route: ROUTES.SETTINGS.PERSONAL_INFORMATION,
    testId: 'stellar-app-navigation-personal-information-link',
    iconName: 'far fa-address-card',
    label: 'Personal information',
  },
  {
    route: ROUTES.SETTINGS.MEMBERSHIP,
    testId: 'stellar-app-navigation-membership-link',
    iconName: 'far fa-user',
    label: 'Membership',
  },
  {
    route: ROUTES.SETTINGS.PASSWORD,
    testId: 'stellar-app-navigation-password-link',
    iconName: 'far fa-key',
    label: 'Password',
  },
  {
    route: ROUTES.SETTINGS.LINKED_ACCOUNTS,
    testId: 'stellar-app-navigation-linked-accounts-link',
    iconName: 'far fa-piggy-bank',
    label: 'Linked Bank',
  },
  {
    route: ROUTES.SETTINGS.DOCUMENTS,
    testId: 'stellar-app-navigation-documents-link',
    iconName: 'far fa-file-arrow-up',
    label: 'Documents',
  },
  {
    route: ROUTES.SETTINGS.LEGAL,
    testId: 'stellar-app-navigation-legal-link',
    iconName: 'far fa-file-invoice',
    label: 'Legal',
  },
  {
    href: 'https://support.stellarfi.com/hc/en-us',
    target: '_blank',
    testId: 'stellar-app-header-menu-help-center-link',
    iconName: 'far fa-circle-question',
    label: 'Help Center'
  },
  {
    testId: 'stellar-app-navigation-help-btn',
    onClick: () => zE('messenger', 'open'),
    iconName: 'far fa-message-lines',
    label: 'Chat With Us'
  },
  {
    route: ROUTES.LOGOUT,
    testId: 'stellar-app-navigation-logout-link',
    iconName: 'far fa-arrow-right-from-bracket',
    label: 'Sign out',
  },
]

export const MOBILE_NAVIGATION_ROUTES_NEW_FUNNEL = [
  {
    route: ROUTES.SETTINGS.PERSONAL_INFORMATION,
    testId: 'stellar-app-navigation-personal-information-link',
    iconName: 'far fa-address-card',
    label: 'Personal information',
  },
  {
    href: 'https://support.stellarfi.com/hc/en-us',
    target: '_blank',
    testId: 'stellar-app-header-menu-help-center-link',
    iconName: 'far fa-circle-question',
    label: 'Help Center'
  },
  {
    testId: 'stellar-app-navigation-help-btn',
    onClick: () => zE('messenger', 'open'),
    iconName: 'far fa-message-lines',
    label: 'Chat With Us'
  },
  {
    route: ROUTES.LOGOUT,
    testId: 'stellar-app-navigation-logout-link',
    iconName: 'far fa-arrow-right-from-bracket',
    label: 'Sign out',
  },
]
