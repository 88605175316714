import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'

import MobileModal from '~/components/ui/Modal/Mobile'
import DesktopModal from '~/components/ui/Modal/Desktop'
import Button from '~/components/ui/Button'

import { MODAL_CONTENT } from './constants'

const LearnMoreModal = props => {
  const { currentStep } = props

  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handleLearnMoreModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.LEARN_MORE_MODAL }))
  }, [modal, dispatch])

  const { header, getDescription } = MODAL_CONTENT[currentStep]

  return isMobile
    ? (
      <MobileModal
        show
        size="md"
        className="p-5"
        onClose={handleLearnMoreModalClose}
        withButton
        btnProps={{
          className: '!bg-black !text-white'
        }}
        data-testid="stellar-dashboard-modals-learn-more-mobile-modal"
      >
        <h2
          data-testid="stellar-dashboard-modals-learn-more-mobile-modal-header"
          className="font-semibold"
        >
          {header}
        </h2>
        {getDescription()}
      </MobileModal>
    ) : (
      <DesktopModal
        show
        size="md"
        className="p-5"
        onClose={handleLearnMoreModalClose}
        withCloseButton
        data-testid="stellar-dashboard-modals-learn-more-desktop-modal"
      >
        <h2 className="font-semibold">
          {header}
        </h2>
        {getDescription()}
        <Button
          variant="contained"
          className="w-full mb-2"
          onClick={handleLearnMoreModalClose}
          data-testid="stellar-dashboard-modals-learn-more-desktop-button"
        >
          Ok, got it
        </Button>
      </DesktopModal>
    )
}

LearnMoreModal.propTypes = {
  currentStep: PropTypes.number
}

LearnMoreModal.defaultProps = {
  currentStep: 1,
}

const Memoized = React.memo(LearnMoreModal)
export default NiceModal.create(Memoized)
