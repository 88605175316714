import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import { MODAL_IDS } from '~/constants'
import {
  useFetchUserQuery,
  useUnlinkBillMutation,
} from '~/store/api'
import { removeModalFromQueue } from '~/store/modules/ui'
import Modal from '~/components/ui/Modal'
import ErrorButton from '~/components/ui/Buttons/ErrorButton'
import StandardButton from '~/components/ui/Buttons/StandardButton'

const UnlinkBillModal = props => {
  const { bill } = props

  const modal = useModal()
  const dispatch = useDispatch()

  const [unlinkBill, unlinkBillApiResult] = useUnlinkBillMutation()

  const handleUnlinkBill = useCallback(async () => {
    const apiResult = await unlinkBill(bill.uuid)
    if (!apiResult.error) {
      modal.resolve()
      modal.remove()
      dispatch(removeModalFromQueue({ id: MODAL_IDS.UNLINK_BILL }))
    }
  }, [bill, unlinkBill, modal, dispatch])
  // TODO: add smooth transition on close
  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.UNLINK_BILL }))
  }, [modal, dispatch])

  const billerName = bill?.biller?.name

  const {
    data = {},
  } = useFetchUserQuery()
  const { user = {} } = data

  const lastFour = user?.card?.lastFour
  return (
    <Modal
      show
      size="md"
      data-testid="stellar-unlink-bill-modal"
      onClose={handleCloseModal}
    >
      <div className="p-5">
        <div className="flex justify-between items-start mb-4">
          <h2 className="text-lg font-semibold">
            Are you sure you want to unlink this bill?
          </h2>
        </div>
        {unlinkBillApiResult.isLoading ? (
          <div
            className="flex items-center justify-center"
            data-testid="stellar-unlink-bill-modal-loader"
          >
            <div className="spinner mt-5 mb-6" role="status">
              <span>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <p className="text-base mb-4">
              Unlinking a bill is permanent and you won’t reach your credit goals
              {' '}
              as quickly.
              <br />
              <br />
              Please make sure to remove the StellarFi card ending in
              {' '}
              {lastFour}
              {' '}
              as the payment method from
              {' '}
              {billerName}
              &apos;s website.
            </p>
            {unlinkBillApiResult.error ? (
              <p className="text-red mb-4">
                Something went wrong while unlinking your bill. Please try again later.
              </p>
            ) : null}
            <div className="flex flex-col justify-end items-center">
              <ErrorButton
                variant="contained"
                className="mb-2 w-full"
                onClick={handleUnlinkBill}
                data-testid="stellar-unlink-bill-modal-submit-button"
              >
                Unlink bill
              </ErrorButton>
              <StandardButton
                variant="outlined"
                className="w-full"
                onClick={handleCloseModal}
                data-testid="stellar-unlink-bill-modal-cancel-button"
              >
                Cancel
              </StandardButton>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}
UnlinkBillModal.propTypes = {
  bill: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    biller: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
}

export default NiceModal.create(UnlinkBillModal)
