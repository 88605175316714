import React from 'react'
import PropTypes from 'prop-types'

import Button from '~/components/ui/Button'
import UserAvatar from '~/components/ui/UserAvatar'

const UserMenuFullScreen = props => {
  const {
    user,
    onOpen,
  } = props

  return (
    <Button
      size=""
      variant=""
      color=""
      onClick={onOpen}
      data-testid="stellar-app-header-fullscreen-menu-avatar-btn"
    >
      <UserAvatar
        user={user}
        size="sm"
        data-testid="stellar-app-header-fullscreen-menu-avatar"
      />
    </Button>
  )
}
UserMenuFullScreen.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  onOpen: PropTypes.func.isRequired,
}

const Memoized = React.memo(UserMenuFullScreen)
export default Memoized
