export const truncateString = (string, length = 13) => (
  string.length <= length ? string : `${string.substring(0, length)}...`
)

// Reminder: never build this URL with user-provided input
export const buildUrlCurrentWithParams = (path, searchParams, paramsToRemove) => {
  const url = new URL(`${window.location.origin}${path}`)

  searchParams.forEach((value, key) => {
    if (!paramsToRemove.includes(key)) {
      url.searchParams.append(key, value)
    }
  })

  return `${url.pathname}${url.search}`
}

export const createGradientCanvas = offsetsColors => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const gradient = ctx.createLinearGradient(0, 0, 0, 300)
  offsetsColors.forEach(({ offset, color }) => gradient.addColorStop(offset, color))
  return gradient
}

export const uppercaseFirstChar = (str = '') => str.replace(/^[a-z]/, m => m.toUpperCase())

export const parseInsightEmoji = (insightCategory = '', icons = {}) => String.fromCodePoint(parseInt(icons[insightCategory], 16))

export const getRandom = (floor, ceiling) => Math.floor(Math.random() * (ceiling - floor + 1)) + floor

export const shuffle = (array = []) => {
  // if it's 1 or 0 items, just return
  if (array.length <= 1) return array

  const randomized = [...array]

  return randomized.sort(() => Math.random() - 0.5)
}

export const parseHyphensToCamelCase = str => str.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase())
