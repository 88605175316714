import CONFIG from '~/config'

export const setUserToken = token => localStorage.setItem(CONFIG.COOKIE_TOKEN, token)
export const getUserToken = () => localStorage.getItem(CONFIG.COOKIE_TOKEN)
export const removeUserToken = () => localStorage.removeItem(CONFIG.COOKIE_TOKEN)

export const setImpresonationToken = token => sessionStorage.setItem(CONFIG.IMPERSONATION_TOKEN, token)
export const getImpersonationToken = () => sessionStorage.getItem(CONFIG.IMPERSONATION_TOKEN)
export const removeImpersonationToken = () => sessionStorage.removeItem(CONFIG.IMPERSONATION_TOKEN)

export const isUserAuthenticated = () => !!getImpersonationToken() || !!getUserToken()
export const isImpersonationAuthenticated = () => !!getImpersonationToken()
