import { useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  SEGMENT_EVENT_TRACK_FUNCTIONS_MAP,
  getSegmentUserContext,
} from '~/services/segment'
import { selectDocumentReferrer } from '~/store/modules/analytics/selectors'
import { selectScreenType } from '~/store/modules/dimensions/selectors'
import { selectIsUserAuthorized, selectIsImpersonationAuthorized } from '~/store/modules/auth/selectors'
import {
  useFetchUserQuery,
} from '~/store/api'

export const useSegmentEvents = eventNames => {
  const eventNamesRef = useRef(eventNames)
  const referrer = useSelector(selectDocumentReferrer)
  const screenType = useSelector(selectScreenType)
  const isImpersonationMode = useSelector(selectIsImpersonationAuthorized)

  const isAuth = useSelector(selectIsUserAuthorized)
  const {
    data = {},
  } = useFetchUserQuery(undefined, { skip: !isAuth })
  const { user = {} } = data

  const trackFunctions = useMemo(() => eventNamesRef.current.map(eventName => (
    (eventData = {}, properties = {}) => SEGMENT_EVENT_TRACK_FUNCTIONS_MAP[eventName](
      {
        screenType,
        ...eventData
      },
      {
        ...getSegmentUserContext(user),
        page: { referrer },
        ...properties,
      },
      {
        isImpersonationMode
      }
    )
  )), [screenType, user, referrer, isImpersonationMode])

  return eventNames.length === 1 ? trackFunctions[0] : trackFunctions
}
