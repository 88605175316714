import { prepareSnackbarMessage } from '~/utils'

export const SNACKBAR_MESSAGE_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  PAUSED: 'paused'
}

export const getSnackbarMessage = (isMobile, message) => {
  switch (message) {
    case SNACKBAR_MESSAGE_TYPES.PAUSED:
      return prepareSnackbarMessage({
        message: 'Changes to your email address are awaiting verification.',
        status: 'paused',
      }, isMobile)
    case SNACKBAR_MESSAGE_TYPES.SUCCESS:
      return prepareSnackbarMessage({
        message: 'Changes to your account have been saved.',
        status: 'success',
      }, isMobile)
    case SNACKBAR_MESSAGE_TYPES.ERROR:
      return prepareSnackbarMessage({
        message: 'Unable to save changes. Please try again or contact support.',
        status: 'error',
      }, isMobile)
    default:
      return {
        message: '',
        status: '',
      }
  }
}
