import React from 'react'
import PropTypes from 'prop-types'

import { PROGRESS_BARS as GENERAL_PROGRESS_BARS } from '~/constants'

import LinearOnboardingProgressBar from './Linear'
import LinearWithDotsOnboardingProgressBar from './LinearWithDots'

const PROGRESS_BARS = {
  [GENERAL_PROGRESS_BARS.LINEAR]: LinearOnboardingProgressBar,
  [GENERAL_PROGRESS_BARS.LINEAR_WITH_DOTS]: LinearWithDotsOnboardingProgressBar,
}

const OnboardingProgressBar = props => {
  const {
    currentStep,
    progress,
    progressBar
  } = props

  const ProgressBarComponent = PROGRESS_BARS[progressBar]

  return (
    <ProgressBarComponent
      currentStep={currentStep}
      progress={progress}
    />
  )
}
OnboardingProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  progressBar: PropTypes.string
}

OnboardingProgressBar.defaultProps = {
  progressBar: GENERAL_PROGRESS_BARS.LINEAR
}

const Memoized = React.memo(OnboardingProgressBar)
export default Memoized
