import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const FadeInAnimation = props => {
  const {
    children,
    duration,
  } = props

  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration }}

    >
      {children}
    </motion.div>
  )
}
FadeInAnimation.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
}
FadeInAnimation.defaultProps = {
  duration: 1,
}

export default React.memo(FadeInAnimation)
