import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { ROUTES } from '~/pages/constants'
import { CLOUDFLARE_IMAGE_IDS } from '~/constants'
import { getCloudflareImageURL } from '~/utils'
import Navigation from '~/features/Headers/App/Navigation'
import Notifications from '~/features/Notifications'
import UserMenu from '~/features/Headers/App/UserMenu'
import Link from '~/components/ui/Link'

const DesktopAppHeader = props => {
  const {
    user,
    withPadding,
    showLimitedHeader,
    hideLogo,
    limitedAccess,
  } = props

  return (
    <div
      className="lg:w-screen"
      data-testid="stellar-headers-app-desktop"
    >
      <div
        data-testid="stellar-headers-app-desktop-container"
        className={classNames('relative w-full md:max-w-[1060px] mx-auto px-4 md:px-0', { 'sm:pb-3 md:pb-23': withPadding })}
      >
        {showLimitedHeader ? (
          <div
            data-testid="stellar-headers-app-desktop-special-header"
            className="py-2.5 flex items-center"
          >
            <UserMenu
              user={user}
              showLimitedHeader={showLimitedHeader}
              limitedAccess={limitedAccess}
            />
            {!hideLogo ? (
              <img
                src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.LOGO)}
                alt="stellar"
                className="h-10 w-10 absolute mx-auto top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 "
              />
            ) : null}
          </div>
        ) : (
          <div className="pt-4 pb-12 flex items-center flex-1">
            <Link
              to={ROUTES.DASHBOARD}
              data-testid="stellar-app-header-link"
            >
              <img
                src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.LOGO_FULL)}
                alt="stellar"
                className="h-8 w-30"
              />
            </Link>
            <div className="ml-5 items-center text-white hidden md:flex">
              <Navigation />
            </div>
            <div className="ml-auto flex flex-row items-center">
              <Notifications />
              <UserMenu
                user={user}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
DesktopAppHeader.propTypes = {
  user: PropTypes.shape({}).isRequired,
  withPadding: PropTypes.bool.isRequired,
  showLimitedHeader: PropTypes.bool.isRequired,
  hideLogo: PropTypes.bool.isRequired,
  limitedAccess: PropTypes.bool.isRequired,
}

export default DesktopAppHeader
