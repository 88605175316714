import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatCentsToDollars } from '~/utils'
import { MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'

import Modal from '~/components/ui/Modal'
import Button from '~/components/ui/Button'

import { setHideFirstBillLinkedModalItem } from './utils'

const FirstBillLinkedModal = props => {
  const { pendingCreditCents } = props

  const modal = useModal()
  const dispatch = useDispatch()

  const handleCloseModal = useCallback(() => {
    setHideFirstBillLinkedModalItem()
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.FIRST_BILL_LINKED }))
  }, [modal, dispatch])

  const formatedCredits = formatCentsToDollars(pendingCreditCents, { maximumFractionDigits: 0 })

  return (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      className="p-6"
      data-testid="stellar-dashboard-modals-first-bill-linked"
    >
      <div className="flex flex-col items-center">
        <FontAwesomeIcon
          icon={['far', 'check-circle']}
          className="block mx-auto mb-6 text-lightspeed w-12 h-12"
        />
        <p className="text-xl font-semibold mb-4">
          First bill linked!
        </p>
      </div>
      <p className="text-base mb-4">
        You successfully linked your first bill. Once your bill has processed, you will receive your
        reward of
        {' '}
        {formatedCredits}
        .
      </p>
      <Button
        variant="outlined"
        className="flex-grow justify-center w-full"
        onClick={handleCloseModal}
        data-testid="stellar-dashboard-modals-first-bill-linked-button"
      >
        Awesome, thanks
      </Button>
    </Modal>
  )
}

FirstBillLinkedModal.propTypes = {
  pendingCreditCents: PropTypes.number,
}
FirstBillLinkedModal.defaultProps = {
  pendingCreditCents: 0,
}

export default NiceModal.create(FirstBillLinkedModal)
