import {
  PAYMENTS_TAG_TYPE,
} from '~/store/api/constants'

const getPaymentEndpoints = builder => ({
  getPayments: builder.query({
    query: (params = {}) => ({
      url: '/payments',
      params,
    }),
    providesTags: [PAYMENTS_TAG_TYPE],
    transformResponse: response => {
      if (response.payments && response.pagination) {
        return {
          list: response.payments,
          ...response.pagination
        }
      }
      return response
    },
  }),
})

export default getPaymentEndpoints
