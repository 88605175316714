/* eslint-disable no-useless-escape */
/* eslint-disable consistent-return */
import { TEST_SSNS } from '~/constants'

export const NUMBERS_ONLY_REGEX = /[^\d]/g
export const LETTERS_ONLY_REGEX = /^[\p{L}\-.'\s]+$/u
const VALID_SSN_REGEX
  = /^(?!(000|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/
const URL_PATTERN_REGEX = new RegExp(
  '^(https?:\\/\\/)?' // validate protocol
  + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // validate domain name
  + '((\\d{1,3}\\.){3}\\d{1,3}))' // validate OR ip (v4) address
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
  + '(\\?[;&a-z\\d%_.,~+=-]*)?' // validate query string
  + '(\\#[-a-z\\d_]*)?$',
  'i' // validate fragment locator
)
const VALID_EMAIL_REGEX
  // eslint-disable-next-line max-len
  = /^(([^<>()[\]\\.,;:\s@!\"#$%&'*/=?^`{|}~]+(\.[^<>()[\]\\.,;:\s@!\"#$%&'*+/=?^`{|}~]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}))$/
const BILLER_SEARCH_REGEX = /[^\w\&\+\s.-]/gi // leave only English letters, numbers, ' ', '.', '-', '&', '+'

export const DEFAULT_URL_PREFIX = 'https://'

export const formatPhoneNumber = (value, previousValue) => {
  // return nothing if no value
  if (typeof value !== 'string') return ''

  // only allows 0-9 inputs
  const currentValue = value.replace('+1', '').replace(NUMBERS_ONLY_REGEX, '')
  const cvLength = currentValue.length

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue

    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
  }
}

export const maskPhoneNumber = value => {
  if (typeof value !== 'string') return ''
  if (value <= 2) return value

  if (value < 12) {
    return `${Array.from({ length: value.length - 2 }, () => 'X')}${value.slice(-2)}`
  }

  return `(XXX) XXX-XX${value.slice(-2)}`
}

// returns 10-digit phone number
export const parseInputPhoneNumberRaw = (phone = '') => {
  if (typeof phone !== 'string') return ''
  const phoneNumber = phone.replace('+1', '').replace(NUMBERS_ONLY_REGEX, '')
  return phoneNumber.length
    ? Number(phoneNumber)
      .toString()
      .slice(0, 10)
    : ''
}

// returns phone number string with telephone prefix (USA only so far)
export const parseInputPhoneNumber = (phone = '') => {
  if (typeof phone !== 'string' && typeof phone !== 'number') return ''
  const phoneNumberParsed = phone
    .toString()
    .replace(NUMBERS_ONLY_REGEX, '')
    .slice(0, 10)
  const prefix = '+1'
  return `${prefix}${phoneNumberParsed}`
}

export const validatePhoneNumber = phoneNumber => {
  if (typeof phoneNumber !== 'string') return false
  const phone = phoneNumber.trim().replace('+1', '')
  return Boolean(phone?.length && phone?.length === 10)
}

export const formatSocialSecurityNumber = value => {
  if (typeof value !== 'string' && typeof value !== 'number') return ''

  const currentValue = value.toString().replace(NUMBERS_ONLY_REGEX, '')
  const cvLength = currentValue.length

  if (cvLength < 4) return currentValue

  if (cvLength < 6) return `${currentValue.slice(0, 3)} - ${currentValue.slice(3)}`

  return `${currentValue.slice(0, 3)} - ${currentValue.slice(3, 5)} - ${currentValue.slice(5, 9)}`
}

export const validateSocialSecurityNumber = value => Boolean(value && (TEST_SSNS.includes(value) || VALID_SSN_REGEX.test(value)))

export const parseSocialSecurityNumber = value => {
  if (typeof value !== 'string' && typeof value !== 'number') return ''
  return value.toString().replace(NUMBERS_ONLY_REGEX, '').slice(0, 9)
}

export const validateEmail = email => Boolean(
  email.match(VALID_EMAIL_REGEX)
)

export const sanitizeBillerSearch = string => (string ? string.replace(BILLER_SEARCH_REGEX, '') : '')

export const validateUrl = urlString => !!URL_PATTERN_REGEX.test(urlString)

export const formatUrl = urlString => {
  if (!urlString || urlString === DEFAULT_URL_PREFIX) {
    return ''
  }

  if (urlString.startsWith('https') || urlString.startsWith('http')) {
    return urlString
  }

  return `https://${urlString}`
}

export const formatNumberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const formatStringAsNumber = string => {
  if (typeof string !== 'string') {
    return string
  }

  return string.replace(NUMBERS_ONLY_REGEX, '')
}
