import React from 'react'
import PropTypes from 'prop-types'
import { Button as SharedButton } from 'stellar-web-shared'

import { useSetPrevRoute } from '~/hooks/useSetPrevRoute'
import { getActionColor } from '../../utils'

const StandardButton = props => {
  const {
    action,
    storePrevRoute,
    onClick,
    ...otherProps
  } = props

  const handleClick = useSetPrevRoute(onClick, storePrevRoute)

  const color = getActionColor(action)
  return (
    <SharedButton
      onClick={handleClick}
      color={color}
      {...otherProps}
    />
  )
}
StandardButton.propTypes = {
  action: PropTypes.string,
  storePrevRoute: PropTypes.bool,
  onClick: PropTypes.func,
}
StandardButton.defaultProps = {
  action: 'standard',
  storePrevRoute: false,
  onClick: () => null,
}

export default StandardButton
