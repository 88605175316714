import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useSelector, useDispatch } from 'react-redux'

import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'

import MobileModal from '~/components/ui/Modal/Mobile'
import DesktopModal from '~/components/ui/Modal/Desktop'

import LinkBankAccountEducationModalContent from './Content'

const LinkBankAccountEducationModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handleLinkBankAccountEducationModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.LINK_BANK_ACCOUNT_EDUCATION }))
  }, [modal, dispatch])

  return isMobile ? (
    <MobileModal
      show
      withButton
      size="md"
      className="p-5"
      onClose={handleLinkBankAccountEducationModalClose}
    >
      <LinkBankAccountEducationModalContent />
    </MobileModal>
  ) : (
    <DesktopModal
      show
      size="md"
      className="p-5 pr-6"
      onClose={handleLinkBankAccountEducationModalClose}
      withCloseButton
    >
      <LinkBankAccountEducationModalContent />
    </DesktopModal>
  )
}

const Memoized = React.memo(LinkBankAccountEducationModal)
export default NiceModal.create(Memoized)
