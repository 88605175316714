import { ROUTES } from '~/pages/constants'

export const FLOW_NAMES = {
  [ROUTES.NEW_BILL]: 'Add bill',
  [ROUTES.BILL_CATEGORIES]: 'Add bill',
  [ROUTES.LINK_PAYMENT]: 'Add bill',
  [ROUTES.CONFIRM_LINK_PAYMENT]: 'Add bill',
  [ROUTES.SETTINGS.PERSONAL_INFORMATION]: 'Personal information',
  [ROUTES.SETTINGS.MEMBERSHIP]: 'Membership',
  [ROUTES.SETTINGS.PASSWORD]: 'Password',
  [ROUTES.SETTINGS.LEGAL]: 'Legal',
  [ROUTES.SETTINGS.LINKED_ACCOUNTS]: 'Linked account',
  [ROUTES.SETTINGS.DOCUMENTS]: 'Documents',
  [ROUTES.MANAGE_ACCOUNT]: 'Manage Account',
  [ROUTES.CLOSE_ACCOUNT]: 'Close Account',
  [ROUTES.CREDIT_TOOLS_REPORT]: 'Credit report',
  [ROUTES.SPEND_LIMIT]: 'StellarFi Limit',
  [ROUTES.GOALS_NEW]: 'New Goal',
  [ROUTES.ACTIVATE_CREDIT_SCORE]: 'Activate credit score',
  [ROUTES.CONFIRM_IDENTITY.IDENTITY]: 'Confirm identity',
  [ROUTES.CONFIRM_IDENTITY.ADDRESS]: 'Confirm identity',
  [ROUTES.CONFIRM_IDENTITY.VERIFY_PHONE_NUMBER]: 'Confirm identity',
  [ROUTES.CONFIRM_IDENTITY.SUMMARY]: 'Confirm identity',
  [ROUTES.PAUSE_ACCOUNT]: 'Pause account',
  [ROUTES.BANK_BALANCE]: 'Bank information',
}
