// TODO: move to shared repo
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Checkbox = props => {
  const {
    id,
    label,
    className,
    checked,
    onChange,
    ...inputProps
  } = props

  const handleChange = useCallback(event => {
    onChange(event.target.value)
  }, [onChange])

  return (
    <label
      htmlFor={id}
      className={`block cursor-pointer ${className}`}
    >
      <input
        type="checkbox"
        id={id}
        className="checkbox hidden"
        checked={checked}
        onChange={handleChange}
        {...inputProps}
      />
      <div className="text">
        {label}
      </div>
    </label>
  )
}
Checkbox.propTypes = {
  label: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  className: '',
  onChange: () => null
}

export default Checkbox
