import {
  USER_TAG_TYPE,
  USER_CLOSING_INFO_TAG_TYPE
} from '~/store/api/constants'

const getAccountEndpoints = builder => ({
  pauseAccount: builder.mutation({
    query: ({ userUuid, body }) => ({
      url: `users/${userUuid}/pause`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE])
  }),
  unpauseAccount: builder.mutation({
    query: userUuid => ({
      url: `users/${userUuid}/unpause`,
      method: 'PUT',
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  reopenAccount: builder.mutation({
    query: ({ userUuid, body }) => ({
      url: `users/${userUuid}/reopen`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, USER_CLOSING_INFO_TAG_TYPE]),
  }),
  closeAccount: builder.mutation({
    query: ({ userUuid, body }) => ({
      url: `users/${userUuid}/close`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, USER_CLOSING_INFO_TAG_TYPE]),
  }),
  getClosingInfo: builder.query({
    query: userUuid => ({
      url: `users/${userUuid}/closing_info`
    }),
    providesTags: [USER_CLOSING_INFO_TAG_TYPE],
  }),
})

export default getAccountEndpoints
