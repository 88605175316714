import React from 'react'
import PropTypes from 'prop-types'

import { CLOUDFLARE_IMAGE_IDS } from '~/constants'
import { getCloudflareImageURL } from '~/utils'

import Button from '~/components/ui/Button'

import { MODAL_CONTENT } from './constants'

const PrimeGuaranteeReferFriendModalContent = props => {
  const { onPrimeGuaranteeReferFriend } = props

  return (
    <div className="flex items-center flex-col text-center">
      <img
        src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.PRIME_GUARANTEE_CREDIT_SCORE_INCREASED)}
        alt="prime guarantee"
        className="w-25 h-25 mb-4"
      />
      <h2 className="text-2xl font-bold text-deepspace">
        {MODAL_CONTENT.HEADER}
      </h2>

      <p className="my-5 text-base font-normal text-deepspace-400">
        {MODAL_CONTENT.DESCRIPTION}
      </p>
      <Button
        variant="contained"
        className="w-full mb-2 bg-lightspeed-600 text-white hover:bg-lightspeed-600"
        onClick={onPrimeGuaranteeReferFriend}
      >
        Refer a friend
      </Button>
    </div>
  )
}

PrimeGuaranteeReferFriendModalContent.propTypes = {
  onPrimeGuaranteeReferFriend: PropTypes.func.isRequired,
}

const Memoized = React.memo(PrimeGuaranteeReferFriendModalContent)
export default Memoized
