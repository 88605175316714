export const getAnimationDirectionClassName = (direction, isClosed) => {
  switch (direction) {
    case 'bottomToTop':
      return isClosed ? 'animate-modal-slide-down translate-y-full' : 'animate-modal-slide-up translate-y-0'
    case 'rightToLeft':
      return isClosed ? 'modal-slide-to-right' : 'modal-slide-to-center centered-transform'
    default:
      return ''
  }
}
