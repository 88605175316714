import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { captureMessage as captureSentryMessage } from '@sentry/react'
import { useNavigate, matchRoutes } from 'react-router-dom'

import CONFIG from '~/config'
import { CLOUDFLARE_IMAGE_IDS } from '~/constants'
import {
  ROUTES,
} from '~/pages/constants'
import { getCloudflareImageURL } from '~/utils'
import Card from '~/components/ui/Card'
import Button from '~/components/ui/Button'

const ErrorFallback = props => {
  const {
    message,
    error,
    onReset,
    isRouteNotFound,
    location,
  } = props
  const navigate = useNavigate()

  useEffect(() => {
    if (isRouteNotFound) {
      captureSentryMessage(`User tried to enter a non-existing route: ${location.pathname || ''}${location.hash || ''}`)
    }
  }, [isRouteNotFound, location.pathname, location.hash])

  useEffect(() => {
    if (location.pathname === '/app/settings') {
      navigate(ROUTES.SETTINGS.PERSONAL_INFORMATION, { replace: true })
    }
  }, [location.pathname, navigate])

  const matchArr = matchRoutes([{ path: ROUTES.LOGIN_BY_TOKEN }], location)
  const isLoginByTokenRoute = Boolean(matchArr)

  return (
    <div className="h-screen flex">
      <Card
        id="app-error"
        className="relative w-3/5 md:w-2/5 m-auto"
      >
        {isRouteNotFound && isLoginByTokenRoute ? (
          <p className="text-base font-normal text-center">
            Your browser has an outdated version of the website.
            Please wait until it automatically updates in about 30 seconds
          </p>
        ) : (
          <>
            <h1 className="text-deepspace text-5xl text-center font-bold mb-3">
              Oops!
            </h1>
            <p className="text-base font-normal text-center">
              {message}
            </p>
          </>
        )}
        <img
          src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.DOG_IN_HELMET)}
          alt="dog in helmet"
          className="absolute -left-16 -top-24"
        />
        <img
          src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.ROCKS)}
          alt="rocks"
          className="absolute -right-16 -top-12"
        />

        {error && CONFIG.ENVIRONMENT === 'development' ? (
          <>
            <pre className="text-center mb-2 whitespace-pre-wrap break-words">
              {error?.stack}
            </pre>
            <Button
              variant="contained"
              color="red"
              className="block m-auto"
              onClick={onReset}
            >
              Try again
            </Button>
          </>
        ) : null}
      </Card>
    </div>
  )
}
ErrorFallback.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.shape({
    name: PropTypes.string,
    message: PropTypes.string,
    stack: PropTypes.string,
  }),
  onReset: PropTypes.func,
  isRouteNotFound: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hash: PropTypes.string,
  }),
}
ErrorFallback.defaultProps = {
  error: null,
  onReset: () => {},
  isRouteNotFound: false,
  location: {},
}

export default React.memo(ErrorFallback)
