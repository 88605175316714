import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { ROUTES } from '~/pages/constants'

export const useGSI = () => {
  const location = useLocation()

  // load GSI client
  useEffect(() => {
    if (location.pathname === ROUTES.ROOT
      || location.pathname === ROUTES.LOGIN
      || location.pathname === ROUTES.SIGNUP
      || location.pathname === ROUTES.SETTINGS.PASSWORD
    ) {
      const GSIScript = document.createElement('script')
      const scriptId = 'google-sign-in-script'
      GSIScript.id = scriptId
      GSIScript.src = 'https://accounts.google.com/gsi/client'
      GSIScript.defer = true

      document.body.appendChild(GSIScript)
    }
    return () => {
      const scriptToDelete = document.getElementById('google-sign-in-script')
      if (scriptToDelete) {
        document.body.removeChild(scriptToDelete)
      }
    }
  }, [location.pathname])

  return null
}
