import {
  createSlice,
} from '@reduxjs/toolkit'

import { getDateTime } from '~/utils/datetime'

const initialState = {
  referrer: document.referrer,
  redirectUrlAfterLogin: '',
  trackedExperiments: {},
}

// slice to store/update data that may be important for various analytics tools that we use
const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setDocumentReferrer(state, action) {
      return {
        ...state,
        referrer: action.payload,
      }
    },
    setRedirectUrl(state, action) {
      return {
        ...state,
        redirectUrlAfterLogin: action.payload,
      }
    },
    clearRedirectUrl(state) {
      return {
        ...state,
        redirectUrlAfterLogin: '',
      }
    },
    setExperimentTracked(state, action) {
      return {
        ...state,
        trackedExperiments: {
          ...state.trackedExperiments,
          [action.payload.experimentName]: {
            ...action.payload.eventData,
            timestamp: getDateTime('now').toISOTime(),
          },

        },
      }
    },
  },
})

export const {
  setDocumentReferrer,
  setRedirectUrl,
  clearRedirectUrl,
  setExperimentTracked,
} = analyticsSlice.actions

export default analyticsSlice.reducer
