import {
  createSlice,
} from '@reduxjs/toolkit'

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight,
  // add orientation, scroll etc. if needed as well
}

const dimensionsSlice = createSlice({
  name: 'dimensions',
  initialState,
  reducers: {
    updateDimensions(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const {
  updateDimensions,
} = dimensionsSlice.actions

export default dimensionsSlice.reducer
