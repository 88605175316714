import { useEffect } from 'react'

import { ROUTES } from '~/pages/constants'

// hook to append Zendesk widget at runtime
export const useZendeskWidget = location => {
  useEffect(() => {
    const zendeskScript = document.createElement('script')
    const scriptId = 'ze-snippet'
    zendeskScript.id = scriptId
    zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=b63998f3-8cc2-4969-b177-158afe1b1d28'
    zendeskScript.defer = true

    if (location.pathname !== ROUTES.ROOT) {
      document.body.appendChild(zendeskScript)
    }

    return () => {
      const scriptToDelete = document.getElementById(scriptId)
      if (scriptToDelete) {
        document.body.removeChild(scriptToDelete)
      }
    }
  }, [location.pathname])

  return null
}
