import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Chart } from 'react-chartjs-2'

import { COLORS, EXPERIAN_STATUSES, USER_STATUSES } from '~/constants'
import {
  selectIsMobile,
  selectDimensions,
} from '~/store/modules/dimensions/selectors'
import { chartAreaBorderPlugin, chartAreaBorderBottomPlugin } from '~/chart/plugins'
import { checkIsZeroLimitUser, createGradientCanvas } from '~/utils'

import classNames from 'classnames'
import { getChartOptions, getScoreProjectionChartData } from '~/utils/charts'
import HeaderModeText from './HeaderModeText'

const GRADIENTS = {
  standard: createGradientCanvas([{ offset: 0, color: COLORS.GRADIENT }, { offset: 0.5704, color: COLORS.GRADIENT }]),
  mobile: createGradientCanvas([{ offset: 0.40, color: COLORS.GRADIENT_END }, { offset: 1.0, color: COLORS.GRADIENT_START }]),
  upperMobile: createGradientCanvas([{ offset: 0.2194, color: COLORS.GRADIENT_END }, { offset: 0.6647, color: COLORS.GRADIENT_START }]),
  lowerMobile: createGradientCanvas([{ offset: 0.4, color: COLORS.GRADIENT }, { offset: 0.7, color: COLORS.GRADIENT_START }])
}

const ScoreProjectionChart = props => {
  const {
    user,
    projections,
    headerMode,
  } = props
  const { experianStatus } = user

  const isMobile = useSelector(selectIsMobile)
  const dimensions = useSelector(selectDimensions)

  const isZeroLimitUser = checkIsZeroLimitUser(user)
  const isActiveCreditUser = Boolean(user.credit && experianStatus === EXPERIAN_STATUSES.SUBSCRIBED)
  const showRealData = Boolean(isActiveCreditUser && !isZeroLimitUser && user.status !== USER_STATUSES.PAUSED)
  const projectedScore = projections[projections.length - 1].baseline
  const projectedIncrease = projectedScore - projections[0].baseline

  return (
    <div className="overflow-hidden">
      <div
        className={classNames('relative w-full max-h-70', {
          '-ml-2.5': headerMode && isMobile
        })}
        style={{ width: isMobile && headerMode ? `${dimensions.width + 20}px` : '100%' }}
        data-testid="stellar-credit-score-projection-chart"
      >
        <div
          className={classNames('absolute font-semibold text-white', {
            'top-4': headerMode,
            'top-7': !headerMode,
            'left-10': headerMode,
            'left-14': !headerMode
          })}
          data-testid="stellar-credit-score-projection-chart-pts"
        >
          <p className={classNames({
            'text-5xl': headerMode,
            'text-4xl': !headerMode,
            'text-white-300': headerMode,
            'text-black': !headerMode
          })}
          >
            {projectedScore}
            <span
              className={classNames('text-base font-normal ml-1', {
                'text-deepspace-100': headerMode,
                'text-black': !headerMode,
              })}
              data-testid="stellar-credit-score-projection-chart-increase"
            >
              +
              {projectedIncrease}
              {' '}
              Pts
            </span>
          </p>
          {headerMode && <HeaderModeText user={user} projections={projections} />}
        </div>
        <Chart
          height={isMobile ? 280 : 200}
          options={getChartOptions(showRealData, headerMode)}
          plugins={headerMode ? [] : [chartAreaBorderPlugin, chartAreaBorderBottomPlugin]}
          data={getScoreProjectionChartData(projections, GRADIENTS, projection => projection === projectedScore, showRealData, headerMode)}
          data-testid="stellar-credit-score-projection-chart-diagram"
        />
      </div>
    </div>
  )
}
ScoreProjectionChart.propTypes = {
  user: PropTypes.shape({
    spendLimitCents: PropTypes.number.isRequired,
    credit: PropTypes.shape({
      score: PropTypes.number,
    }),
    status: PropTypes.string.isRequired,
    registrationNextStep: PropTypes.string.isRequired,
    experianStatus: PropTypes.string.isRequired,
  }).isRequired,
  projections: PropTypes.arrayOf(PropTypes.shape({
    baseline: PropTypes.number.isRequired,
    lowerLimit: PropTypes.number.isRequired,
    upperLimit: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  headerMode: PropTypes.bool,
}
ScoreProjectionChart.defaultProps = {
  headerMode: false,
}

export default React.memo(ScoreProjectionChart)
