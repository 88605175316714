export const UNLEASH_URL_SEARCH_PARAM_PREFIX = 'unleash_'

// snake_case in the unleash dashboard becomes camelCase here
export const EXPERIMENTS = {
  MX_M2: 'mxM2',
  PHONE_NUMBER_2FA: 'phoneNumber_2FA',
  SIGNUP_DYNAMIC_HEADLINE_BILLS: 'signupDynamicHeadlineBills',
  SKIP_RECAPTCHA: 'skipRecaptcha',
  SHOW_CREDIT_REPORTING_DATE: 'showCreditReportingDate',
  APPLE_SIGN_IN: 'appleSignIn',
  PRIME_3_MONTH_GUARANTEE: 'prime_3MonthGuarantee',
  NATIVE_APP_LAUNCH_ANNOUNCEMENT_ANDROID_ALL: '1.1NativeAppLaunchAnnouncement',
  NATIVE_APP_LAUNCH_ANNOUNCEMENT_IPHONE_ALL: '1.3NativeAppLaunchAnnouncement',
  MX_PLAID_FALLBACK: 'mxPlaidFallback',
  MX_SHUTDOWN: 'mxShutdown',
  PRE_MEMBERSHIP_PLAN_EDUCATION: 'preMembershipPlanEducation',
  FIRST_BILL_LINK_POPULAR_BILLERS: 'firstBillLinkPopularBillers',
  HIGHNOTE_ROLLOUT_NEW_USERS: 'highnoteRolloutNewUsers',
  HIGHNOTE_ROLLOUT_EXISTING_USERS: 'highnoteRolloutExistingUsers',
  REWARDS_REDEMPTION: 'rewardsRedemption',
  SHUTDOWN_RAF: 'shutdownRaf',
  SHUTDOWN_CREATE_ACCOUNT: 'shutdownCreateAccount',
  HIDE_MOBILE_APP_ANCMT: 'hideMobileAppAncmt',
  TEMP_OUTAGE: '1.0TempOutage',
  MOBILE_DOB_SELECTOR: 'mobileDobSelector',
}

export const EXPERIMENT_VARIANTS = {
  NATIVE_APP_LAUNCH_ANNOUNCEMENT_ANDROID_ALL: {
    CONTROL: 'control',
    V1: 'v1',
  },
  NATIVE_APP_LAUNCH_ANNOUNCEMENT_IPHONE_ALL: {
    CONTROL: 'control',
    V1: 'v1',
  },
  MOBILE_DOB_SELECTOR: {
    CONTROL: 'control',
    V1: 'v1',
    V2: 'v2',
  },
}
