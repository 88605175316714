export const UPLOAD_DOCUMENTS_STEPS = {
  UPLOAD_DOCUMENTS_TO_SOCURE: 'upload_documents_to_socure',
  SOCURE_DOCUMENTS_UPLOADED: 'socure_documents_uploaded',
  SOCURE_FAILED_CONTACT_SUPPORT: 'socure_failed_contact_support',
  HIGHNOTE_DOCUMENT_VERIFICATION: 'highnote_document_verification',
}

export const REGISTRATION_STEPS = {
  CONFIRM_IDENTITY: 'confirm_identity',
  SET_SUBSCRIPTION: 'set_subscription',
  LINK_BANK_ACCOUNT: 'link_bank_account',
  HIGHNOTE_APPLICATION_IN_PROGRESS: 'highnote_application_in_progress',
  CONFIRM_ACCOUNT: 'confirm_account',
  ALL_SET: 'all_set',
  CONTACT_SUPPORT: 'contact_support',
  ...UPLOAD_DOCUMENTS_STEPS,
}

export const CREDIT_SCORE_INCREASED_STATUSES = {
  SCORE_INCREASE: 'score_increased'
}

export const BILL_CARD_VERSIONS = {
  OTHER_STEPS: 0,
  LINK_BANK_ACCOUNT: 1,
  SET_MEMBERSHIP: 2,
  COMPLETED_FLOW: 3
}

export const IDENTITY_CONFIRMATION_STEPS = {
  IDENTITY: 1,
  ADDRESS: 2,
  PHONE_VERIFICATION: 3,
  SUMMARY: 4,
}

export const USER_STATUSES = {
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
  PAUSED: 'paused',
  CLOSING: 'closing',
  CLOSED: 'closed',
  LOCKED: 'locked',
}

export const EXPERIAN_STATUSES = {
  PENDING: 'pending',
  FAILED: 'failed',
  PRUNED: 'pruned',
  SUBSCRIBED: 'subscribed',
  DEACTIVATED: 'deactivated',
  DELETED: 'deleted',
}

export const SPEND_LIMIT_KEYS = {
  V1_LEGACY_LIMIT: 'v1_legacy_limit',
  MINIMUM_OVERRIDE: 'minimum_override',
}

export const KYC_STATUSES = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  REFER: 'refer',
  DUPLICATE: 'duplicate'
}

export const DOCV_STATUSES = {
  ACCEPT: 'accept',
  EXPIRED: 'expired',
  IN_PROGRESS: 'in_progress',
  REJECT: 'reject',
  SOFT_FAILED: 'soft_failed',
  RESUBMIT: 'resubmit',
  UPLOADED: 'uploaded'
}

export const HIGHNOTE_KYC_STATUSES = {
  IN_AUTO_REVIEW: 'in_auto_review',
  UPLOAD_PENDING: 'upload_pending',
  POST_SOCURE_UPLOAD_PENDING: 'post_socure_upload_pending',
  ONGOING_REVIEW: 'ongoing_review',
  ADDITIONAL_UPLOAD_REQUESTED: 'additional_upload_requested',
  DENIED: 'denied'
}

export const RISK_LEVELS = {
  UNKNOWN: 'unknown',
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
}

export const HIGH_RISK_LEVEL_REASONS = {
  NO_OWNERSHIP_MATCH: 'no_ownership_match',
  INSUFFICIENT_TRANSACTION_HISTORY: 'insufficient_transaction_history',
  OTHER: 'other'
}

export const SUBSCRIPTION_PLANS = {
  LITE: 'Lite',
  PRIME: 'Prime',
  PREMIUM: 'Premium',
}
export const SUBSCRIPTION_PLAN_RENEWAL_TYPES = {
  MONTHLY: 'monthly',
  ANNUAL: 'annual',
}

export const CARD_PROVIDERS = {
  LITHIC: 'lithic',
  CONNEXPAY: 'connexpay',
  HIGHNOTE: 'highnote',
}

export const HIGHNOTE_PRODUCT_APPLICATION_STEPS = {
  ACCOUNT_HOLDER: 'account_holder',
  PRODUCT_APPLICATION: 'product_application',
  UNDERWRITING_DECISION: 'underwriting_decision',
  ISSUE_FINANCIAL_ACCOUNT: 'issue_financial_account',
  ISSUE_CARD: 'issue_card',
}

export const GOOGLE_AUTH_TYPES = {
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
  CONNECT: 'connect'
}

export const APPLE_AUTH_TYPES = {
  SIGN_UP: 'signUp',
  SIGN_IN: 'signIn',
  CONNECT: 'connect'
}

export const ENTRY_POINTS = {
  WEB: 'web',
  MOBILE: 'mobile',
}

export const SUMMARY_STEPS = {
  SSN: 1,
  SUMMARY_FORM: 2
}

export const EMAIL_UPDATE_VARIANTS = {
  CONFIRMATION: 'confirmation',
  VERIFICATION: 'verification',
}
export const TEST_SSNS = [
  '666285344', '666242470', '666262187', '666389719', '666329522', '666567141', '666506100', '666622487', '666600740', '666766572',
  '666568649', '666585273', '666428606', '666587908', '666161689', '666462643', '666268328', '666112249', '666443161', '666844400',
  '666588485', '666385519', '666889522', '666743958', '666467096', '666569378', '666848034', '666921447', '666925816', '666509037',
  '666580959', '666804636', '666602241', '666360449', '666747034', '666529080', '666606033', '666564288', '666842032', '666900311',
  '666441491', '666464810', '666389719', '666467291', '666259019', '666922243', '666608316', '666533917', '666077411', '666522736',
  '666026867', '666329833', '666464400', '666483267', '666163365', '666680786', '666780619', '666620760', '666823837',
  '666824123' // EXPERIAN_TODO: remove this Array specific SSN from validation
]

export const STELLAR_CS_EMAIL = 'mailto:support@stellarfi.com'

export const USER_AGREEMENT_LINK = 'https://www.stellarfi.com/legal/stellar-user-agreement'

export const PAYMENT_AGREEMENT_LINK = 'https://www.stellarfi.com/legal/stellar-payment-authorization-agreement'

export const CREDIT_SCORE_INCREASE_TERMS_AND_CONDITIONS = 'https://stellarfi.com/legal/stellarfi-credit-score-increase-guarantee-terms-and-conditions'

export const UNKNOWN = 'unknown'
export const NO_FUND_LOW_MEDIUM_RISK = 'no_fund_low_medium_risk'
export const HIGH_RISK_NO_OWNERSHIP_MATCH = 'high_risk_no_ownership_match'
export const HIGH_RISK_INSUFFIENT_HISTORY = 'high_risk_insufficient_history'
export const HIGH_RISK_OTHER = 'high_risk_other'
