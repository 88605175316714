import { useEffect } from 'react'
import {
  configureScope as configureSentryScope,
  setUser as setSentryUser,
} from '@sentry/react'

// hook for everything that we want to do with Sentry at runtime on the background
export const useSentry = user => {
  // once we receive valid user data we need to set sentry user
  useEffect(() => {
    if (user) {
      const {
        uuid,
      } = user

      setSentryUser({
        id: uuid,
      })
    }

    return () => {
      configureSentryScope(scope => scope.setUser(null))
    }
  }, [user])

  return null
}
