import React, { useEffect } from 'react'
import { Outlet, useLocation, matchRoutes } from 'react-router-dom'
import classNames from 'classnames'

import { EXPERIMENTS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'

import AppHeader from '~/features/Headers/App'
import UserAgreement from '~/features/UserAgreement'
import AppFooter from '~/features/Footers/App'
import OnboardingProgressSteps from '~/features/OnboardingProgressSteps'
import CollapsiblePrimeGuaranteeCard from '~/features/CollapsiblePrimeGuaranteeCard'

import { ROUTES_LIST } from '~/pages/constants'
import { AUTH_LAYOUT_CONFIG } from './constants'

const AppLayout = () => {
  const location = useLocation()

  const {
    enabled: isPrimeGuarantee
  } = useExperiment(EXPERIMENTS.PRIME_3_MONTH_GUARANTEE)

  const matchArr = matchRoutes(ROUTES_LIST, location)

  const routeLayoutConfig = AUTH_LAYOUT_CONFIG[matchArr?.[0].route.path]

  const LayoutComponent = routeLayoutConfig?.LayoutComponent

  const showPrimeGuaranteeHeader = routeLayoutConfig?.show3monthGuaranteeHeader && isPrimeGuarantee
  const showOnboardingProgressSteps = routeLayoutConfig?.showOnboardingProgressSteps
  const bodyBackgroundColor = routeLayoutConfig?.bodyBackgroundColor ? routeLayoutConfig.bodyBackgroundColor : '#f5f5f5'

  useEffect(() => {
    document.body.style.backgroundColor = bodyBackgroundColor
  }, [bodyBackgroundColor])

  return (
    <>
      <UserAgreement />
      {LayoutComponent ? (
        <LayoutComponent>
          <Outlet />
        </LayoutComponent>
      ) : (
        <>
          <AppHeader />
          {showPrimeGuaranteeHeader ? (
            <CollapsiblePrimeGuaranteeCard />
          ) : null}
          {showOnboardingProgressSteps ? (
            <OnboardingProgressSteps showRoundOnboardingPageSteps />
          ) : null}
          <div className={classNames(
            'relative w-full md:max-w-[1060px] mx-auto px-4 pb-4',
            { 'top-5 md:-top-22': routeLayoutConfig?.topOffset,
              'mb-30': !routeLayoutConfig?.hideAppFooter },
          )}
          >
            <Outlet />
          </div>
          {routeLayoutConfig?.hideAppFooter ? null : (
            <AppFooter />
          )}
        </>
      )}
    </>
  )
}

export default React.memo(AppLayout)
