import { useState, useEffect, useCallback } from 'react'
import { captureMessage as captureSentryMessage } from '@sentry/react'
import { useDispatch, useSelector } from 'react-redux'

import { useFetchUserContextQuery } from '~/store/api'
import { EXPERIMENT_ENTERED } from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { selectIsImpersonationAuthorized } from '~/store/modules/auth/selectors'
import { setExperimentTracked } from '~/store/modules/analytics'
import { selectTrackedExperiments } from '~/store/modules/analytics/selectors'

// useExperiment
//   1. Checks the userContext for the current bucketing status for the given experiment
//   2. Emits an Experiment Entered event to Segment unless recordEvent = false
// parameters
//   experimentName (string): the camelCased name of an experiment
//   recordEvent (bool): record the event to segment, or skip reporting
// returns
//   enabled (bool): whether the experiment is enabled
//   type (string): the type of experiment, either ab or multivariate
//   variant (string): the bucketed variant for the experiment
//   payload (string, optional): the payload associated to the bucketed variant
//   isLoaded (bool): whether the experiment bucketing data was available
//   isLoadingUserContext (bool): indicates that we're still waiting for the user_context api call to return
//   trackExperiment (func): a callback that to emit the event to segment, in case it needs to be manually fired
export const useExperiment = (experimentName, recordEvent = true) => {
  const dispatch = useDispatch()
  const trackExperimentEntered = useSegmentEvents([EXPERIMENT_ENTERED])
  const isImpersonation = useSelector(selectIsImpersonationAuthorized)
  const trackedExperiments = useSelector(selectTrackedExperiments)

  const [experiment, setExperiment] = useState({ enabled: false, variant: 'unknown', type: '' })
  const [hasTrackedExperiment, setHasTrackedExperiment] = useState(false)

  const {
    data: userContextData = {},
    isLoading: isLoadingUserContext,
    isFetching: isFetchingUserContext,
    error
  } = useFetchUserContextQuery()

  const trackExperiment = useCallback((overrideEventData = {}) => {
    const eventData = {
      experimentName,
      experimentVariant: experiment.variant,
      ...overrideEventData,
    }
    if (experiment.type && !hasTrackedExperiment && !trackedExperiments[experimentName]) {
      trackExperimentEntered(eventData)
      setHasTrackedExperiment(true)
      dispatch(setExperimentTracked({ experimentName, eventData }))
    }
  }, [
    experimentName,
    experiment,
    trackExperimentEntered,
    hasTrackedExperiment,
    setHasTrackedExperiment,
    dispatch,
    trackedExperiments,
  ])

  useEffect(() => {
    if (!isLoadingUserContext && error) {
      captureSentryMessage('[useExperiment] Context error', { tags: { experimentName } })
      return
    }

    if (!isLoadingUserContext) {
      const { userContext: { experiments } } = userContextData
      const exp = experiments[experimentName]
      if (!exp) {
        captureSentryMessage('[useExperiment] Experiment missing', { tags: { experimentName } })
        return
      }

      setExperiment(exp)
    }
  }, [
    userContextData,
    isLoadingUserContext,
    error,
    experimentName,
    setExperiment,
  ])

  useEffect(() => {
    if (!recordEvent || experiment.variant === 'unknown' || isImpersonation) return

    trackExperiment()
  }, [
    recordEvent,
    trackExperiment,
    experiment.variant,
    isImpersonation
  ])

  return {
    ...experiment,
    isLoaded: !!experiment.type,
    trackExperiment: isImpersonation ? () => {} : trackExperiment,
    isLoadingUserContext,
    isFetchingUserContext,
  }
}
