import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { ADD_BANK_CLICKED } from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'

import CTALink from '~/components/ui/Links/CTALink'
import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'
import { MODAL_HASH_BASED_IDS } from '~/constants'
import { LINK_ACCOUNT_VARIANTS } from '../../constants'

const MxLink = props => {
  const {
    variant,
    disabled,
    btnText,
    btnClassName,
    reason
  } = props

  const location = useLocation()
  const trackAddBankClicked = useSegmentEvents([ADD_BANK_CLICKED])

  const queryParams = reason ? `?reason=${reason}` : ''
  const hrefTo = `${location.pathname}${queryParams}#${MODAL_HASH_BASED_IDS.MX_BANK_CONNECT}`

  const handleOpenMx = useCallback(() => {
    trackAddBankClicked({ provider: BANK_ACCOUNT_PROVIDERS.MX })
  }, [trackAddBankClicked])

  return (
    <div
      data-testid="stellar-link-bank-account-mx-link"
    >
      {variant === LINK_ACCOUNT_VARIANTS.V1 ? (
        <CTALink
          to={hrefTo}
          variant="contained"
          size=""
          className={`w-full text-base py-4 my-5 ${btnClassName}`}
          disabled={disabled}
          onClick={handleOpenMx}
          data-testid="stellar-link-bank-account-mx-btn"
        >
          <FontAwesomeIcon
            icon="far fa-plus"
            size="sm"
            className="mr-2 text-white"
          />
          <span
            className="text-white"
            data-testid="stellar-link-bank-account-mx-btn-text"
          >
            {btnText}
          </span>
        </CTALink>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V2 ? (
        <CTALink
          to={hrefTo}
          variant="contained"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          disabled={disabled}
          onClick={handleOpenMx}
          data-testid="stellar-link-bank-account-mx-btn-v2"
        >
          {btnText}
        </CTALink>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V4 ? (
        <CTALink
          to={hrefTo}
          variant="outlined"
          size="sm"
          className={`w-full mb-2 ${btnClassName}`}
          disabled={disabled}
          onClick={handleOpenMx}
          data-testid="stellar-link-bank-account-mx-btn-v4"
        >
          {btnText}
        </CTALink>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V5 ? (
        <CTALink
          to={hrefTo}
          variant="contained"
          size="sm"
          className={`w-full ${btnClassName}`}
          disabled={disabled}
          onClick={handleOpenMx}
          data-testid="stellar-link-bank-account-mx-btn-v5"
        >
          Connect bank
        </CTALink>
      ) : null}
      {variant === LINK_ACCOUNT_VARIANTS.V6 ? (
        <CTALink
          to={hrefTo}
          variant=""
          size=""
          className={`w-full text-deepspace hover:text-lightspeed underline font-bold text-base ${btnClassName}`}
          disabled={disabled}
          onClick={handleOpenMx}
        >
          {btnText}
        </CTALink>
      ) : null}
    </div>
  )
}

MxLink.propTypes = {
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  btnClassName: PropTypes.string,
  btnText: PropTypes.string,
  reason: PropTypes.string
}

MxLink.defaultProps = {
  variant: LINK_ACCOUNT_VARIANTS.V1,
  btnClassName: '',
  btnText: 'Connect bank',
  reason: '',
  disabled: false
}

const Memoized = React.memo(MxLink)
export default Memoized
