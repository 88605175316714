import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { MODAL_IDS } from '~/constants'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import { removeModalFromQueue } from '~/store/modules/ui'
import Modal from '~/components/ui/Modal'
import MobileModal from '~/components/ui/Modal/Mobile'

import DisclaimerContent from './Content'

const OnboardingCreditScoreDisclaimerModal = props => {
  const { isScoreProjectionAvailable } = props
  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handleCloseModal = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.ONBOARDING_CREDIT_SCORE_DISCLAIMER }))
  }, [modal, dispatch])

  return isMobile ? (
    <MobileModal
      show
      size="md"
      className="p-5"
      onClose={handleCloseModal}
      withButton
      btnProps={{
        className: '!py-2.75 text-lg leading-6'
      }}
      data-testid="stellar-onboarding-credit-score-disclaimer-mobile-modal"
    >
      <DisclaimerContent isScoreProjectionAvailable={isScoreProjectionAvailable} isMobile={isMobile} />
    </MobileModal>
  ) : (
    <Modal
      show
      size="md"
      onClose={handleCloseModal}
      withCloseButton
      data-testid="stellar-onboarding-credit-score-disclaimer-desktop-modal"
    >
      <DisclaimerContent isScoreProjectionAvailable={isScoreProjectionAvailable} isMobile={isMobile} />
    </Modal>
  )
}

OnboardingCreditScoreDisclaimerModal.propTypes = {
  isScoreProjectionAvailable: PropTypes.bool.isRequired,
}

export default NiceModal.create(OnboardingCreditScoreDisclaimerModal)
