import { useEffect, useState } from 'react'

// this hook can be handy when you need an additional delay that happens separately from other conditions.
// Let's say, you need a task to complete not less that in 2 seconds. You pass 2 as first argument to the hook
// and it will return a boolean flag indicating did the expected amount of time last
export const useTimeout = delay => {
  const [timeoutFinished, setTimeoutFinished] = useState(false)

  useEffect(() => {
    setTimeout(() => setTimeoutFinished(true), delay * 1000)
  }, [delay, setTimeoutFinished])

  return {
    timeoutFinished,
  }
}
