import React from 'react'

import { EXPERIAN_STATUSES } from '~/constants'
import { ROUTES } from '~/pages/constants'
import Can from '~/containers/Flags/Can'
import classNames from 'classnames'
import StandardLink from '~/components/ui/Links/StandardLink'

const Navigation = () => {
  const linkClasses = classNames(
    'no-underline decoration-transparent text-white font-medium mr-5 hover:decoration-red hover:underline underline-offset-4'
  )

  return (
    <>
      <StandardLink
        to={ROUTES.DASHBOARD}
        size="md"
        color=""
        variant=""
        className={linkClasses}
        data-testid="stellar-app-header-navigation-dashboard-link"
      >
        Dashboard
      </StandardLink>
      <StandardLink
        to={ROUTES.PAYMENT_HISTORY}
        size="md"
        color=""
        variant=""
        className={linkClasses}
        data-testid="stellar-app-header-navigation-payment-history-link"
      >
        Payment History
      </StandardLink>
      <Can
        permissions={{
          experianStatus: EXPERIAN_STATUSES.SUBSCRIBED,
        }}
      >
        <StandardLink
          to={ROUTES.CREDIT_TOOLS}
          size="md"
          color=""
          variant=""
          className={linkClasses}
        >
          Credit Launchpad
        </StandardLink>
      </Can>
      <StandardLink
        to={ROUTES.REWARDS}
        size="md"
        color=""
        variant=""
        className={linkClasses}
        data-testid="stellar-app-header-navigation-rewards-link"
      >
        Rewards
      </StandardLink>
    </>
  )
}

const Memoized = React.memo(Navigation)
export default Memoized
