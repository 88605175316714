const clientStorage = {
  COOKIE_ANONYMOUS_ID: 'com.stellar.anonymousId',
  COOKIE_BANK_ISSUE_ALERT: 'com.stellar.bankIssueAlert',
  COOKIE_HIDE_ACTIVATE_BILL_PAY_CARD_MODAL: 'com.stellar.hideActivateBillPayCardModal',
  COOKIE_HIDE_CARD_PAUSED_MODAL: 'com.stellar.hideCardPausedModal',
  COOKIE_HIDE_FIRST_BILL_LINKED_MODAL: 'com.stellar.hideFirstBillLinkedModal',
  COOKIE_HIDE_ONBOARDING_FINISHED_MODAL: 'com.stellar.hideOnboardingFinishedModal',
  COOKIE_HIDE_SMS_OPT_IN_MODAL: 'com.stellar.hideSMSOptInModal',
  COOKIE_LINKED_BILLS: 'com.stellar.linkedBills',
  COOKIE_PROMO_CODE_MODAL: 'com.stellar.promoCodeModal',
  COOKIE_TOKEN: 'com.stellar.token',
  COOKIE_DISMISSED_CARD_ACTIVATION_SUCCESS_MODAL: 'com.stellar.dismissedCardActivationSuccessModal',
  IMPERSONATION_TOKEN: 'com.stellar.impersonationToken',
  MEMBER_DOB: 'com.stellar.memberDOB',
}

const envVars = {
  APPLE_OAUTH_CLIENT_ID: process.env.APPLE_OAUTH_CLIENT_ID,
  BILLER_CONNECT_URL: process.env.BILLER_CONNECT_URL,
  CLOUDFLARE_ACCOUNT_HASH: process.env.CLOUDFLARE_ACCOUNT_HASH,
  COOKIE_YES_URL: process.env.COOKIE_YES_URL,
  ENVIRONMENT: process.env.NODE_ENV,
  FINGERPRINT_JS_API_KEY: process.env.FINGERPRINT_JS_API_KEY,
  FINGERPRINT_VISITOR_ID_KEY: '_fingerprint_visitor_id',
  GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
  GOOGLE_OAUTH_CLIENT_ID: process.env.GOOGLE_OAUTH_CLIENT_ID,
  HEROKU_APP_ENV: process.env.HEROKU_APP_ENV,
  HEROKU_SLUG_COMMIT: process.env.HEROKU_SLUG_COMMIT,
  LOB_SECRET_KEY: process.env.LOB_SECRET_KEY,
  PLAID_TOKEN_API_URL: process.env.PLAID_TOKEN_API_URL,
  RECAPTCHA_V3_ENABLED: process.env.RECAPTCHA_V3_ENABLED,
  RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
  RECAPTCHA_V3_SITE_KEY: process.env.RECAPTCHA_V3_SITE_KEY,
  REDUX_LOGGER: process.env.REDUX_LOGGER,
  SEGMENT_WRITE_KEY: process.env.SEGMENT_WRITE_KEY,
  SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
  SENTRY_DSN: process.env.SENTRY_DSN,
  SERVER_URL: `${process.env.SERVER_API_URL}/${process.env.API_VERSION}`,
  SOCURE_SDK_PUBLIC_KEY: process.env.SOCURE_SDK_PUBLIC_KEY,
  MOBILE_APP_BUNDLE_ID: process.env.MOBILE_APP_BUNDLE_ID,
}

const config = { ...clientStorage, ...envVars }

export default config
