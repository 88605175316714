import { useState, useEffect, useCallback } from 'react'
import { throttle } from '~/utils'

export const isValidNotEmptyArray = list => !!(list && list?.length && list?.length > 0)

// hook that groups multiple RTK Query responses into a single array when infinitely scrolling
const useInfiniteScroll = (useLazyGetDataListQuery, params, isExperiment = true) => {
  const [listData, setListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)

  const handleSetNextPage = useCallback(throttle(() => setCurrentPage(page => page + 1)), [setCurrentPage])

  const [fetchDataList, queryResponse] = useLazyGetDataListQuery()

  // API call goes here
  useEffect(() => {
    if (isExperiment) {
      fetchDataList({
        ...params,
        page: currentPage,
      })
    }
  }, [fetchDataList, currentPage, params, isExperiment])

  const {
    data = {},
    isFetching,
  } = queryResponse
  const {
    list,
    totalPages,
  } = data || {}

  useEffect(() => {
    if (isValidNotEmptyArray(list)) {
      setListData(previousData => [...previousData, ...list])
    }
  }, [list])

  const refresh = useCallback(() => {
    setCurrentPage(1)
    setListData([])
  }, [])

  // if search updates - we need to refresh the list
  useEffect(() => {
    refresh()
  }, [params.search, refresh])

  const loadMore = useCallback(() => {
    if (currentPage < totalPages && !isFetching && listData.length > 0) {
      handleSetNextPage()
    }
  }, [currentPage, totalPages, isFetching, listData.length, handleSetNextPage])

  return {
    ...queryResponse,
    listData,
    currentPage,
    loadMore,
    refresh,
  }
}

export default useInfiniteScroll
