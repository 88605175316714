export const BILL_STATUS = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  INACTIVE: 'inactive',
  INCOMPLETE: 'incomplete',
  ISSUE_AUTH: 'issue-auth',
  ISSUE_AUTOPAY: 'issue-autopay',
  ISSUE_PAYMENT: 'issue-payment',
  NO_BILL_DUE: 'no-bill-due',
  OVERDUE: 'overdue',
  PAID: 'paid',
  PAYMENT_DECLINED: 'payment-declined',
  PAYMENT_PENDING: 'payment-pending',
  SYNCING: 'syncing',
  UNKNOWN: 'unknown',
  UPCOMING: 'upcoming',
}

export const BILL_STATUS_BACKGROUND_COLORS = {
  [BILL_STATUS.PAID]: 'bg-lightspeed',
  [BILL_STATUS.PAYMENT_DECLINED]: 'bg-orange',
  [BILL_STATUS.UPCOMING]: 'bg-steel-100', // Should be Steel/200
  [BILL_STATUS.INACTIVE]: 'bg-steel-100',
  [BILL_STATUS.INCOMPLETE]: 'bg-steel-100', // Should be Steel/200
  [BILL_STATUS.DRAFT]: 'bg-steel-100', // Should be Steel/200
  [BILL_STATUS.PAYMENT_PENDING]: 'bg-steel-100', // Should be Steel/200
}

export const UNLINK_BILLS = 'unlink_bills'

export const UNLINK_BILL_INSTRUCTIONS = [
  {
    title: '1.',
    description: 'Login to your billers website. ',
  },
  {
    title: '2.',
    description: 'Go to your payment settings. ',
  },
  {
    title: '3.',
    description: 'Remove your StellarFi card and add your personal card.',
  },
]
