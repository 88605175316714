export const formatDayAccordingToMonth = (day, month) => {
  if (month) {
    switch (month) {
      case '02':
        return day > '28' ? '28' : day
      case '04':
      case '06':
      case '09':
      case '11':
        return day > '30' ? '30' : day
      default:
        return day > '31' ? '31' : day
    }
  } else if (day > 31 && !month) {
    return '31'
  } else {
    return day
  }
}

export const formatDateWithSlash = (date, maxYear) => {
  let formattedDate = date

  const isFirstDatePartEntered = formattedDate.length >= 3
  const isSecondDatePartEntered = formattedDate.length >= 5

  if (isFirstDatePartEntered) {
    let day = formattedDate.slice(2, 4)
    let month = formattedDate.slice(0, 2)
    let year = formattedDate.slice(4, 8)

    day = formatDayAccordingToMonth(day, month)

    if (month > '12') month = '12'
    if (year > maxYear) year = maxYear

    formattedDate = `${month}/${day}${isSecondDatePartEntered ? '/' : ''}${year}`
  } else {
    let value = formattedDate.slice(0, 2)

    if (value > '12') value = '12'

    formattedDate = `${value}${isFirstDatePartEntered ? '/' : ''}${formattedDate.slice(2, 4)}`
  }

  return formattedDate
}
