import {
  createSlice,
} from '@reduxjs/toolkit'

const initialState = {
  authMask: '',
  isAuth: false,
  isImpersonationAuth: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthMask(state, action) {
      return {
        ...state,
        authMask: action.payload
      }
    },
    setIsAuthorized(state, action) {
      return {
        ...state,
        isAuth: action.payload,
      }
    },
    setIsImpersonationAuthorized(state, action) {
      return {
        ...state,
        isImpersonationAuth: action.payload
      }
    },
  },
})

export const {
  setAuthMask,
  setIsAuthorized,
  setIsImpersonationAuthorized
} = authSlice.actions

export default authSlice.reducer
