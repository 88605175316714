import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CollapsibleCard from '~/components/ui/CollapsibleCard'

const CollapsiblePrimeGuaranteeCard = () => (
  <CollapsibleCard
    key="collapsible-prime-guarantee-card"
    className="bg-red text-white text-sm"
    iconClassName="!ml-0"
    titleClassName="justify-center"
    title={(
      <div className="text-center text-white mr-3 font-semibold">
        <FontAwesomeIcon
          icon="fa-sharp fa-solid fa-shield-check"
          className="mr-2"
        />
        Money back guarantee
      </div>
    )}
  >
    <div className="text-center font-normal">
      Credit score
      <span className="mx-1 font-semibold">
        increase 100%
      </span>
      guaranteed within just 3 months, or your money back.
    </div>
  </CollapsibleCard>
)

const Memoized = React.memo(CollapsiblePrimeGuaranteeCard)
export default Memoized
