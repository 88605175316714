export const BACKGROUNDS = {
  green: 'bg-green text-green',
  yellow: 'bg-yellow',
  red: 'bg-red text-red',
  blue: 'bg-blue text-blue',
  orange: 'bg-blue text-blue',
  lightspeed: 'bg-lightspeed text-lightspeed'
}

export const NOTIFICATION_EVENTS = {
  BILL_PAID: 'bill_paid',
  BILL_OVERDUE: 'bill_overdue'
}
