import {
  REGISTRATION_STEPS,
  BILL_CARD_VERSIONS,
  SUBSCRIPTION_PLANS,
  CARD_PROVIDERS,
  RISK_LEVELS,
  HIGH_RISK_LEVEL_REASONS,
  USER_STATUSES,
  DOCV_STATUSES,
  KYC_STATUSES,
  CREDIT_TRACKER_IN_PROGRESS,
  CREDIT_TRACKER_OPEN,
  UNKNOWN,
  NO_FUND_LOW_MEDIUM_RISK,
  HIGH_RISK_INSUFFIENT_HISTORY,
  HIGH_RISK_NO_OWNERSHIP_MATCH,
  HIGH_RISK_OTHER,
  HIGHNOTE_PRODUCT_APPLICATION_STEPS,
  CARD_STATES,
} from '~/constants'

import { BANK_ACCOUNT_PROVIDERS } from '~/constants/bankAccounts'

export const checkIsZeroLimitUser = user => user?.registrationNextStep === REGISTRATION_STEPS.ALL_SET && user?.spendLimitCents === 0

// vanity function because checkIsZeroLimitUser doesn't describe purpose of its result
// user's risk level may be high risk but we may have been manually changed spend limit in order to let them through
export const checkIsHighRiskUser = user => checkIsZeroLimitUser(user)

export const checkIsLiteUser = (user = {}) => user.subscriptionPlan?.name === SUBSCRIPTION_PLANS.LITE

export const checkIsUserAllowedToAddBill = (user = {}) => {
  const isNotPaused = user.status !== USER_STATUSES.PAUSED
  return !(user.riskLevel === RISK_LEVELS.HIGH && user.spendLimitCents === 0) && isNotPaused && !user.lockedCollectable
}

export const checkIsMissingBankAccount = user => user?.accounts?.length === 0

export const getBillPayCardVersion = registrationNextStep => {
  switch (registrationNextStep) {
    case REGISTRATION_STEPS.LINK_BANK_ACCOUNT:
      return BILL_CARD_VERSIONS.LINK_BANK_ACCOUNT
    case REGISTRATION_STEPS.SET_SUBSCRIPTION:
      return BILL_CARD_VERSIONS.SET_MEMBERSHIP
    case REGISTRATION_STEPS.CONFIRM_ACCOUNT:
    case REGISTRATION_STEPS.ALL_SET:
      return BILL_CARD_VERSIONS.COMPLETED_FLOW
    default:
      return BILL_CARD_VERSIONS.OTHER_STEPS
  }
}

export const checkisAddBillAllowed = user => {
  const billPayCardVersion = getBillPayCardVersion(user?.registrationNextStep)
  return billPayCardVersion === BILL_CARD_VERSIONS.COMPLETED_FLOW && user?.status === USER_STATUSES.VERIFIED
}

export const getAccountBalance = accountInfo => {
  const balances = [accountInfo?.currentBalance, accountInfo?.availableBalance].filter(Number)
  if (balances.length === 0) return 0

  return Math.min(...balances) || 0
}

export const checkIsDocvAvailable = user => (
  user?.kycStatus === KYC_STATUSES.REFER
    && (
      !user.docvStatus
        || DOCV_STATUSES.IN_PROGRESS === user.docvStatus
        || DOCV_STATUSES.UPLOADED === user.docvStatus
    )
)

export const checkIsDocvInProgress = user => (
  checkIsDocvAvailable(user) && (
    DOCV_STATUSES.IN_PROGRESS === user.docvStatus
    || DOCV_STATUSES.UPLOADED === user.docvStatus
  )
)
export const checkHasUserHighnoteCard = card => card?.provider === CARD_PROVIDERS.HIGHNOTE

export const getUserFirstName = user => user?.identityFirstName || user?.firstName || ''
export const getUserLastName = user => user?.identityLastName || user?.lastName || ''

export const checkIsPausedAccount = user => user?.status === USER_STATUSES.PAUSED
export const checkIsClosedAccount = user => user?.status === USER_STATUSES.CLOSED
export const checkIsLockedAccount = user => user?.status === USER_STATUSES.LOCKED
export const checkIsVerifiedUser = user => user?.status === USER_STATUSES.VERIFIED

export const checkCreditTrackerStatus = (user, statuses) => (
  Array.isArray(statuses) ? statuses.includes(user?.creditScoreTrackerStatus) : user?.creditScoreTrackerStatus === statuses
)

export const checkUserStatus = (user, statuses) => (Array.isArray(statuses) ? statuses.includes(user?.status) : user?.status === statuses)

export const checkUserRegistrationStep = (user, steps) => (
  Array.isArray(steps) ? steps.includes(user?.registrationNextStep) : user?.registrationNextStep === steps
)

export const checkUserCardState = (user, states) => (
  Array.isArray(states) ? states.includes(user?.card?.state) : user?.card?.state === states
)

export const getIsAddBillAllowed = (user = {}) => {
  const isNotHighRiskUser = !checkIsHighRiskUser(user)
  const allowedUserStatuses = [USER_STATUSES.VERIFIED, USER_STATUSES.UNVERIFIED]
  const allowedCreditTrackerStatuses = [CREDIT_TRACKER_OPEN, CREDIT_TRACKER_IN_PROGRESS]
  const isVisibleForUserStatuses = checkUserStatus(user, allowedUserStatuses)
  const isVisibleForTrackerStatuses = checkCreditTrackerStatus(user, allowedCreditTrackerStatuses)

  return isNotHighRiskUser && isVisibleForTrackerStatuses && isVisibleForUserStatuses
}

/**
 * Determines whether to show/hide the Credit Score charts
 * @param {Object} user current user object
 * @returns {boolean}
 */
export const getIsCreditScoreProjectionAllowed = (user = {}) => {
  const isNotHighRiskUser = !checkIsHighRiskUser(user)
  const isGoodStandingUser = checkUserStatus(user, [USER_STATUSES.VERIFIED, USER_STATUSES.UNVERIFIED])

  return isGoodStandingUser && isNotHighRiskUser
}

export const getBankProvider = (isMxShutdownExperimentEnabled, bankProviderOverride, isMXExperimentEnabled) => {
  if (isMxShutdownExperimentEnabled) return BANK_ACCOUNT_PROVIDERS.PLAID
  if (bankProviderOverride) return bankProviderOverride

  return isMXExperimentEnabled ? BANK_ACCOUNT_PROVIDERS.MX : BANK_ACCOUNT_PROVIDERS.PLAID
}

export const checkIsMXBankProvider = bankProvider => bankProvider === BANK_ACCOUNT_PROVIDERS.MX

export const checkHasBankIssue = user => {
  const userAccount = user?.accounts && user?.accounts?.[0]
  const isClosedAccount = checkIsClosedAccount(user)
  const noBankAccountFoundForClosedAccount = isClosedAccount && !userAccount?.uuid && (
    user?.registrationNextStep === REGISTRATION_STEPS.CONFIRM_ACCOUNT
    || user?.registrationNextStep === REGISTRATION_STEPS.SET_SUBSCRIPTION
    || user?.registrationNextStep === REGISTRATION_STEPS.ALL_SET)
  const noBankAccountFound = !userAccount?.uuid && user?.registrationNextStep === REGISTRATION_STEPS.ALL_SET

  return noBankAccountFound || userAccount?.errorCode || userAccount?.blocked || noBankAccountFoundForClosedAccount
}

export const checkHasCurrentMxProvider = user => {
  const userAccount = user?.accounts && user?.accounts?.[0]

  return checkIsMXBankProvider(userAccount?.providerType)
}

export const checkHasStellarTradeLine = (creditReport = {}) => {
  if (!Object.keys(creditReport).length) {
    return false
  }

  const { stellarTradelines } = creditReport

  if (!stellarTradelines?.length) {
    return false
  }

  return true
}

export const checkIsLockedNotCollectable = (user = {}) => user.status === USER_STATUSES.LOCKED && !user?.lockedCollectable

export const checkIsPrimeGuarantee = user => !!user?.creditScoreIncreaseGuarantee?.status

export const checkShowSubscriptionPrimeGuaranteeBanner = user => !checkIsClosedAccount(user)
&& checkIsPrimeGuarantee(user)
&& (user?.settings?.showCreditScoreIncreasedBanner || user?.settings?.showCreditScoreIncreaseGuaranteeBanner)

export const getReviewAccountStatus = (riskLevel, highRiskLevelReason, isNotEnoughFunds) => {
  if (riskLevel === RISK_LEVELS.HIGH) {
    if (highRiskLevelReason === HIGH_RISK_LEVEL_REASONS.INSUFFICIENT_TRANSACTION_HISTORY) {
      return HIGH_RISK_INSUFFIENT_HISTORY
    }
    if (highRiskLevelReason === HIGH_RISK_LEVEL_REASONS.NO_OWNERSHIP_MATCH) {
      return HIGH_RISK_NO_OWNERSHIP_MATCH
    }
    return HIGH_RISK_OTHER
  }
  if ((riskLevel === RISK_LEVELS.LOW || riskLevel === RISK_LEVELS.MEDIUM) && isNotEnoughFunds) {
    return NO_FUND_LOW_MEDIUM_RISK
  }
  return UNKNOWN
}

export const checkIsBalanceCheckInProgress = user => {
  const billPayCardVersion = getBillPayCardVersion(user?.registrationNextStep)
  const accountInfo = user?.accounts?.[0]
  const isLinkBankAccountStep = billPayCardVersion === BILL_CARD_VERSIONS.OTHER_STEPS || billPayCardVersion === BILL_CARD_VERSIONS.LINK_BANK_ACCOUNT
  const isAccountDisconnected = checkIsMissingBankAccount(user) || !Object.keys(accountInfo || {}).length || accountInfo?.errorCode

  return !isLinkBankAccountStep && !isAccountDisconnected && !accountInfo?.legibleBalanceLastCheckedAt
}

export const checkIsEveryFinalizedBillUpdatedToNewCardProvider = (linkedBills = []) => linkedBills.every(
  // billPayCardProvider: null means bill hasn't been finalized yet
  linkedBill => !linkedBill.billPayCardProvider || linkedBill.billPayCardProvider === CARD_PROVIDERS.HIGHNOTE
)

export const checkIsHighnoteCardIssued = user => user?.productCardRegistrationStep === HIGHNOTE_PRODUCT_APPLICATION_STEPS.ISSUE_CARD
export const checkIsKycInReview = user => user?.registrationNextStep === REGISTRATION_STEPS.HIGHNOTE_DOCUMENT_VERIFICATION
export const checkIsGoodStandingUser = user => checkIsVerifiedUser(user) && user?.card?.state === CARD_STATES.OPEN
