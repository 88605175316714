import React from 'react'

import { CLOUDFLARE_IMAGE_IDS, CREDIT_SCORE_INCREASE_TERMS_AND_CONDITIONS } from '~/constants'
import { getCloudflareImageURL } from '~/utils'

import Link from '~/components/ui/Link'

import { MODAL_CONTENT } from './constants'

const PrimeGuaranteeModalContent = () => (
  <>
    <div className="flex items-center">
      <img
        src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.PRIME_GUARANTEE)}
        alt="prime guarantee"
        className="w-15 h-15 mr-4"
      />
      <h2 className="text-2xl font-semibold">
        {MODAL_CONTENT.HEADER}
      </h2>
    </div>
    <p className="mt-5 mb-6 text-base font-normal">
      {MODAL_CONTENT.DESCRIPTION}
      <Link
        variant=""
        className="ml-1"
        target="_blank"
        href={CREDIT_SCORE_INCREASE_TERMS_AND_CONDITIONS}
      >
        View full terms and conditions
      </Link>
    </p>
  </>
)

const Memoized = React.memo(PrimeGuaranteeModalContent)
export default Memoized
