import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Modal from '~/components/ui/Modal'
import Button from '~/components/ui/Button'
import Link from '~/components/ui/Link'
import { CLOUDFLARE_IMAGE_IDS } from '~/constants'
import { getCloudflareImageURL } from '~/utils'
import {
  AFFILIATE_LINK_CLICKED,
} from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'

export const AFFILIATE_LINK = 'https://www.dpbolvw.net/click-100664121-14415782'

const ChimeModal = props => {
  const {
    show,
    onClose,
  } = props

  const trackAffiliateLinkClicked = useSegmentEvents([AFFILIATE_LINK_CLICKED])

  const handleCloseModal = useCallback(async () => {
    onClose()
  }, [onClose])

  const handleAffiliateLinkClicked = useCallback(() => {
    trackAffiliateLinkClicked({ affiliate: 'Chime', AFFILIATE_LINK })

    handleCloseModal()
  }, [handleCloseModal, trackAffiliateLinkClicked])

  return (
    <Modal
      show={show}
      className="p-5 max-w-93.75"
      size=""
      data-testid="stellar-chime-modal"
      onClose={handleCloseModal}
    >
      <img
        src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.CHIME_LOGO)}
        alt="chime logo"
        className="mb-2 mx-auto"
      />
      <p className="text-2xl font-semibold mb-2">
        We recommend Chime
      </p>
      <p className="mb-5">
        Chime offers better banking for everyone. Sign up for a Chime® Checking Account below.
      </p>
      <Link
        href={AFFILIATE_LINK}
        target="_blank"
        rel="noreferrer"
        className="w-full text-center mb-3"
        variant="contained"
        onClick={handleAffiliateLinkClicked}
        data-testid="stellar-chime-modal-get-started-link"
      >
        Get started
      </Link>
      <Button
        variant="outlined"
        className="w-full mb-5"
        onClick={handleCloseModal}
        data-testid="stellar-chime-modal-dismiss-button"
      >
        No thanks
      </Button>
      <p className="text-xs text-steel">
        Chime is a financial technology company, not a bank. Banking services provided by The Bancorp Bank, N.A. or Stride Bank, N.A., Members FDIC.
      </p>
    </Modal>
  )
}
ChimeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const Memoized = React.memo(ChimeModal)
export default Memoized
