import { ROUTES } from '~/pages/constants'

// TODO: Remove showZendeskChat once we've decided we do not want it on mobile
const COLOR_WHITE = '#FFFFFF'

export const AUTH_LAYOUT_CONFIG = {
  [ROUTES.LIMITED_ACCESS]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.POLL_HIGHNOTE_CARD]: {
    bodyBackgroundColor: COLOR_WHITE,
    hideAppFooter: true
  },
  [ROUTES.LOCKED_ACCOUNT]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.REVIEW_ACCOUNT]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.QUESTIONNAIRE.START]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.QUESTIONNAIRE.QUESTIONS]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.QUESTIONNAIRE.END]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.BILL_CATEGORIES]: {
    hideAppFooter: true
  },
  [ROUTES.PRODUCT_TOURS.WELCOME]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.PRODUCT_TOURS.LINK_FIRST_BILL_EDUCATION]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.UPLOAD_DOCUMENTS]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showZendeskChat: true,
  },
  [ROUTES.BANK_CONNECTION_ISSUE]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showZendeskChat: true,
  },
  [ROUTES.CONTACT_SUPPORT]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showZendeskChat: true,
  },
  [ROUTES.ONBOARDING_CREDIT_SCORE]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CHOOSE_SUBSCRIPTION]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.LINK_BANK_ACCOUNT]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.DASHBOARD]: {
    topOffset: true,
  },
  [ROUTES.BANK_BALANCE]: {
    topOffset: true,
    hideAppFooter: true,
    showZendeskChat: false
  },
  [ROUTES.CHOOSE_GOALS]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CONFIRM_IDENTITY.ROOT]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CONFIRM_IDENTITY.IDENTITY]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CONFIRM_IDENTITY.ADDRESS]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CONFIRM_IDENTITY.VERIFY_PHONE_NUMBER]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CONFIRM_IDENTITY.SUMMARY]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.CONFIRMATION]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.SPEND_LIMIT]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SUBSCRIPTION]: {
    topOffset: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.REWARDS]: {
    topOffset: true,
  },
  [ROUTES.REWARD_BADGES]: {
    topOffset: true,
  },
  [ROUTES.REWARD_REDEEM]: {
    topOffset: true,
  },
  [ROUTES.REWARD_REDEEM_CONFIRM]: {
    topOffset: true,
  },
  [ROUTES.REWARD_REDEEM_SUCCESS]: {
    topOffset: true,
  },
  [ROUTES.GOALS_NEW]: {
    topOffset: false,
    hideAppFooter: true,
  },
  [ROUTES.GOALS_SHOW]: {
    topOffset: true,
  },
  [ROUTES.NEW_BILL]: {
    // LayoutComponent: AddBillFlowLayout,
    hideAppFooter: true,
  },
  [ROUTES.LINK_PAYMENT]: {
    // LayoutComponent: AddBillFlowLayout,
    hideAppFooter: true,
  },
  [ROUTES.CONFIRM_LINK_PAYMENT]: {
    // LayoutComponent: AddBillFlowLayout,
    hideAppFooter: true,
  },
  [ROUTES.PAYMENT_HISTORY]: {
    topOffset: true,
  },
  [ROUTES.CREDIT_TOOLS]: {
    topOffset: true,
  },
  [ROUTES.CREDIT_TOOLS_DEBT_ANALYSIS]: {
    topOffset: true,
  },
  [ROUTES.CREDIT_TOOLS_SCORE_SIMULATOR]: {
    topOffset: true,
  },
  [ROUTES.CREDIT_TOOLS_SCORE_FACTORS]: {
    topOffset: true,
  },
  [ROUTES.CREDIT_TOOLS_REPORT]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.ACTIVATE_CREDIT_SCORE]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
    showOnboardingProgressSteps: true,
    show3monthGuaranteeHeader: true,
  },
  [ROUTES.POLL_CREDIT_SCORE]: {
    hideAppFooter: true,
    bodyBackgroundColor: COLOR_WHITE,
  },
  [ROUTES.SETTINGS.PERSONAL_INFORMATION]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SETTINGS.PROMO_CREDITS]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SETTINGS.MEMBERSHIP]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SETTINGS.PASSWORD]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SETTINGS.LEGAL]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SETTINGS.LINKED_ACCOUNTS]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.SETTINGS.DOCUMENTS]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.MANAGE_ACCOUNT]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.PAUSE_ACCOUNT]: {
    topOffset: true,
    hideAppFooter: true,
  },
  [ROUTES.CLOSE_ACCOUNT]: {
    topOffset: true,
  },
}
