import { useEffect } from 'react'
import CONFIG from '~/config'
import { isReCaptchaV3Enabled } from '~/features/ReCaptcha/utils'

const useReCAPTCHAv3 = () => {
  useEffect(() => {
    let script

    if (isReCaptchaV3Enabled) {
      script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${CONFIG.RECAPTCHA_V3_SITE_KEY}`
      document.head.appendChild(script)
    }

    return () => {
      if (script) {
        document.head.removeChild(script)
      }
    }
  }, [])
}

export default useReCAPTCHAv3
