import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const sizes = {
  sm: 'h-7.5 w-7.5',
  md: 'h-14 w-14',
  lg: 'h-20 w-20'
}

export default function Avatar(props) {
  const { url, label, size, className, ...other } = props
  return (
    url
      ? <img src={url} alt="avatar" className={classNames('rounded-full object-cover object-center', className, sizes[size])} {...other} />
      : (
        <div
          className={classNames(
            className,
            { 'text-sm': size === 'sm',
              'text-xl': size === 'md',
              'text-2xl': size === 'lg' },
            sizes[size],
            'bg-gray text-black rounded-full  font-semibold flex items-center justify-center uppercase'
          )}
          {...other}
        >
          {label || (
            <FontAwesomeIcon
              icon="far fa-user"
            />
          )}
        </div>
      )
  )
}

Avatar.propTypes = {
  url: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  label: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string
}

Avatar.defaultProps = {
  url: '',
  size: 'md',
  label: '',
  className: '',
  'data-testid': ''
}
