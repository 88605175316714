import {
  USER_TAG_TYPE,
} from '~/store/api/constants'

const getDebtReliefEndpoints = builder => ({
  fetchDebtReliefUser: builder.query({
    query: (params = {}) => ({
      url: '/debt_relief/fetch_user',
      params,
    }),
  }),
  verifyDebtReliefUserSSN: builder.mutation({
    query: body => ({
      url: '/debt_relief/verify_ssn',
      method: 'POST',
      body,
    }),
  }),
  registerDebtReliefUser: builder.mutation({
    query: body => ({
      url: 'debt_relief/registration',
      method: 'POST',
      body: {
        ...body,
        passwordConfirmation: body.password,
      }
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
})

export default getDebtReliefEndpoints
