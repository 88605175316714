import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Button from '~/components/ui/Button'
import BankAccountLink from '~/features/BankAccountManagement/Link'
import Loader from '~/components/ui/Loader'

import { BANK_LINK_REASONS } from '../constants'

const LinkAccountV3 = props => {
  const {
    accounts,
    isLoading,
    onChimeModalOpen,
    onLoading,
    onSuccess,
    btnText,
    buttonClassName,
    className,
    loaderClassName
  } = props

  const handleSuccess = useCallback(() => {
    onSuccess()
  }, [onSuccess])

  return (
    <>
      {isLoading ? (
        <Loader
          className={loaderClassName}
          data-testid="stellar-link-bank-accounts-loader-v3"
        />
      ) : (
        <>
          <BankAccountLink
            accounts={accounts}
            onLoading={onLoading}
            onSuccess={handleSuccess}
            variant="v2"
            btnText={btnText}
            btnClassName={buttonClassName}
            reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
          />
          <p className={`text-center ${className}`}>
            <Button
              size=""
              className="mx-1 underline"
              onClick={onChimeModalOpen}
              data-testid="stellar-link-bank-account-button-v2"
            >
              Click here
            </Button>
            if you don&apos;t have a compatible bank account.
          </p>
        </>
      )}
    </>
  )
}
LinkAccountV3.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  onChimeModalOpen: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
}
LinkAccountV3.defaultProps = {
  accounts: [],
  btnText: 'Connect bank',
  buttonClassName: '',
  className: '',
  loaderClassName: '',
}

const Memoized = React.memo(LinkAccountV3)
export default Memoized
