/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useNonScrollablePage } from '~/hooks/useNonScrollablePage'
import { ANIMATIONS as GENERAL_ANIMATIONS } from '~/constants'

import ExplodingConfettiAnimation from '~/components/animations/ExplodingConfetti'
import ConfettiAnimation from '~/components/animations/Confetti'
import StandardButton from '~/components/ui/Buttons/StandardButton'
import './styles.scss'

const sizes = {
  xs: 'max-w-xs h-auto',
  sm: 'w-80 h-auto',
  md: 'w-96 h-auto',
  lg: 'max-w-lg h-auto',
  fullScreen: 'w-screen h-screen',
}

const ANIMATIONS = {
  [GENERAL_ANIMATIONS.CONFETTI]: ConfettiAnimation,
  [GENERAL_ANIMATIONS.EXPLODING_CONFETTI]: ExplodingConfettiAnimation,
}

const Modal = props => {
  const {
    show,
    children,
    footerComponent,
    withCloseButton,
    onClose,
    size,
    className,
    animation,
    'data-testid': testId,
  } = props

  const isFullScreen = size === 'fullScreen'

  useNonScrollablePage(show)

  const handleClickOutside = useCallback(() => {
    onClose()
  }, [onClose])

  const handleClick = useCallback(event => {
    event.stopPropagation()
  }, [])

  const AnimationComponent = ANIMATIONS[animation]

  return (
    <ReactModal
      isOpen={show}
      closeTimeoutMS={300}
      overlayClassName="fixed inset-0 bg-steel bg-opacity-70 flex justify-center items-center z-30"
      className={classNames(sizes[size], 'outline-0 [&>*:not(button)]:outline-0')}
      onRequestClose={handleClickOutside}
    >
      <div
        data-dismiss="modal"
        className={classNames(
          'z-40 relative overflow-hidden overflow-y-auto bg-white',
          isFullScreen ? 'p-6 m-0 w-full h-full' : 'max-h-8/10 mx-4 rounded',
          className,
        )}
        data-testid={testId}
        onClick={handleClick}
      >
        {AnimationComponent ? (
          <AnimationComponent />
        ) : null}
        {isFullScreen || withCloseButton ? (
          <StandardButton
            size=""
            className="absolute top-4 right-4 p-2 z-10"
            onClick={onClose}
            data-testid="stellar-modal-close-btn"
          >
            <FontAwesomeIcon icon="fa-regular fa-xmark" className="text-2xl" />
          </StandardButton>
        ) : null}
        <div className="relative z-5">
          {children}
        </div>
      </div>
      <div className="relative z-5">
        {footerComponent}
      </div>
    </ReactModal>
  )
}
Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  withCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['', 'xs', 'sm', 'md', 'lg', 'fullScreen']),
  'data-testid': PropTypes.string,
  animation: PropTypes.string,
  footerComponent: PropTypes.node,
}

Modal.defaultProps = {
  show: false,
  children: null,
  withCloseButton: false,
  onClose: () => {},
  size: 'sm',
  'data-testid': '',
  animation: '',
  footerComponent: null,
}

export default Modal
