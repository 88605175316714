const getBillerEndpoints = builder => ({
  getBillCategories: builder.query({
    query: () => '/bill_categories',
  }),
  getBillers: builder.query({
    query: (params = {}) => ({
      url: '/billers',
      params,
    }),
  }),
  getBiller: builder.query({
    query: billerUuid => ({
      url: `/billers/${billerUuid}`,
    }),
  }),
})

export default getBillerEndpoints
