import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'

import { ROUTES } from '~/pages/constants'
import UserAvatar from '~/components/ui/UserAvatar'
import Button from '~/components/ui/Button'
import Link from '~/components/ui/Link'
import Popover from '~/components/ui/Popover'
import CompleteAccountSetupButton from '~/features/CompleteAccountSetup/Button'

import {
  getUserFirstName,
  getUserLastName,
} from '~/utils'
import { REGISTRATION_STEPS, USER_STATUSES } from '~/constants'

const UserMenuPopover = props => {
  const {
    user,
    showLimitedHeader,
    limitedAccess
  } = props

  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Popover
      target={(
        <UserAvatar
          user={user}
          size="sm"
          data-testid="stellar-app-header-menu-popover-avatar"
        />
      )}
      containerProps={{
        className: '-mx-3 min-w-72.5',
      }}
      data-testid="stellar-app-header-menu-popover"
    >
      <div
        className="pr-16 px-3 py-2"
        data-testid="stellar-app-header-menu"
      >
        <Link
          to={ROUTES.SETTINGS.PERSONAL_INFORMATION}
          className="no-underline"
          data-testid="stellar-app-header-menu-personal-info-link"
        >
          <UserAvatar
            user={user}
            className="mr-3"
          />
          {getUserFirstName(user)}
          {' '}
          {getUserLastName(user)}
        </Link>
      </div>
      { user?.registrationNextStep !== REGISTRATION_STEPS.ALL_SET && pathname.includes('settings') && !limitedAccess ? (
        <CompleteAccountSetupButton
          label="Complete your account"
          className="w-full no-underline text-base font-normal leading-6 hover:bg-deepspace-50 hover:rounded px-4 py-3 flex !justify-start"
          variant="mr"
          data-testid="stellar-app-header-menu-complete-account-settings-link"
        />
      ) : null}
      {user?.status !== USER_STATUSES.LOCKED ? (
        <Link
          to={ROUTES.SETTINGS.PERSONAL_INFORMATION}
          size="sm"
          className="w-full no-underline !text-base !font-normal leading-6 hover:bg-deepspace-50 hover:rounded px-4 py-3 flex !justify-start"
          color=" "
          data-testid="stellar-app-header-menu-account-settings-link"
        >
          Account settings
        </Link>
      ) : null}
      <Link
        href="https://support.stellarfi.com/hc/en-us"
        target="_blank"
        rel="noreferrer"
        size="sm"
        color=" "
        className="no-underline !text-base !font-normal leading-6 hover:bg-deepspace-50 hover:rounded px-4 py-3 w-full !flex !justify-start"
        data-testid="stellar-app-header-menu-help-center-link"
      >
        Help Center
      </Link>

      <Button
        size=""
        className="w-full flex !justify-start hover:bg-deepspace-50 hover:rounded px-4 py-3 !text-base !font-normal leading-6"
        onClick={() => zE('messenger', 'open')}
        data-testid="stellar-app-header-menu-help-btn"
        variant=" "
      >
        Chat With Us
      </Button>
      {!showLimitedHeader ? (
        <Link
          to={ROUTES.SETTINGS.LEGAL}
          size="sm"
          color=" "
          className="w-full no-underline !text-base !font-normal leading-6 hover:bg-deepspace-50 hover:rounded px-4 py-3 flex !justify-start"
          data-testid="stellar-app-header-menu-legal-link"
        >
          Legal
        </Link>
      ) : null}
      <Button
        size=""
        className="w-full flex !justify-start hover:bg-deepspace-50 hover:rounded px-4 py-3 !text-base !font-normal leading-6"
        variant=" "
        onClick={() => navigate(ROUTES.LOGOUT)}
        data-testid="stellar-app-header-menu-log-out-button"
      >
        Log Out
      </Button>
      <div className="px-4 pt-3">
        <Link
          size="xs"
          className="no-underline text-xs !font-medium leading-6 mr-2"
          rel="noreferrer"
          href="https://www.stellarfi.com/legal/privacy-policy"
          target="_blank"
          data-testid="stellar-app-header-menu-privacy-policy-link"
          color=" "
        >
          Privacy Policy
        </Link>
        -
        <Link
          size="xs"
          className="no-underline text-xs !font-medium leading-6 ml-2"
          color=" "
          rel="noreferrer"
          href="https://www.stellarfi.com/legal/stellar-terms-and-conditions"
          target="_blank"
          data-testid="stellar-app-header-menu-terms-of-use-link"
        >
          Terms of Use
        </Link>
      </div>
    </Popover>
  )
}
UserMenuPopover.propTypes = {
  showLimitedHeader: PropTypes.bool.isRequired,
  limitedAccess: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    status: PropTypes.string,
    identityFirstName: PropTypes.string,
    identityLastName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    registrationNextStep: PropTypes.string,
  }).isRequired,
}

const Memoized = React.memo(UserMenuPopover)
export default Memoized
