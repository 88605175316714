import React from 'react'

import { ROUTES } from '~/pages/constants'

import VerifyEmailPrompt from '~/features/VerifyEmailPrompt'
import CTALink from '~/components/ui/Links/CTALink'
import Button from '~/components/ui/Button'

export const ACTIVATE_CARD = 0
export const ADD_BILL_STEP = 1
export const BILL_PAYMENT_STEP = 2
export const CREDIT_SCORE_INCREASE = 3
export const ALL_SET_STEP = 4

export const STEPS_SEQUENCE = [
  ACTIVATE_CARD,
  ADD_BILL_STEP,
  BILL_PAYMENT_STEP,
  CREDIT_SCORE_INCREASE
]

export const STEPS = {
  [ACTIVATE_CARD]: {
    key: 'activate_card',
    label: 'Activate StellarFi card',
    getDescription: () => 'Unlock your credit score by providing more information about you.',
  },
  [ADD_BILL_STEP]: {
    key: 'add_first_bill',
    label: 'Link your first bill',
    getDescription: (user, onOpenLearnMoreModal) => {
      if (user.billCount > 0) {
        return user.emailVerified ? (
          <p>
            Finish adding your first bill by clicking the &quot;Finalize Setup&quot; link in your Linked Bills section below.
          </p>
        ) : (
          <p>
            We sent you a verification email. Once you verify your email, you&apos;ll get access to
            your StellarFi Bill Pay Card and can finish adding your first bill.
            <VerifyEmailPrompt className="ml-1" />
          </p>
        )
      }
      return (
        <>
          Link bills by using the StellarFi card as your payment method.
          {' '}
          <Button
            variant=""
            onClick={onOpenLearnMoreModal}
            className="!font-semibold underline"
            data-testid="stellar-dashboard-banner-onboarding-progress-step-add_first_bill-current-step-description-btn"
          >
            Learn more.
          </Button>
        </>
      )
    },
    getCta: (user, isPreMembershipPlanEducationEnabled, isTempOutageEnabled) => {
      if (user.billCount === 0) {
        return (
          <CTALink
            to={ROUTES.NEW_BILL}
            variant="contained"
            className={`w-full text-white ${isTempOutageEnabled ? 'bg-deepspace-300' : 'bg-lightspeed-600'}`}
            storePrevRoute
            disabled={isTempOutageEnabled}
            name="new bill test"
          >
            Link a bill
          </CTALink>
        )
      }
      return null
    },
  },
  [BILL_PAYMENT_STEP]: {
    key: 'return_payment',
    label: 'Complete a bill payment',
    getDescription: (user, onOpenLearnMoreModal) => (
      <>
        We&apos;ll pay your bill when it&apos;s due, then process the payment from your bank account.
        {' '}
        <Button
          variant=""
          onClick={onOpenLearnMoreModal}
          className="!font-semibold underline"
          data-testid="stellar-dashboard-banner-onboarding-progress-step-return_payment-current-step-description-btn"
        >
          Learn more.
        </Button>
      </>
    ),
  },
  [CREDIT_SCORE_INCREASE]: {
    key: 'credit_score_increase',
    label: 'Credit score increase',
    getDescription: (user, onOpenLearnMoreModal) => (
      <>
        We&apos;ll report monthly payments and a limit that grows with each paid bill. Link more bills to build your credit even faster.
        {' '}
        <Button
          variant=""
          onClick={onOpenLearnMoreModal}
          className="!font-semibold underline"
          data-testid="stellar-dashboard-banner-onboarding-progress-step-credit_score_increase-current-step-description-btn"
        >
          Learn more.
        </Button>
      </>
    )
  },
}
