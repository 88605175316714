import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import {
  ANIMATIONS,
  MODAL_IDS,
} from '~/constants'
import { formatCentsToDollars } from '~/utils'
import { removeModalFromQueue } from '~/store/modules/ui'
import Button from '~/components/ui/Button'
import Modal from '~/components/ui/Modal/Desktop'

const RewardPointsRedeemSuccessModal = props => {
  const {
    redeemedPoints,
  } = props

  const modal = useModal()
  const dispatch = useDispatch()

  const handleConfirm = useCallback(() => {
    modal.resolve()
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.REWARD_POINTS_REDEEM_SUCCESS }))
  }, [modal, dispatch])

  const formattedPoints = formatCentsToDollars(redeemedPoints, { maximumFractionDigits: 2 })

  return (
    <Modal
      show
      size="md"
      animation={ANIMATIONS.EXPLODING_CONFETTI}
      className="p-5"
    >
      <p className="font-semibold text-xl mb-3">
        Congrats!
      </p>
      <p className="mb-4">
        You have redeemed
        {' '}
        {formattedPoints}
        {' '}
        towards your next bill or membership fee.
      </p>
      <Button
        variant="outlined"
        size="sm"
        className="w-full"
        onClick={handleConfirm}
      >
        Yipee!
      </Button>
    </Modal>
  )
}
RewardPointsRedeemSuccessModal.propTypes = {
  redeemedPoints: PropTypes.number,
}
RewardPointsRedeemSuccessModal.defaultProps = {
  redeemedPoints: 0,
}

const Memoized = React.memo(RewardPointsRedeemSuccessModal)
export default NiceModal.create(Memoized)
