import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '~/components/ui/Avatar'
import {
  getUserFirstName,
  getUserLastName,
} from '~/utils'

const UserAvatar = props => {
  const {
    user,
    size,
    className,
    'data-testid': testId,
  } = props

  const label = `${getUserFirstName(user).charAt(0)}${getUserLastName(user).charAt(0)}`

  return (
    <Avatar
      label={label}
      url={user.avatarUrl}
      size={size}
      className={className}
      data-testid={testId}
    />
  )
}
UserAvatar.propTypes = {
  user: PropTypes.shape({
    identityFirstName: PropTypes.string,
    identityLastName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
  }).isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
}
UserAvatar.defaultProps = {
  size: 'md',
  className: '',
  'data-testid': 'user-avatar',
}

const Memoized = React.memo(UserAvatar)
export default Memoized
