import React from 'react'
import { ACTIONS } from '~/constants'
import StandardLink from '../StandardLink' // Adjust the import path as needed

const CTALink = props => (
  <StandardLink
    {...props}
    action={ACTIONS.CTA}
  />
)

export default CTALink
