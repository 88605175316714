/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Tippy from '@tippyjs/react'
import classNames from 'classnames'

const Popover = props => {
  const {
    className,
    target,
    containerProps,
    children,
    onOpen,
    onClose,
    ...others
  } = props

  const [visible, setVisible] = useState(false)

  const handleClose = useCallback(() => {
    setVisible(false)
    onClose()
  }, [setVisible, onClose])

  // allows popover targets to collapse menu on click instead of just outside click
  const handleToggle = useCallback(() => {
    setVisible(previouslyOpened => {
      if (previouslyOpened) {
        onClose()
        return false
      }

      onOpen()
      return true
    })
  }, [setVisible, onClose, onOpen])

  return (
    <Tippy
      theme="light-border"
      animation="shift-toward-extreme"
      interactive
      allowHTML
      arrow={false}
      placement="bottom-end"
      delay={[0, 30000]}
      maxWidth="none"
      visible={visible}
      onClickOutside={handleClose}
      content={(
        <div
          className={`p-2 ${containerProps?.className || ''}`}
          onClick={handleClose}
          onScroll={containerProps?.onScroll}
        >
          {children}
        </div>
      )}
    >
      <span
        tabIndex="0"
        className={classNames('cursor-pointer', className, {
          visible
        })}
        onClick={handleToggle}
        {...others}
      >
        {target}
      </span>
    </Tippy>
  )
}
Popover.propTypes = {
  className: PropTypes.string,
  target: PropTypes.node,
  containerProps: PropTypes.shape({
    className: PropTypes.string,
    onScroll: PropTypes.func,
  }),
  children: PropTypes.node,
  'data-testid': PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}
Popover.defaultProps = {
  className: '',
  target: null,
  containerProps: {
    onScroll: () => {},
  },
  children: null,
  'data-testid': '',
  onOpen: () => {},
  onClose: () => {},
}

export default Popover
