// TODO: remove this file and replace current imports with shared repo code
/* eslint-disable default-param-last */
import humanizeDuration from 'humanize-duration'
import { DateTime, Duration } from 'luxon'
import milliseconds from 'milliseconds'
import { TIME_OF_DAY } from '~/constants'

/**
 * Returns a valid DateTime object by handling various input types
 * @param {Date|DateTime|string|number} dateOrString
 * @param {{locale: string, zone: string}} opts
 * @returns DateTime object
 */
export const getDateTime = (dateOrString, opts) => {
  if (dateOrString === 'now') return DateTime.local()
  if (DateTime.isDateTime(dateOrString)) return dateOrString
  if (typeof dateOrString === 'number' || !Number.isNaN(Number(dateOrString))) {
    return DateTime.fromMillis(Number(dateOrString), opts)
  }
  const isString = typeof dateOrString === 'string'
  let date = isString
    ? DateTime.fromISO(dateOrString, opts)
    : DateTime.fromJSDate(dateOrString, opts)
  if (!date.isValid && dateOrString && isString) {
    date = DateTime.fromJSDate(new Date(dateOrString), opts)
  }
  return date
}

/**
 * Returns date or transformed date from the input
 * @param {Date|string} dateOrString
 * @returns Date
 */
export const getDate = dateOrString => {
  if (dateOrString instanceof Date) return dateOrString
  return getDateTime(dateOrString).toJSDate()
}

/**
 * @typedef {Object} DateFormat
 * @property {string} [year]
 * @property {string} [month]
 * @property {string} [day]
 * @property {string} [hour]
 * @property {string} [minute]
 * @property {string} [second]
 * @property {boolean} [hour12]
 */
/**
 * Constant with date formats for toLocaleString
 * @typedef {Object} DATE_FORMATS
 * @property {DateFormat} FULL_DATE Example: Tuesday, July 14, 2020
 * @property {DateFormat} FULL_TIME Example: 01:49:04 PM
 * @property {DateFormat} LONG_DATE Example: July 14, 2020
 * @property {DateFormat} LONG_TIME Example: 01:49 PM
 * @property {DateFormat} SHORT_DATE Example: 7/14/20
 * @property {DateFormat} DAY_MONTH_DATE Example: July 14
 * @property {DateFormat} SHORT_MONTH_DAY_DATE Example: Jul 14
 * @property {DateFormat} CLASSIC_DATE Example: 7/14/2020
 * @property {DateFormat} SHORT_DATETIME Example: 7/14/20, 1:49 PM
 * @property {DateFormat} SHORT_TIME Example: 1:49 PM
 * @property {DateFormat} SHORT_MONTH_DATE Example: Jul 14, 2020
 * @property {DateFormat} SHORT_MONTH_DATE Example: Thursday, December 15
 */
export const DATE_FORMATS = {
  FULL_DATE: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  },
  FULL_TIME: {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  LONG_DATE: {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  },
  LONG_TIME: {
    hour: '2-digit',
    minute: '2-digit',
  },
  SHORT_DATE: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  },
  DAY_MONTH_DATE: {
    month: 'long',
    day: 'numeric',
  },
  SHORT_MONTH_DAY_DATE: {
    month: 'short',
    day: 'numeric',
  },
  SHORT_MONTH_YEAR: {
    month: 'short',
    year: 'numeric',
  },
  CLASSIC_DATE: {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  },
  TWO_DIGIT_MONTH_DAY: {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  },
  SHORT_DATETIME: {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  },
  SHORT_TIME: {
    hour: 'numeric',
    minute: 'numeric',
  },
  TINY_DATE: {
    month: 'numeric',
    day: 'numeric',
  },
  TINY_DATETIME: {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  LONG_MONTH: {
    month: 'long',
    day: 'numeric',
  },
  SHORT_MONTH: {
    month: 'short',
    day: 'numeric',
  },
  SHORT_MONTH_DATE: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  SHORT_MONTH_DAY: {
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  },
  LONG_MONTH_DAY: {
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  },
  LONG_DATE_SHORT_MONTH: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  DAY_DATE: {
    month: 'long',
    day: 'numeric',
    weekday: 'short',
  },
}
/**
 * Returns formatted date
 * @param {Date|string} date
 * @param {(string|DateFormat)} format
 * @param {{locale: string, zone: string}} opts
 * @returns {string|DateTime} a string formatted according to the format specified
 */
export const formatDate = (
  date,
  format = DATE_FORMATS.LONG_DATE,
  opts
) => getDateTime(date, opts)
  .toLocaleString(format in DATE_FORMATS ? DATE_FORMATS[format] : format)
Object.assign(formatDate, DATE_FORMATS)

/**
 * Returns date difference between two dates
 * @param {DateTime} firstDate
 * @param {DateTime} [secondDate = DateTime.local]
 * @returns {{daysDiff: number, text: string}}
 */
export const getDateDiff = (firstDate, secondDate = 'now') => {
  const diffDuration = getDateTime(secondDate).diff(getDateTime(firstDate))
  const daysDiff = Math.floor(diffDuration.as('days'))
  const absDiff = Math.abs(daysDiff)

  let text = ''
  if (absDiff > 20) {
    text = humanizeDuration(Math.abs(diffDuration.as('milliseconds')), {
      round: true,
      units: [absDiff > 60 ? 'mo' : 'w'],
    })
  } else if (absDiff > 1) {
    text = humanizeDuration(Math.abs(diffDuration.as('milliseconds')), {
      round: true,
      units: ['d'],
    })
  } else if (absDiff === 1) {
    text = daysDiff > 0 ? 'yesterday' : 'tomorrow'
  } else {
    text = 'today'
  }

  return { daysDiff, text }
}

const DEFAULT_DUEDATE_OPTS = {
  after: ({ text }) => `${text} ago`,
  before: ({ text }) => `in ${text}`
}
/**
 * Returns time due to provided date
 * @param {DateTime|string} [dueDate]
 * @returns {{daysDiff: number, text: string}}
 */
export const getDueDateDiff = (dueDate, other, opts = DEFAULT_DUEDATE_OPTS) => {
  if (!dueDate) return { daysDiff: 0, text: '' }

  const diff = getDateDiff(getDateTime(dueDate), other)
  const { daysDiff } = diff

  if (opts.default) {
    return { daysDiff, text: opts.default(diff) }
  }
  if (daysDiff > 1) {
    return { daysDiff, text: opts.after(diff) }
  }
  if (daysDiff < -1) {
    return { daysDiff, text: opts.before(diff) }
  }

  return diff
}

/**
 * Returns time difference between two dates
 * @param {DateTime} firstDT
 * @param {DateTime} [secondDT = DateTime.local]
 * @returns {string}
 */
export const getTimeDiff = (firstDT, secondDT = getDateTime('now')) => {
  const diff = secondDT.diff(firstDT).as('milliseconds')
  const humanized = humanizeDuration(Math.abs(diff), { largest: 1 })

  if (milliseconds.seconds(60) > Math.abs(diff)) {
    return 'now'
  }

  return diff > 0 ? `${humanized} ago` : `in ${humanized}`
}

/**
 * Checks dates if they are equal
 * @param {DateTime} left
 * @param {DateTime} right
 * @returns {boolean}
 */
export const isEqualDateTime = (left, right) => DateTime.isDateTime(left) && DateTime.isDateTime(right) && left.equals(right)

export const shortDurationHumanizer = humanizeDuration.humanizer({
  language: 'shortEn',
  languages: {
    shortEn: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms',
    }
  },
  delimiter: ' ',
  largest: 2,
  round: true,
  spacer: '',
  units: ['y', 'w', 'd', 'h', 'm', 's'],
})

/**
 * Returns time difference between provided dates and current time with short names
 * @param {DateTime} from
 * @param {DateTime} to? default to now
 * @returns {string}
 */
export const getShortDateDiff = (from, to = 'now', opts) => {
  const diff = getDateTime(to).diff(getDateTime(from))
  if (Math.abs(diff.as('minutes')) < 1) return '<1m'
  return shortDurationHumanizer(diff.as('milliseconds'), opts)
}

/**
 * Converts duration string to milliseconds
 * Example of duration: '1 09:00:00.1111'
 * @param {string} duration
 * @returns {number}
 */
export const durationToMillis = duration => {
  const parts = duration.split(/\D/g).slice(0, -1).map(value => Number(value) || 0)
  if (parts.length === 3) {
    const [hours, minutes, seconds] = parts
    return Duration.fromObject({ hours, minutes, seconds }).as('milliseconds')
  }
  if (parts.length === 4) {
    const [days, hours, minutes, seconds] = parts
    return Duration.fromObject({ days, hours, minutes, seconds }).as('milliseconds')
  }
  return 0
}

/**
 * Given a luxon dateTime object, it returns time in seconds of that day starting from 00:00
 * @param {DateTime} dateTime
 * @returns {number}
 */
export const convertDateTimeToSeconds = dateTime => (dateTime.hour * 60 * 60) + (dateTime.minute * 60) + dateTime.second

export const sortByDate = (accounts, key = '') => [...accounts]?.sort((a, b) => {
  if (b[key] < a[key]) return -1
  if (b[key] < a[key]) return 1
  return 0
})

export function getTimeOfDayText() {
  const now = new Date()
  const hrs = now.getHours()

  if (hrs < 12) {
    return TIME_OF_DAY.morning
  }

  if (hrs < 18) {
    return TIME_OF_DAY.afternoon
  }

  return TIME_OF_DAY.evening
}
