import React from 'react'
import PropTypes from 'prop-types'

import { useCan } from '~/hooks/useCan'
import Null from '~/components/ui/Null'

const Can = props => {
  const {
    children,
    permissions,
    any,
    Fallback,
  } = props

  const can = useCan(permissions, any)

  return can ? children : <Fallback />
}
Can.propTypes = {
  children: PropTypes.node.isRequired,
  permissions: PropTypes.shape({}).isRequired,
  any: PropTypes.bool,
  Fallback: PropTypes.elementType,
}
Can.defaultProps = {
  any: false,
  Fallback: Null,
}

export default React.memo(Can)
