import React from 'react'
import { ACTIONS } from '~/constants'
import StandardButton from '../StandardButton'

const ErrorButton = props => (
  <StandardButton
    {...props}
    action={ACTIONS.ERROR}
  />
)

export default ErrorButton
