import React, { useCallback, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import { addSnackbarMessage } from '~/store/modules/snackbar'
import { removeModalFromQueue } from '~/store/modules/ui'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import { useLazyResendEmailConfirmationQuery } from '~/store/api'
import { MODAL_IDS } from '~/constants'
import Button from '~/components/ui/Button'
import Modal from '~/components/ui/Modal'
import { getSnackbarMessage, SNACKBAR_MESSAGE_TYPES } from '~/pages/Settings/constants'

const PersonalInformationEmailModal = props => {
  const { email } = props

  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)
  const [hasResent, setHasResent] = useState(false)

  const [resendEmailConfirmation, resendEmailConfirmationApiResult] = useLazyResendEmailConfirmationQuery()

  const handleResendEmail = useCallback(async () => {
    const apiResult = await resendEmailConfirmation()
    if (!apiResult.error) {
      setHasResent(true)
    }
  }, [setHasResent, resendEmailConfirmation])

  const handleClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.PERSONAL_INFORMATION_EMAIL }))
    dispatch(addSnackbarMessage(getSnackbarMessage(isMobile, SNACKBAR_MESSAGE_TYPES.PAUSED)))
  }, [modal, dispatch, isMobile])

  return (
    <Modal
      show
      size="md"
      className="!md:mx-0 mx-2"
      withCloseButton
      onClose={handleClose}
      data-testid="stellar-settings-personal-information-email-modal"
    >
      <div className="p-5 px-5">
        <h2 className="font-semibold text-deepspace ">Verify it&apos;s you</h2>
        <p className="mt-3">
          We&apos;ve sent a verification email to
          <span className="pl-1 break-words">{email}</span>
          . Please verify the new email address by clicking the link in the email.
        </p>
        <div className="flex items-center mt-5 flex-col">
          <Button
            variant="contained"
            className="w-full mb-2"
            onClick={handleClose}
            data-testid="stellar-settings-personal-information-email-modal-cancel-btn"
          >
            Ok, got it
          </Button>
          {hasResent ? (
            <p
              className="font-semibold mt-1"
              data-testid="stellar-settings-personal-information-email-modal-verification-message"
            >
              A new email has been sent
              <FontAwesomeIcon
                icon="far fa-check"
                className="ml-2"
              />
            </p>
          ) : (
            <Button
              className="w-full"
              disabled={hasResent}
              variant="outlined"
              onClick={handleResendEmail}
              data-testid="stellar-settings-personal-information-email-modal-resend-btn"
            >
              Resend email
            </Button>
          )}
        </div>
        {resendEmailConfirmationApiResult.error ? (
          <p
            className="text-red-600 text-base mt-4 font-semibold"
            data-testid="stellar-settings-personal-information-email-modal-error"
          >
            <FontAwesomeIcon icon="far fa-triangle-exclamation" className="text-md mr-1" />
            {resendEmailConfirmationApiResult.error?.data?.errors?.[0]?.message}
          </p>
        ) : null}
      </div>

    </Modal>
  )
}

PersonalInformationEmailModal.propTypes = {
  email: PropTypes.string.isRequired,
}

export default NiceModal.create(PersonalInformationEmailModal)
