import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'

import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS, STELLAR_CS_EMAIL } from '~/constants'

import Link from '~/components/ui/Link'
import MobileModal from '~/components/ui/Modal/Mobile'

import './index.scss'

const TempOutageLearnMoreModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()

  const handleTempOutageLearnMoreModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.TEMP_OUTAGE_LEARN_MORE_MODAL }))
  }, [modal, dispatch])

  return (
    <MobileModal
      show
      withCloseButton
      className="bg-white w-full md:max-w-115 p-5 !rounded-none"
      containerClassName="h-full"
      onClose={handleTempOutageLearnMoreModalClose}
      data-testid="stellar-dashboard-modals-temp-outage-learn-more-modal"
      closeTimeoutMS={600}
      isFullScreen
      animationDirection="rightToLeft"
    >
      <div className="font-normal text-base">
        <h2 className="font-semibold">
          New cards coming soon!
        </h2>
        <p className="my-6">
          We&apos;re upgrading to a new and improved StellarFi Rewards Card!
          Soon you&apos;ll be able to earn points for every bill paid, enjoy even more compatible billers, and choose from flexible repayment options.
        </p>
        <p>
          Unfortunately, starting
          {' '}
          <span className="font-bold">
            September 1st
          </span>
          , bill payments will be unavailable until the new rewards cards are ready.
        </p>
        <p className="font-bold my-6">
          To avoid unpaid bills:
          <ul className="font-normal list-decimal list-inside pt-2">
            <li className="mb-2">Under &quot;Linked bills&quot; in your dashboard, review which bills are paid with your StellarFi card</li>
            <li>Visit each biller&apos;s payment settings and replace your StellarFi card with a new payment method </li>
          </ul>
        </p>
        <p>
          Due to the inconvenience,
          {' '}
          <span className="font-bold">
            we will NOT charge a membership fee while bill payments are paused.
          </span>
        </p>
        <p className="my-6">
          Credit reporting will continue and your score will not be impacted by the temporary pause in payment processing.
        </p>
        <p>
          We will alert you when your new rewards card is here!
        </p>
      </div>
      <p className="mt-6">
        Have questions? Please visit our
        {' '}
        <Link href="https://stellarfi.com/legal/upcoming-changes" target="_blank">
          member FAQs
        </Link>
        . For support, use the help chat feature or email
        {' '}
        <Link href={STELLAR_CS_EMAIL}>support@stellarfi.com</Link>
      </p>

    </MobileModal>
  )
}

const Memoized = React.memo(TempOutageLearnMoreModal)
export default NiceModal.create(Memoized)
