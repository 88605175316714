import { mapRoutesObjToArray } from '~/utils'

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  LOGIN_BY_TOKEN: '/login-by-token/:token',
  VERIFY_LOGIN: '/verify-login',
  VERIFY_LOGIN_WITH_EMAIL: '/verify-login-with-email',
  IMPERSONATION: '/impersonation/:impersonationToken',
  REQUEST_PASSWORD: '/request-password',
  RESET_PASSWORD: '/reset-password/:token',
  UNIVERSAL_LINK: '/universal-link',
  SIGNUP: '/signup',
  SIGNUP_DEBT_RELIEF: '/signup-debt-relief/:registrationToken',
  PARTNERS: {
    SIGNUP: '/partners/:onboardingToken/signup',
  },
  SUBSCRIPTION: '/app/subscription',
  IMPROVE_SCORE: '/app/improve-score',
  LINK_BANK_ACCOUNT: '/app/link-bank-account',
  LINK_MX_BANK_ACCOUNT: '/app/link-mx-bank-account',
  CONFIRMATION: '/app/confirmation',
  VERIFICATION: '/verification/:userToken/:verificationToken',
  EMAIL_CONFIRMATION: 'confirmation/:confirmationToken',
  DASHBOARD: '/app/dashboard',
  POLL_DOCUMENT_VERIFICATION: '/app/poll-document-verification',
  POLL_HIGHNOTE_CARD: '/app/poll-highnote-card',
  HIGHNOTE_CARD_ACTIVATION: '/app/highnote-card-activation',
  ACTIVATE_CREDIT_SCORE: '/app/activate-credit-score',
  CONFIRM_IDENTITY: {
    IDENTITY: '/app/confirm-identity/identity',
    ADDRESS: '/app/confirm-identity/address',
    VERIFY_PHONE_NUMBER: '/app/confirm-identity/verify-phone-number',
    SUMMARY: '/app/confirm-identity/summary',
  },
  ONBOARDING_CREDIT_SCORE: '/app/onboarding-credit-score',
  BANK_CONNECTION_ISSUE: '/app/bank-connection-issue',
  UPLOAD_DOCUMENTS: '/app/upload-documents',
  DOCUMENTS: '/app/documents/:document',
  HIGHNOTE_DOCUMENT_VERIFICATION: '/app/highnote-document-verification',
  CONTACT_SUPPORT: '/app/contact-support',
  CHOOSE_SUBSCRIPTION: '/app/choose-subscription',
  POLL_CREDIT_SCORE: '/app/poll-credit-score',
  GOALS_SHOW: '/app/goals/:goal_uuid',
  GOALS_NEW: '/app/goals/new',
  CHOOSE_GOALS: '/app/choose-goals',
  QUESTIONNAIRE: {
    START: '/app/questionnaire/start',
    QUESTIONS: '/app/questionnaire/questions',
    END: '/app/questionnaire/end',
  },
  LIMITED_ACCESS: '/app/limited-access',
  REVIEW_ACCOUNT: '/app/review-account',
  REWARDS: '/app/rewards',
  REWARD_BADGES: '/app/rewards/badges',
  REWARD_REDEEM: '/app/rewards/redeem',
  REWARD_REDEEM_CONFIRM: '/app/rewards/redeem/confirm',
  REWARD_REDEEM_SUCCESS: '/app/rewards/redeem/success',
  NEW_BILL: '/app/bills/new',
  BILL_CATEGORIES: '/app/bills/:bill_uuid/categories',
  LINK_PAYMENT: '/app/bills/:bill_uuid/link-payment',
  CONFIRM_LINK_PAYMENT: '/app/bills/:bill_uuid/link-payment/confirm',
  PAYMENT_HISTORY: '/app/payment-history',
  SPEND_LIMIT: '/app/limit',
  CREDIT_TOOLS: '/app/credit-tools',
  CREDIT_TOOLS_DEBT_ANALYSIS: '/app/credit-tools/debt-analysis',
  CREDIT_TOOLS_SCORE_SIMULATOR: '/app/credit-tools/score-simulator',
  CREDIT_TOOLS_SCORE_FACTORS: '/app/credit-tools/score-factors',
  CREDIT_TOOLS_REPORT: '/app/credit-tools/report',
  BANK_BALANCE: '/app/bank-balance',
  SETTINGS: {
    PERSONAL_INFORMATION: '/app/settings/personal-information',
    PROMO_CREDITS: '/app/settings/promo-credits', // TODO: has been changed to /app/rewards, should be removed eventually
    MEMBERSHIP: '/app/settings/membership',
    PASSWORD: '/app/settings/password',
    LEGAL: '/app/settings/legal',
    LINKED_ACCOUNTS: '/app/settings/linked-accounts',
    DOCUMENTS: '/app/settings/documents',
    // NOTIFICATIONS: '/app/settings/notifications',
    // PAYMENT_INFORMATION: '/app/settings/payment-information',
  },
  PRODUCT_TOURS: {
    WELCOME: '/app/product-tours/welcome',
    LINK_FIRST_BILL_EDUCATION: '/app/product-tours/link-first-bill-education',
  },
  MANAGE_ACCOUNT: '/app/manage-account',
  PAUSE_ACCOUNT: '/app/pause-account',
  CLOSE_ACCOUNT: '/app/close-account',
  LOCKED_ACCOUNT: '/app/locked-account',
  CLOSED_ACCOUNT: '/closed-account',
  SECURITY_PASSWORD_RESET: '/security-password-reset',
}

export const ROUTES_LIST = mapRoutesObjToArray(ROUTES)
