import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

import Button from '~/components/ui/Button'

const CardActivationSuccessModalContent = props => {
  const { handleScrollToAnchorTag, handleCloseModal } = props

  return (
    <>
      <h2 className="text-2xl font-semibold mb-2 mr-5">
        Your new card is ready!
      </h2>
      <div className="flex flex-col">
        <div className="flex flex-row items-center mb-2">
          <div className="bg-lightspeed-10 rounded-18 p-4 h-6 w-6 mr-2 flex items-center justify-center">
            <FontAwesomeIcon
              icon="far fa-circle-xmark"
              color="blue"
            />
          </div>
          Your old StellarFi card will be deactivated by July 31st
        </div>
        <div className="flex flex-row items-center mb-2">
          <div className="bg-lightspeed-10 rounded-18 p-4 h-6 w-6 mr-2 flex items-center justify-center">
            <FontAwesomeIcon
              icon="far fa-credit-card"
              color="blue"
            />
          </div>
          Your virtual card is ready to use immediately
        </div>
        <div className="flex flex-row items-center mb-2">
          <div className="bg-lightspeed-10 rounded-18 p-4 h-6 w-6 mr-2 flex items-center justify-center">
            <FontAwesomeIcon
              icon="far fa-pen"
              color="blue"
            />
          </div>
          Update your payment information for all linked bills ASAP.
        </div>
      </div>
      <div className="flex flex-col w-full mt-4">
        <Button
          variant="contained"
          color="blue"
          className="mb-4"
          onClick={handleScrollToAnchorTag}
          data-testid="stellar-upgrade-membership-modal-confirm-btn"
        >
          Start updating bills
        </Button>
        <Button
          variant="outlined"
          color="blue"
          onClick={handleCloseModal}
          data-testid="stellar-upgrade-membership-modal-cancel-btn"
        >
          Back do Dashboard
        </Button>
      </div>
    </>
  )
}
CardActivationSuccessModalContent.propTypes = {
  handleScrollToAnchorTag: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

export default React.memo(CardActivationSuccessModalContent)
