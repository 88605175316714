import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ONBOARDING_PROGRESS_STEPS } from '~/features/OnboardingProgressSteps/constants'

const LinearWithDotsOnboardingProgressBar = props => {
  const { currentStep, progress } = props

  return (
    <div className="relative flex h-5 items-center text-center md:w-100 w-59.5">
      <div className="flex justify-between items-center mb-1 absolute w-full">
        {ONBOARDING_PROGRESS_STEPS?.map(step => {
          const isActive = step.activeOnSteps[0] <= currentStep
          return (
            <div
              key={step.label}
              className={`w-5 h-5 rounded-full flex items-center justify-center ${isActive ? ' text-white bg-lightspeed' : 'text-lightspeed bg-white'}`}
            >
              <FontAwesomeIcon
                icon={isActive ? 'far fa-check' : 'far fa-scrubber'}
                className={`${isActive ? 'text-white w-2 h-2' : 'text-lightspeed w-5 h-5'}`}
              />
            </div>
          )
        })}
      </div>
      <div className="w-full h-[1px] bg-steel-300 rounded-3xl z-1">
        <div
          className="h-0.25 bg-lightspeed rounded-3xl"
          style={{ width: `${progress}%` }}
        />
      </div>

    </div>
  )
}
LinearWithDotsOnboardingProgressBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
}

const Memoized = React.memo(LinearWithDotsOnboardingProgressBar)
export default Memoized
