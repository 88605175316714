import React, { useEffect, Suspense, lazy } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Routes, Route, useLocation } from 'react-router-dom'

import { ROUTES } from '~/pages/constants'
import AppLayout from '~/layouts/App'
import SiteLayout from '~/layouts/Site'
import AuthRoute from '~/containers/AuthRoute'
import NonAuthRoute from '~/containers/NonAuthRoute'
import FadeInAnimation from '~/components/animations/FadeIn'
import AppLoading from '~/containers/App/Loading'
import PartnerRedirect from '~/containers/PartnerRedirect'

import NotFound from '~/components/ui/ErrorFallback'

// Async pages
const SignUp = lazy(() => import('~/pages/Signup'))
const SignupDebtRelief = lazy(() => import('~/pages/SignupDebtRelief'))
const PartnerSignup = lazy(() => import('~/pages/Partners/Signup'))
const Login = lazy(() => import('~/pages/Login'))
const Logout = lazy(() => import('~/pages/Logout'))
const Impersonation = lazy(() => import('~/pages/Impersonation'))
const ResetPasswordInstructions = lazy(() => import('~/pages/ResetPasswordInstructions'))
const ResetPassword = lazy(() => import('~/pages/ResetPassword'))
const UniversalLink = lazy(() => import('~/pages/UniversalLink'))
const Subscription = lazy(() => import('~/pages/Subscription'))
const LinkBankAccount = lazy(() => import('~/pages/LinkBankAccount'))
const Confirmation = lazy(() => import('~/pages/Confirmation'))
const Verification = lazy(() => import('~/pages/Verification'))
const Dashboard = lazy(() => import('~/pages/Dashboard'))
const PollDocumentVerification = lazy(() => import('~/pages/PollDocumentVerification'))
const PollHighnoteCard = lazy(() => import('~/pages/PollHighnoteCard'))
const SpendLimit = lazy(() => import('~/pages/SpendLimit'))
const CreditScore = lazy(() => import('~/pages/CreditScore'))
const CreditReport = lazy(() => import('~/pages/CreditReport'))
const ConfirmIdentity = lazy(() => import('~/pages/ConfirmIdentity'))
const PollCreditScore = lazy(() => import('~/pages/PollCreditScore'))
const ChooseGoals = lazy(() => import('~/pages/ChooseGoals'))
const NewGoal = lazy(() => import('~/pages/NewGoal'))
const Rewards = lazy(() => import('~/pages/Rewards'))
const Badges = lazy(() => import('~/pages/Badges'))
const Redeem = lazy(() => import('~/pages/Redeem'))
const RedeemConfirm = lazy(() => import('~/pages/RedeemConfirm'))
const RedeemSuccess = lazy(() => import('~/pages/RedeemSuccess'))
const Settings = lazy(() => import('~/pages/Settings'))
const PaymentHistory = lazy(() => import('~/pages/PaymentHistory'))
const CreditTools = lazy(() => import('~/pages/CreditTools'))
const NewBill = lazy(() => import('~/pages/NewBill'))
const BillCategories = lazy(() => import('~/pages/BillCategories'))
const LinkPayment = lazy(() => import('~/pages/LinkPayment'))
const ConfirmLinkPayment = lazy(() => import('~/pages/ConfirmLinkPayment'))
const ManageAccount = lazy(() => import('~/pages/ManageAccount'))
const PauseAccount = lazy(() => import('~/pages/PauseAccount'))
const CloseAccount = lazy(() => import('~/pages/CloseAccount'))
const ClosedAccount = lazy(() => import('~/pages/ClosedAccount'))
const LockedAccount = lazy(() => import('~/pages/LockedAccount'))
const SecurityPasswordReset = lazy(() => import('~/pages/SecurityPasswordReset'))
const WelcomeProductTour = lazy(() => import('~/pages/ProductTours/Welcome'))
const VerifyLoginSMS = lazy(() => import('~/pages/VerifyLogin/SMS'))
const VerifyLoginEmail = lazy(() => import('~/pages/VerifyLogin/Email'))
const BankBalance = lazy(() => import('~/pages/BankBalance'))
const OnboardingCreditScore = lazy(() => import('~/pages/OnboardingCreditScore'))
const ChooseSubscription = lazy(() => import('~/pages/ChooseSubscription'))
const UploadDocuments = lazy(() => import('~/pages/UploadDocuments'))
const Documents = lazy(() => import('~/pages/Documents'))
const HighnoteDocumentVerification = lazy(() => import('~/pages/HighnoteDocumentVerification'))
const BankConnectionIssue = lazy(() => import('~/pages/BankConnectionIssue'))
const ContactSupport = lazy(() => import('~/pages/ContactSupport'))
const LoginByToken = lazy(() => import('~/pages/LoginByToken'))
const LinkFirstBillEducation = lazy(() => import('~/pages/ProductTours/LinkFirstBillEducation'))
const QuestionnaireStart = lazy(() => import('~/pages/Questionnaire/Start'))
const QuestionnaireQuestions = lazy(() => import('~/pages/Questionnaire'))
const QuestionnaireEnd = lazy(() => import('~/pages/Questionnaire/End'))
const ReviewAccount = lazy(() => import('~/pages/ReviewAccount'))
const LimitedAccess = lazy(() => import('~/pages/LimitedAccess'))
const HighnoteCardActivation = lazy(() => import('~/pages/HighnoteCardActivation'))

const Pages = () => {
  const location = useLocation()

  // whenever route changes, we need to scroll to the top of the page (better UX for mobile)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Suspense fallback={<AppLoading loadingMessage="Loading page..." />}>
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          {/* animated */}
          <Route
            path={ROUTES.ROOT}
            element={(
              <PartnerRedirect>
                <NonAuthRoute>
                  <FadeInAnimation duration={0.5}>
                    <SignUp />
                  </FadeInAnimation>
                </NonAuthRoute>
              </PartnerRedirect>
            )}
          />
          <Route
            path={ROUTES.SIGNUP}
            element={(
              <PartnerRedirect>
                <NonAuthRoute>
                  <FadeInAnimation duration={0.5}>
                    <SignUp />
                  </FadeInAnimation>
                </NonAuthRoute>
              </PartnerRedirect>
            )}
          />
          <Route
            path={ROUTES.PARTNERS.SIGNUP}
            element={(
              <NonAuthRoute>
                <FadeInAnimation duration={0.5}>
                  <PartnerSignup />
                </FadeInAnimation>
              </NonAuthRoute>
            )}
          />

          {/* non-auth only */}
          <Route
            path="/"
            element={(
              <NonAuthRoute>
                <SiteLayout />
              </NonAuthRoute>
            )}
          >
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.VERIFY_LOGIN} element={<VerifyLoginSMS />} />
            <Route path={ROUTES.VERIFY_LOGIN_WITH_EMAIL} element={<VerifyLoginEmail />} />
            <Route path={ROUTES.REQUEST_PASSWORD} element={<ResetPasswordInstructions />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
          </Route>
          <Route
            path="/"
            element={(
              <NonAuthRoute />
            )}
          >
            <Route path={ROUTES.SIGNUP_DEBT_RELIEF} element={<SignupDebtRelief />} />
            <Route path={ROUTES.LOGIN_BY_TOKEN} element={<LoginByToken />} />
          </Route>

          {/* generic */}
          <Route path="/" element={<SiteLayout />}>
            <Route path={ROUTES.CLOSED_ACCOUNT} element={<ClosedAccount />} />
            <Route path={ROUTES.UNIVERSAL_LINK} element={<UniversalLink />} />
            <Route path={ROUTES.SECURITY_PASSWORD_RESET} element={<SecurityPasswordReset />} />
            <Route path={ROUTES.IMPERSONATION} element={<Impersonation />} />
          </Route>

          <Route path={ROUTES.VERIFICATION} element={<Verification />} />
          <Route path={ROUTES.EMAIL_CONFIRMATION} element={<Verification />} />
          <Route path={ROUTES.LOGOUT} element={<Logout />} />
          <Route path={ROUTES.SUBSCRIPTION} element={(<Subscription />)} />

          {/* auth only */}
          <Route
            path="app"
            element={(
              <AuthRoute>
                <AppLayout />
              </AuthRoute>
            )}
          >
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTES.POLL_DOCUMENT_VERIFICATION} element={<PollDocumentVerification />} />
            <Route path={ROUTES.POLL_HIGHNOTE_CARD} element={<PollHighnoteCard />} />
            <Route path={ROUTES.HIGHNOTE_CARD_ACTIVATION} element={<HighnoteCardActivation />} />
            <Route path={ROUTES.SPEND_LIMIT} element={<SpendLimit />} />
            <Route path={ROUTES.LINK_BANK_ACCOUNT} element={<LinkBankAccount />} />
            <Route path={ROUTES.CONFIRMATION} element={<Confirmation />} />
            <Route path={ROUTES.ACTIVATE_CREDIT_SCORE} element={<CreditScore />} />
            <Route path={ROUTES.BANK_BALANCE} element={<BankBalance />} />
            <Route path={ROUTES.QUESTIONNAIRE.START} element={<QuestionnaireStart />} />
            <Route path={ROUTES.QUESTIONNAIRE.QUESTIONS} element={<QuestionnaireQuestions />} />
            <Route path={ROUTES.QUESTIONNAIRE.END} element={<QuestionnaireEnd />} />
            <Route path={ROUTES.LIMITED_ACCESS} element={<LimitedAccess />} />
            <Route path={ROUTES.LOCKED_ACCOUNT} element={<LockedAccount />} />
            {Object.values(ROUTES.CONFIRM_IDENTITY).map(confirmIdentityRoute => (
              <Route
                key={confirmIdentityRoute}
                path={confirmIdentityRoute}
                element={<ConfirmIdentity />}
              />
            ))}
            <Route path={ROUTES.POLL_CREDIT_SCORE} element={<PollCreditScore />} />
            <Route path={ROUTES.CHOOSE_GOALS} element={<ChooseGoals />} />
            <Route path={ROUTES.PRODUCT_TOURS.WELCOME} element={<WelcomeProductTour />} />
            <Route path={ROUTES.PRODUCT_TOURS.LINK_FIRST_BILL_EDUCATION} element={<LinkFirstBillEducation />} />
            <Route path={ROUTES.GOALS_NEW} element={<NewGoal />} />
            <Route path={ROUTES.REWARDS} element={<Rewards />} />
            <Route path={ROUTES.REWARD_BADGES} element={<Badges />} />
            <Route path={ROUTES.REWARD_BADGES} element={<Redeem />} />
            <Route path={ROUTES.REWARD_REDEEM_CONFIRM} element={<RedeemConfirm />} />
            <Route path={ROUTES.REWARD_REDEEM_SUCCESS} element={<RedeemSuccess />} />
            <Route path={ROUTES.NEW_BILL} element={<NewBill />} />
            <Route path={ROUTES.BILL_CATEGORIES} element={<BillCategories />} />
            <Route path={ROUTES.LINK_PAYMENT} element={<LinkPayment />} />
            <Route path={ROUTES.CONFIRM_LINK_PAYMENT} element={<ConfirmLinkPayment />} />
            <Route path={ROUTES.PAYMENT_HISTORY} element={<PaymentHistory />} />
            <Route path={ROUTES.CREDIT_TOOLS} element={<CreditTools />} />
            <Route path={ROUTES.CREDIT_TOOLS_REPORT} element={<CreditReport />} />
            {Object.values(ROUTES.SETTINGS).map(settingsRoute => (
              <Route
                key={settingsRoute}
                path={settingsRoute}
                element={<Settings />}
              />
            ))}
            <Route path={ROUTES.MANAGE_ACCOUNT} element={<ManageAccount />} />
            <Route path={ROUTES.PAUSE_ACCOUNT} element={<PauseAccount />} />
            <Route path={ROUTES.CLOSE_ACCOUNT} element={<CloseAccount />} />
            <Route path={ROUTES.ONBOARDING_CREDIT_SCORE} element={<OnboardingCreditScore />} />
            <Route path={ROUTES.CHOOSE_SUBSCRIPTION} element={<ChooseSubscription />} />
            <Route path={ROUTES.UPLOAD_DOCUMENTS} element={<UploadDocuments />} />
            <Route path={ROUTES.DOCUMENTS} element={<Documents />} />
            <Route path={ROUTES.HIGHNOTE_DOCUMENT_VERIFICATION} element={<HighnoteDocumentVerification />} />
            <Route path={ROUTES.BANK_CONNECTION_ISSUE} element={<BankConnectionIssue />} />
            <Route path={ROUTES.CONTACT_SUPPORT} element={<ContactSupport />} />
            <Route path={ROUTES.REVIEW_ACCOUNT} element={<ReviewAccount />} />
          </Route>

          <Route
            path="*"
            element={(
              <NotFound
                message="The page you requested could not be found. Please check the URL and try again."
                isRouteNotFound
                location={location}
              />
            )}
          />
        </Routes>
      </AnimatePresence>
    </Suspense>
  )
}

const Memoized = React.memo(Pages)
export default Memoized
