import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch, useSelector } from 'react-redux'

import { MODAL_IDS } from '~/constants'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import { removeModalFromQueue } from '~/store/modules/ui'
import MobileModal from '~/components/ui/Modal/Mobile'
import DesktopModal from '~/components/ui/Modal/Desktop'

import ConfirmLinkPaymentModalBody from './ModalBody'

const ConfirmLinkPaymentCardModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handleConfirmLinkPaymentCardModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.CONFIRM_LINK_PAYMENT_CARD }))
  }, [modal, dispatch])

  return isMobile
    ? (
      <MobileModal
        show
        size="md"
        className="p-5"
        onClose={handleConfirmLinkPaymentCardModalClose}
        btnProps={{
          className: '!text-lightspeed-600 !border-lightspeed-600'
        }}
        withButton
      >
        <ConfirmLinkPaymentModalBody
          isMobile={isMobile}
          handleConfirmLinkPaymentCardModalClose={handleConfirmLinkPaymentCardModalClose}
        />
      </MobileModal>
    ) : (
      <DesktopModal
        show
        size="md"
        className="p-5"
        onClose={handleConfirmLinkPaymentCardModalClose}
      >
        <ConfirmLinkPaymentModalBody
          isMobile={isMobile}
          handleConfirmLinkPaymentCardModalClose={handleConfirmLinkPaymentCardModalClose}
        />
      </DesktopModal>
    )
}

const Memoized = React.memo(ConfirmLinkPaymentCardModal)
export default NiceModal.create(Memoized)
