import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'

import Button from '~/components/ui/Button'
import Modal from '~/components/ui/Modal'
import LinkBankAccount from '~/features/BankAccountManagement'
import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'

const RepaymentRelinkAccountModal = () => {
  const dispatch = useDispatch()
  const modal = useModal()

  const handleCloseModal = useCallback(() => {
    modal.resolve()
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.REPAYMENT_RELINK_ACCOUNT }))
  }, [modal, dispatch])

  return (
    <Modal
      show
      className="p-5"
      size="xs"
      withCloseButton
      onClose={handleCloseModal}
    >
      <div className="flex flex-col">
        <FontAwesomeIcon
          icon="far fa-triangle-exclamation"
          className="mt-2 mb-8 text-red text-5xl justify-self-center"
        />
        <p className="text-2xl font-semibold text-center mb-2">
          Missing linked bank account
        </p>
        <p className="text-center text-base mb-4">
          You do not have any linked active bank account. Please link a bank account to proceed.
        </p>
        <LinkBankAccount
          variant="v5"
          onSuccess={handleCloseModal}
        />
        <div className="flex items-center mt-6 justify-self-center">
          <Button
            variant="outlined"
            className="w-full"
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default NiceModal.create(RepaymentRelinkAccountModal)
