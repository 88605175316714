import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useDispatch } from 'react-redux'

import MobileModal from '~/components/ui/Modal/Mobile'
import { MODAL_IDS } from '~/constants'
import { removeModalFromQueue } from '~/store/modules/ui'

const TooltipModal = props => {
  const { header, description, children, ctaProps } = props

  const dispatch = useDispatch()
  const modal = useModal()

  const handleModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.TOOLTIP_MODAL }))
  }, [modal, dispatch])

  return (
    <MobileModal
      show
      size="md"
      className="p-5"
      onClose={handleModalClose}
      ctaProps={ctaProps}
      withButton
    >
      {header ? (
        <h3 className="text-2xl font-semibold mb-3">
          {header}
        </h3>
      ) : null}
      {React.isValidElement(description) ? description : (
        <p className="mb-3">
          {description}
        </p>
      )}
      <div className="mb-3">
        {children}
      </div>
    </MobileModal>
  )
}

TooltipModal.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  header: PropTypes.string,
  children: PropTypes.node,
  ctaProps: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.string,
    onClick: PropTypes.func
  }),
}

TooltipModal.defaultProps = {
  header: '',
  children: '',
  ctaProps: null,
}

export default NiceModal.create(TooltipModal)
