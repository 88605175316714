import React from 'react'
import PropTypes from 'prop-types'
import { formatDate } from '~/utils'

const HeaderModeText = props => {
  const {
    credit,
    isSubscribed,
    isScoreAvailable,
  } = props

  const { scoreDate } = credit || {}

  return isSubscribed ? (
    <div className="font-normal">
      {isScoreAvailable ? (
        <>
          <p className="leading-6">
            <strong>VANTAGE</strong>
            SCORE 3.0
          </p>
          <span
            className="text-xs text-white leading-6 font-medium"
            data-testid="stellar-credit-score-projection-chart-header-credit-available"
          >
            {`Last updated ${formatDate(scoreDate, 'SHORT_MONTH_DATE', { setZone: true })}`}
          </span>
        </>
      ) : (
        <p className="text-sm font-normal leading-6">
          Your credit score
          {' '}
          <br />
          {' '}
          will appear here shortly.
        </p>
      )}
    </div>
  ) : (
    <p
      className="text-lg font-bold text-white"
      data-testid="stellar-credit-score-projection-chart-header"
    >
      Sample score projection
    </p>
  )
}

HeaderModeText.propTypes = {
  credit: PropTypes.shape({
    score: PropTypes.number,
    scoreDate: PropTypes.string,
  }),
  isSubscribed: PropTypes.bool.isRequired,
  isScoreAvailable: PropTypes.bool.isRequired,
}

HeaderModeText.defaultProps = {
  credit: null
}

export default React.memo(HeaderModeText)
