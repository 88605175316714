import { combineReducers } from 'redux'

import { LOGOUT_USER } from '~/store/modules/auth/actions'
import api from '~/store/api'

import auth from './auth'
import snackbar from './snackbar'
import dimensions from './dimensions'
import analytics from './analytics'
import ui from './ui'

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth,
  snackbar,
  dimensions,
  analytics,
  ui,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default rootReducer
