import React from 'react'
import PropTypes from 'prop-types'

import { EXPERIAN_STATUSES, REGISTRATION_STEPS } from '~/constants/user'
import ProjectionsToolTip from '../../ToolTip'

const HeaderModeText = props => {
  const {
    user,
    projections,
  } = props

  const { experianStatus } = user
  const isSubscribed = experianStatus === EXPERIAN_STATUSES.SUBSCRIBED
  const isScoreAvailable = Boolean(user.credit) && user.registrationNextStep === REGISTRATION_STEPS.ALL_SET

  return isSubscribed ? (
    <p
      className="text-white md:text-deepspace"
      data-testid="stellar-credit-score-projection-chart-header-credit-available"
    >
      {isScoreAvailable ? (
        <span className="text-lg font-bold">
          {projections.length - 1}
          {' '}
          month projection
          <ProjectionsToolTip user={user} placement="bottom" className="text-white" />
        </span>
      ) : (
        <div className="flex items-start">
          <span className="text-sm font-normal leading-6">
            Your credit score projection
            {' '}
            <br />
            {' '}
            will appear here shortly.
          </span>
          <ProjectionsToolTip user={user} placement="bottom" className="text-white" />
        </div>
      )}

    </p>
  ) : (
    <p
      className="text-lg font-bold text-white"
      data-testid="stellar-credit-score-projection-chart-header"
    >
      Sample score projection
      <ProjectionsToolTip user={user} placement="bottom" className="text-white" />
    </p>
  )
}

HeaderModeText.propTypes = {
  user: PropTypes.shape({
    credit: PropTypes.shape({
      score: PropTypes.number,
    }),
    registrationNextStep: PropTypes.string,
    experianStatus: PropTypes.string.isRequired,
  }).isRequired,
  projections: PropTypes.arrayOf(PropTypes.shape({
    baseline: PropTypes.number.isRequired,
    lowerLimit: PropTypes.number.isRequired,
    upperLimit: PropTypes.number.isRequired,
  }).isRequired).isRequired,
}

export default React.memo(HeaderModeText)
