import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from '~/components/ui/Modal'

import { EXPERIMENTS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'
import { useMxConnect } from '~/features/BankAccountManagement/Mx/hooks/useMxConnect'
import { useFetchUserQuery } from '~/store/api'

import { ROUTES } from '~/pages/constants'

import { getMXErrorStateWarningMessage } from '~/features/BankAccountManagement/utils'
import LinkAlternativeProvider from './LinkAlternativeProvider'

const MxBankConnectModal = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const modal = useModal()

  const {
    enabled: isMxM2Enabled,
    isLoaded: isMxM2Loaded
  } = useExperiment(EXPERIMENTS.MX_M2)

  const {
    enabled: isMxPlaidFallbackEnabled
  } = useExperiment(EXPERIMENTS.MX_PLAID_FALLBACK)

  const {
    data = {},
  } = useFetchUserQuery()
  const { user = {} } = data

  const bankDetails = user.accounts?.[0]
  const errorStateWarningMessage = getMXErrorStateWarningMessage(bankDetails?.errorCode)

  const [showFooter, setShowFooter] = useState(false)
  const [isModalLoaded, setIsModalLoaded] = useState(false)
  const [isPlaidInProgress, setIsPlaidInProgress] = useState(false)

  const handleCloseModal = useCallback(() => {
    modal.remove()
    navigate(location.pathname)
  }, [modal, location.pathname, navigate])

  const {
    handleMxWidgetOpen,
    isLoading: isMxConnectLoading
  } = useMxConnect({ onModalClose: handleCloseModal, user })

  useEffect(() => {
    if (isMxM2Loaded && !isMxM2Enabled) {
      navigate(ROUTES.DASHBOARD)
    }
  }, [isMxM2Enabled, isMxM2Loaded, navigate])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMxPlaidFallbackEnabled) { setShowFooter(true) }
    }, 15000)

    return () => clearTimeout(timer)
  }, [isMxPlaidFallbackEnabled])

  useEffect(() => {
    if (!isModalLoaded) {
      handleMxWidgetOpen()
      setIsModalLoaded(true)
    }
  }, [isModalLoaded, handleMxWidgetOpen, setIsModalLoaded])

  return (
    <Modal
      show
      onClose={handleCloseModal}
      size="lg"
      className="max-w-122.5"
      footerComponent={
        showFooter
          ? (
            <LinkAlternativeProvider
              onClose={handleCloseModal}
              onLoading={() => setIsPlaidInProgress(true)}
            />
          ) : null
      }
    >
      {!isPlaidInProgress ? (
        <div className="m-5 p-5">
          {isMxConnectLoading ? (
            <div className="flex items-center justify-center">
              <div className="spinner mt-5 mb-6" role="status">
                <span>Loading...</span>
              </div>
            </div>
          ) : null}

          <div className="w-[320px]">
            {errorStateWarningMessage && !isMxConnectLoading ? (
              <div className="text-sm font-semibold">
                {errorStateWarningMessage}
              </div>
            ) : null}

            <div id="mx-widget-root" />
          </div>
        </div>
      ) : null}
    </Modal>
  )
}

const Memoized = React.memo(MxBankConnectModal)
export default NiceModal.create(Memoized)
