export const COLORS = {
  WHITE: 'rgba(255,255,255,1)',
  GREEN_DEFAULT_10: 'rgba(11, 30, 45, 1)',
  DEEPSPACE_DEFAULT: 'rgba(11,30,45,1)',
  STEEL_DEFAULT: 'rgba(113, 122, 129, 0.35)',
  FAKE_DATA: 'RGB(112, 117, 122,1)',
  TRANSPARENT: 'transparent',
  BORDER: 'rgba(238, 241, 243, 1)',
  GRADIENT: 'rgba(0, 75, 255, 0.2)',
  GRADIENT_START: 'rgba(1, 17, 29, 0.0)',
  GRADIENT_END: 'rgba(0, 75, 255, 0.2)',
  LINE_HEADER: 'rgba(255, 255, 255, 0)',
  LINE: 'rgba(0, 75, 255, 1)',
  POINT_BG_HEADER: 'rgba(0, 75, 255, 1)',
  POINT_BG: 'rgba(255, 255, 255, 0)',
  LOWER_LIMIT: 'rgba(0, 75, 255, 0.3)',
  UPPER_LIMIT_HEADER: 'rgba(22, 76, 166, 1)',
  UPPER_LIMIT: 'rgba(22, 76, 166, 0.0)',
  FAKE_DATA_XAXIS: 'rgb(82 93 100)'
}
