import {
  USER_TAG_TYPE,
  GOALS_TAG_TYPE,
} from '~/store/api/constants'

const getGoalEndpoints = builder => ({
  getUserGoals: builder.query({
    query: (params = {}) => ({
      url: '/goals',
      params,
    }),
    providesTags: [GOALS_TAG_TYPE],
  }),
  postGoals: builder.mutation({
    query: body => ({
      url: '/user_goals',
      method: 'POST',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, GOALS_TAG_TYPE]),
  }),
  updateGoal: builder.mutation({
    query: ({ id, body }) => ({
      url: `/user_goals/${id}`,
      method: 'PUT',
      body,
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
  deleteGoal: builder.mutation({
    query: id => ({
      url: `/user_goals/${id}`,
      method: 'DELETE',
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE, GOALS_TAG_TYPE]),
  }),
  uploadGoalAvatar: builder.mutation({
    query: ({ id, file }) => {
      const formData = new FormData()
      formData.append('avatar', file)

      return {
        url: `/user_goals/${id}`,
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: formData,
      }
    },
    invalidatesTags: (_, error) => (error ? [] : [USER_TAG_TYPE]),
  }),
})

export default getGoalEndpoints
