import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import ReactModal from 'react-modal'

import { ROUTES } from '~/pages/constants'
import { useFetchUserQuery, useFetchUserContextQuery } from '~/store/api'
import {
  isUserAuthenticated,
  isImpersonationAuthenticated
} from '~/utils'
import { setIsAuthorized, setIsImpersonationAuthorized } from '~/store/modules/auth'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'
import { useTimeout } from '~/hooks/useTimeout'
import { useWindowResizeListener } from '~/hooks/useWindowResizeListener'
import { useZendeskWidget } from '~/hooks/useZendeskWidget'
import { useSegment } from '~/hooks/useSegment'
import { useSentry } from '~/hooks/useSentry'
import { useGSI } from '~/services/google/SignIn/useGSI'
import { useCheckUserAccountStatus } from '~/hooks/useCheckUserAccountStatus'
import CheckUserAuthMode from '~/features/CheckUserAuthMode'
import SnackbarMessageQueue from '~/features/SnackbarMessageQueue'
import AppLoading from '~/containers/App/Loading'
import ModalManager from '~/containers/ModalManager'
import Pages from '~/pages'
import ErrorFallback from '~/components/ui/ErrorFallback'
import { useHideZendeskChat } from '~/hooks/useHideZendeskChat'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import useReCAPTCHAv3 from '~/features/ReCaptcha/hooks/useReCAPTCHAv3'

ReactModal.setAppElement('#root')

const App = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const isAuth = useSelector(selectIsUserAuthorized)
  const isMobile = useSelector(selectIsMobile)

  const {
    data = {},
    error,
    isLoading: isLoadingUser,
    isFetching: isFetchingUser,
  } = useFetchUserQuery(undefined, { skip: !isAuth })
  const {
    user,
  } = data

  useZendeskWidget(location)
  useSegment(location)
  useReCAPTCHAv3()
  useWindowResizeListener()
  useSentry(user)
  useGSI()
  useHideZendeskChat(isMobile && location.pathname !== ROUTES.CONTACT_SUPPORT)

  useCheckUserAccountStatus()

  // Firing and forgetting this call so subsequent experiment bucketing
  // won't need to wait for userContext to load
  useFetchUserContextQuery()

  // runs once on app init to properly set auth flag in redux
  useEffect(() => {
    const hasToken = isUserAuthenticated()
    const hasImpersonationToken = isImpersonationAuthenticated()

    dispatch(setIsAuthorized(hasToken))
    dispatch(setIsImpersonationAuthorized(hasImpersonationToken))
  }, [dispatch])

  // in case of user token expiration we remove the token from local storage
  useEffect(() => {
    if (isAuth && !isFetchingUser && error && error.status === 403) {
      navigate(ROUTES.LOGOUT)
    }
  }, [isAuth, isFetchingUser, error, navigate])

  const {
    timeoutFinished,
  } = useTimeout(0.5)

  const showLoadingState = isLoadingUser || !timeoutFinished

  return (
    <>
      {showLoadingState ? (
        <AppLoading loadingMessage="Prepare for liftoff" />
      ) : (
        <>
          {error && error.status >= 500 ? (
            <ErrorFallback
              message={`Server API error: ${error.data?.errors?.[0]?.message || 'please try again later'}`}
            />
          ) : (
            <>
              <CheckUserAuthMode user={user} />
              <Pages />
              <ModalManager />
            </>
          )}
        </>
      )}
      <SnackbarMessageQueue />
    </>
  )
}

const Memoized = React.memo(App)
export default Memoized
