import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '~/components/ui/Button'
import Loader from '~/components/ui/Loader'
import ListItem from '~/features/Notifications/ListItem'
import { useNonScrollablePage } from '~/hooks/useNonScrollablePage'

import './styles.scss'

const FullScreenNotificationsList = props => {
  const {
    isListOpen,
    notificationsList,
    hasReadNotifications,
    isFetchingNotifications,
    error,
    onScroll,
    onClose,
  } = props

  useNonScrollablePage(isListOpen)

  const nodeRef = useRef(null)

  return (
    <CSSTransition
      in={isListOpen}
      nodeRef={nodeRef}
      timeout={600}
      classNames="notifications-list"
      unmountOnExit
    >
      <div
        ref={nodeRef}
        onScroll={onScroll}
        className="bg-white w-full h-screen fixed top-0 left-0 py-4 px-6 z-20 overflow-y-scroll"
      >
        <div className="relative mb-5 flex items-center">
          <p className="text-center font-semibold text-lg flex-1">
            Notifications
          </p>
          <Button
            size=""
            variant=""
            className="px-2 py-0 absolute top-0 right-0"
            onClick={onClose}
          >
            <FontAwesomeIcon
              icon="fa-regular fa-xmark"
              size="xl"
            />
          </Button>
        </div>
        {notificationsList.map((notification, i) => (
          <ListItem
            key={notification.uuid}
            notification={notification}
            hasReadNotifications={hasReadNotifications}
            index={i}
          />
        ))}
        {isFetchingNotifications ? (
          <Loader size="small" />
        ) : null}

        {!isFetchingNotifications && !notificationsList.length ? (
          <p
            className="text-center"
            data-testid="stellar-notifications-empty"
          >
            No notifications at the moment
          </p>
        ) : null}

        {error ? (
          <p
            className="text-red"
            data-testid="stellar-notifications-error"
          >
            {JSON.stringify(error.data)}
          </p>
        ) : null}
      </div>
    </CSSTransition>
  )
}
FullScreenNotificationsList.propTypes = {
  isListOpen: PropTypes.bool.isRequired,
  notificationsList: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    readAt: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    iconColorName: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
  })).isRequired,
  isFetchingNotifications: PropTypes.bool.isRequired,
  hasReadNotifications: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
  onScroll: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
FullScreenNotificationsList.defaultProps = {
  error: null,
}

export default FullScreenNotificationsList
