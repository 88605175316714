import { USER_AGREEMENTS_TAG_TYPE } from './constants'

const getUserAgreementEndpoints = builder => ({
  getUserAgreement: builder.query({
    query: () => ({
      url: '/user_agreements',
      method: 'GET',
    }),
    providesTags: [USER_AGREEMENTS_TAG_TYPE],
  }),
  postUserAgreement: builder.mutation({
    query: acceptedVersion => ({
      url: '/user_agreements',
      method: 'POST',
      body: { acceptedVersion },
    }),
    invalidatesTags: (_, error) => (error ? [] : [USER_AGREEMENTS_TAG_TYPE]),
  })
})

export default getUserAgreementEndpoints
