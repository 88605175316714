import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { selectIsMobile } from '~/store/modules/dimensions/selectors'
import { useUpdateUserSettingsMutation } from '~/store/api'

import Modal from '~/components/ui/Modal'

import {
  setHideActivateBillPayCardModalItem,
} from '../utils'

import SwitchPaymentProviderPrompt from '.'

const SwitchPaymentProviderModal = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const modal = useModal()
  const isMobile = useSelector(selectIsMobile)

  const [updateUserSettings] = useUpdateUserSettingsMutation()

  const handleCloseModal = useCallback(async () => {
    setHideActivateBillPayCardModalItem()

    await updateUserSettings({ hideActivateBillPayCardModal: true })

    modal.remove()
    navigate(location.pathname)
  }, [updateUserSettings])

  return (
    <Modal
      show
      onClose={handleCloseModal}
      withCloseButton
      size={isMobile ? 'fullScreen' : 'md'}
      className="p-5 w-90 flex items-center justify-center"
    >
      <SwitchPaymentProviderPrompt isModal />
    </Modal>
  )
}

const Memoized = React.memo(SwitchPaymentProviderModal)
export default NiceModal.create(Memoized)
