import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { MODAL_IDS } from '~/constants'
import { useUpdateUserMutation } from '~/store/api'
import { addSnackbarMessage } from '~/store/modules/snackbar'
import { removeModalFromQueue } from '~/store/modules/ui'
import Modal from '~/components/ui/Modal'
import FormCheckboxField from '~/components/form/CheckboxField'
import Loader from '~/components/ui/Loader'
import Link from '~/components/ui/Link'
import Button from '~/components/ui/Button'

import { setHideSMSOptInModalItem } from './utils'

const SMSOptInModal = props => {
  const { uuid, smsOptIn } = props

  const modal = useModal()
  const dispatch = useDispatch()

  const [updateUser, updateUserApiResult] = useUpdateUserMutation()

  const {
    control,
    formState: {
      isValid,
      isSubmitting,
      isDirty
    },
    handleSubmit,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      uuid,
      smsOptIn
    },
  })

  const handleCloseModal = useCallback(() => {
    setHideSMSOptInModalItem()
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.SMS_OPT_IN }))
  }, [modal, dispatch])

  const handleCheckboxToggle = useCallback(value => (
    setValue('smsOptIn', value !== 'true', { shouldDirty: true })
  ), [setValue])

  const onSubmit = useCallback(async data => {
    const apiResult = await updateUser(data)

    if (!apiResult.error) {
      handleCloseModal()
      dispatch(addSnackbarMessage({
        message: 'Changes to your account have been saved.',
        status: 'success',
      }))
    }
  }, [updateUser, handleCloseModal, dispatch])

  return (
    <Modal
      show
      size="md"
      withCloseButton
      onClose={handleCloseModal}
      className="p-6"
      data-testid="stellar-dashboard-modals-sms-opt-in"
    >
      { updateUserApiResult.isLoading
        ? <Loader />
        : (
          <>
            <h1 className="font-semibold text-2xl mb-4">
              Stay in the loop
            </h1>
            <p className="text-base font-normal">
              Sign up for SMS and get up-to-date info on your score & StellarFi updates.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormCheckboxField
                control={control}
                name="smsOptIn"
                onChange={handleCheckboxToggle}
                className="mt-6 mb-5"
                key="personal-information-checkbox-edit"
                id="personal-information-checkbox-edit"
                data-testid="stellar-personal-information-checkbox-edit"
                label={(
                  <p className="text-xs leading-6 font-medium">
                    I agree to receive automated text messages from StellarFi to the mobile number used at sign-in.
                    Msg frequency may vary. Msg & data rates may apply. Reply HELP for help and STOP to cancel. See
                    {' '}
                    <Link
                      size="xs"
                      className="!font-bold mr-1"
                      rel="noreferrer"
                      href="https://www.stellarfi.com/legal/stellar-terms-and-conditions"
                      target="_blank"
                      data-testid="stellar-app-header-menu-terms-of-use-link"
                    >
                      Terms of Use
                    </Link>
                    &
                    <Link
                      size="xs"
                      className="!font-bold ml-1"
                      rel="noreferrer"
                      href="https://www.stellarfi.com/legal/privacy-policy"
                      target="_blank"
                      data-testid="stellar-app-header-menu-privacy-policy-link"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </p>
                )}
              />
              {updateUserApiResult.error ? (
                <p
                  data-testid="stellar-dashboard-modals-sms-opt-in-error"
                  className="text-red text-sm pb-2"
                >
                  {updateUserApiResult.error.data?.errors?.[0]?.message}
                </p>
              ) : null}
              <Button
                variant="contained"
                className="w-full"
                type="submit"
                disabled={!isValid || isSubmitting || !isDirty}
                data-testid="stellar-dashboard-modals-sms-opt-in-btn"
              >
                Opt in
              </Button>
            </form>
          </>
        ) }
    </Modal>
  )
}

SMSOptInModal.propTypes = {
  smsOptIn: PropTypes.bool.isRequired,
  uuid: PropTypes.string.isRequired,
}

export default NiceModal.create(SMSOptInModal)
