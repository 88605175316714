import { ROUTES } from '~/pages/constants'
import { SETTINGS_PAGE_LINKS } from '~/pages/Settings/Sidebar/constants'
import {
  checkFeatureFlags,
  checkPermissions,
  camelToSnakeCaseObj,
} from '~/utils'

const resolveDocumentsUpload = user => Boolean(user.canUploadDocuments || user.documents?.length)

export const getAvailableLinks = (user, links) => links.filter(link => {
  const has = link.featureFlags ? checkFeatureFlags(camelToSnakeCaseObj(user.features), link.featureFlags) : true
  const can = link.permissions ? checkPermissions({ ...user }, link.permissions) : true
  const canUploadDocuments = link.route === ROUTES.SETTINGS.DOCUMENTS ? resolveDocumentsUpload(user) : true
  const notOauthUser = link.route === ROUTES.SETTINGS.PASSWORD ? !user.oauthIdentity?.length : true

  return has && can && canUploadDocuments && notOauthUser
})

export const getSettingsPageLinks = user => getAvailableLinks(user, SETTINGS_PAGE_LINKS)
