import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useSelector, useDispatch } from 'react-redux'

import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'
import { selectIsMobile } from '~/store/modules/dimensions/selectors'

import MobileModal from '~/components/ui/Modal/Mobile'
import DesktopModal from '~/components/ui/Modal/Desktop'
import Button from '~/components/ui/Button'

import PrimeGuaranteeModalContent from './Content'

const PrimeGuaranteeModal = () => {
  const modal = useModal()
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  const handlePrimeGuaranteeModalClose = useCallback(() => {
    modal.remove()
    dispatch(removeModalFromQueue({ id: MODAL_IDS.PRIME_GUARANTEE_MODAL }))
  }, [modal, dispatch])

  return isMobile
    ? (
      <MobileModal
        show
        size="md"
        className="p-5"
        onClose={handlePrimeGuaranteeModalClose}
        withButton
      >
        <PrimeGuaranteeModalContent />
      </MobileModal>
    ) : (
      <DesktopModal
        show
        size="md"
        className="p-5"
        onClose={handlePrimeGuaranteeModalClose}
        withCloseButton
      >
        <PrimeGuaranteeModalContent />
        <Button
          variant="outlined"
          className="w-full mb-2"
          onClick={handlePrimeGuaranteeModalClose}
        >
          Ok, got it
        </Button>
      </DesktopModal>
    )
}

const Memoized = React.memo(PrimeGuaranteeModal)
export default NiceModal.create(Memoized)
