export const throttle = (callback, limit = 250) => {
  let waiting = false
  return (...args) => {
    if (!waiting) {
      callback.apply(this, args)
      waiting = true
      setTimeout(() => {
        waiting = false
      }, limit)
    }
  }
}
