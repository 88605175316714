import Score from '../Score'
import ScoreProjection from '../ScoreProjection'

export const CREDIT_VIEWS = {
  Score: {
    popoverLabel: 'Credit score',
    popoverTarget: 'Score',
    component: Score,
  },
  ScoreProjection: {
    popoverLabel: 'Score projection',
    popoverTarget: 'ScoreProjection',
    component: ScoreProjection,
  },
}
