import React, { useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useUpdateUserSettingsMutation } from '~/store/api'

import LaunchMobileAppCard from '~/features/LaunchMobileApp/Card'
import Modal from '~/components/ui/Modal'

const LaunchMobileAppModal = () => {
  const modal = useModal()
  const location = useLocation()
  const navigate = useNavigate()

  const [updateUserSettings] = useUpdateUserSettingsMutation()

  const handleCloseLaunchMobileAppModal = useCallback(async () => {
    const apiResult = await updateUserSettings({ dismissedDownloadAppModal: true })

    if (!apiResult.error) {
      modal.remove()
      navigate(location.pathname)
    }
  }, [modal, navigate, location, updateUserSettings])

  return (
    <Modal
      show
      size="md"
      withCloseButton
      onClose={handleCloseLaunchMobileAppModal}
      className="rounded-xl p-6"
      data-testid="stellar-dashboard-modals-launch-mobile-app"
    >
      <LaunchMobileAppCard inModal />
    </Modal>
  )
}

export default NiceModal.create(LaunchMobileAppModal)
