import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import {
  useFetchUserQuery,
  useUnpauseAccountMutation,
} from '~/store/api'
// import {
//   openUnpauseAccountModal,
// } from '~/components/CheckUserAccount/utils'
import { dismissSnackbarMessage } from '~/store/modules/snackbar'
import Button from '~/components/ui/Button'

const ReactivateAccount = props => {
  const {
    snackbarMessage,
  } = props

  const dispatch = useDispatch()
  const {
    data = {},
  } = useFetchUserQuery()
  const { user } = data
  const [unpauseAccount] = useUnpauseAccountMutation()

  const handleUnpauseAccount = useCallback(async () => {
    try {
      const apiResult = await unpauseAccount(user.uuid)
      if (!apiResult.error) {
        // openUnpauseAccountModal()
        // TODO: hotifx at the moment, should be changed
        window.location.reload()
        // TODO: add handling in case receiving err response, not showing any message yet
        dispatch(dismissSnackbarMessage(snackbarMessage.key))
      }
    } catch (err) {
      // TODO: err handling here
    }
  }, [user, unpauseAccount, dispatch, snackbarMessage.key])

  return (
    <p className="mr-10">
      In order to use your account, please
      <Button
        className="bg-transparent ml-1 underline"
        size=""
        onClick={handleUnpauseAccount}
      >
        reactivate.
      </Button>
    </p>
  )
}
ReactivateAccount.propTypes = {
  snackbarMessage: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }).isRequired,
}

export default React.memo(ReactivateAccount)
