import React, { useCallback, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Modal from '~/components/ui/Modal'
import Button from '~/components/ui/Button'
import Loader from '~/components/ui/Loader'

import {
  useFetchUserQuery,
  useFetchAccountTypesRequestQuery,
  useConnectPrimaryAccountMutation
} from '~/store/api'
import { addSnackbarMessage } from '~/store/modules/snackbar'
import { EXPERIMENTS, MODAL_IDS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'

import { ROUTES } from '~/pages/constants'
import { BANK_LINK_REASONS } from '~/features/BankAccountManagement/constants'
import AccountSelectOption from './AccountSelectOption'
import { groupAccountTypesByAvailability } from '../../utils'

const PrimaryAccountSelectModal = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const modal = useModal()
  const { data: { user = {} } = {}, refetch: refetchUser } = useFetchUserQuery()

  const {
    enabled: isPreMembershipPlanEducationEnabled,
    isLoaded: isPreMembershipPlanEducationLoaded,
  } = useExperiment(EXPERIMENTS.PRE_MEMBERSHIP_PLAN_EDUCATION)

  const reason = searchParams.get('reason')

  const [selectedAccount, setSelectedAccount] = useState(null)
  const [connectPrimaryAccount, connectPrimaryAccountResult] = useConnectPrimaryAccountMutation()

  const handleSelectAccount = useCallback(account => {
    setSelectedAccount(account)
  }, [setSelectedAccount])

  const handlePrimaryAccountSelectSubmit = useCallback(async () => {
    const apiResult = await connectPrimaryAccount(selectedAccount?.uuid)

    if (!apiResult.error) {
      modal.remove()

      if (reason === BANK_LINK_REASONS.ACCOUNT_SETUP && !user.subscriptionPlan) {
        isPreMembershipPlanEducationEnabled
          ? navigate(ROUTES.CHOOSE_SUBSCRIPTION)
          : navigate(ROUTES.SUBSCRIPTION)
      } else {
        navigate(location.pathname)
      }

      refetchUser()
      dispatch(addSnackbarMessage({
        message: 'Primary account has been connected',
        status: 'success',
      }))
    }
  }, [connectPrimaryAccount, selectedAccount, isPreMembershipPlanEducationEnabled, navigate, modal, dispatch, user, reason, location.pathname, refetchUser])

  const {
    data = {},
    error: errorFetchingAccountTypes,
    isLoading: isLoadingAccounts,
  } = useFetchAccountTypesRequestQuery()
  const { accounts: accountTypes = [] } = data
  const { enabled: enabledAccounts } = useMemo(() => groupAccountTypesByAvailability(accountTypes), [accountTypes])

  const handleCloseModal = useCallback(() => {
    modal.remove()
    navigate(location.pathname)
  }, [modal, location.pathname, navigate])

  return (
    <Modal
      show
      onClose={handleCloseModal}
      size="lg"
      className="m-5 p-5 h-auto"
    >
      <h3 className="text-3xl font-semibold mb-2">
        Connect account
      </h3>
      <p>Choose the account you wish to connect to your StellarFi account.</p>
      <div className="account-list pt-5 font-medium">
        <p className="text-sm pb-3">Select account</p>

        {isLoadingAccounts || !isPreMembershipPlanEducationLoaded ? (
          <Loader />
        ) : (
          <>
            {errorFetchingAccountTypes ? (
              <p className="text-red">
                Error while fetching account types
              </p>
            ) : (
              <div>
                {enabledAccounts.map(account => (
                  <AccountSelectOption
                    key={account.uuid}
                    account={account}
                    isSelected={account.uuid === selectedAccount?.uuid}
                    isDisabled={!account.enabled}
                    onSelectAccount={handleSelectAccount}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {connectPrimaryAccountResult.error ? (
        <p className="mb-4 text-red">
          {connectPrimaryAccountResult.error.data?.errors?.[0]?.message}
        </p>
      ) : null}
      <Button
        variant="contained"
        className="w-full mt-2"
        disabled={!selectedAccount || connectPrimaryAccountResult.isLoading}
        onClick={handlePrimaryAccountSelectSubmit}
      >
        Confirm
      </Button>
    </Modal>
  )
}

const Memoized = React.memo(PrimaryAccountSelectModal)
export default NiceModal.create(Memoized)
