import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import Link from '~/components/ui/Link'
import { MODAL_HASH_BASED_IDS } from '~/constants'

const RelinkMxLink = props => {
  const {
    title,
    className
  } = props

  const location = useLocation()

  return (
    <Link
      to={`${location.pathname}#${MODAL_HASH_BASED_IDS.MX_BANK_CONNECT}`}
      size=""
      className={className}
    >
      {title}
    </Link>
  )
}

RelinkMxLink.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

RelinkMxLink.defaultProps = {
  className: 'hover:underline bg-transparent',
  title: 'Relink your bank account'
}

export default RelinkMxLink
