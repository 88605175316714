import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'

import { dismissSnackbarMessage } from '~/store/modules/snackbar'
import {
  selectIsMobile,
} from '~/store/modules/dimensions/selectors'

import {
  getContainerClassNames,
  getMessageClassNames,
  getIcon,
} from '~/features/SnackbarMessageQueue/utils'
import CustomMessages from '~/features/SnackbarMessageQueue/CustomMessages'

const animations = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const Message = props => {
  const {
    snackbarMessage,
  } = props
  const dispatch = useDispatch()
  const isMobile = useSelector(selectIsMobile)

  useEffect(() => {
    if (snackbarMessage.mode !== 'sticky') {
      setTimeout(() => {
        dispatch(dismissSnackbarMessage(snackbarMessage.key))
      }, 3000)
    }
  }, [snackbarMessage, dispatch])

  return (
    <div
      data-testid="stellar-snackbar-message-block"
      className={`
      ${getContainerClassNames(snackbarMessage.position, isMobile)}
      ${snackbarMessage.containerClassName || ''}
      `}
    >
      <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.5 }}
        className={getMessageClassNames(snackbarMessage.status, isMobile)}
        role="alert"
      >
        <span className="justify-self-start h-full pt-1">
          {getIcon(snackbarMessage.status)}
        </span>
        {snackbarMessage.componentName ? (
          <CustomMessages snackbarMessage={snackbarMessage} />
        ) : (
          <span data-testid="stellar-snackbar-message">{snackbarMessage.message}</span>
        )}
      </motion.div>
    </div>
  )
}

Message.propTypes = {
  snackbarMessage: PropTypes.shape({
    key: PropTypes.string.isRequired,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    componentName: PropTypes.string,
    mode: PropTypes.oneOf(['', 'sticky']),
    status: PropTypes.string,
    position: PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    }),
    containerClassName: PropTypes.string,
  }).isRequired,
}

export default React.memo(Message)
