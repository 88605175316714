import React from 'react'
import PropTypes from 'prop-types'

const LinearOnboardingProgressBar = props => {
  const {
    progress,
  } = props

  return (
    <>
      <div className="flex justify-between items-center mb-1">
        <span className="text-xs font-semibold">
          0%
        </span>
        <span className="text-xs font-semibold">
          100%
        </span>
      </div>
      <div className="relative w-full h-3 bg-moonrock-400 rounded-3xl mb-5">
        <div
          className="h-3  bg-lightspeed rounded-3xl"
          style={{ width: `${progress}%` }}
        />
      </div>
    </>
  )
}
LinearOnboardingProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
}

const Memoized = React.memo(LinearOnboardingProgressBar)
export default Memoized
