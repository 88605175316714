import React, { useCallback, useState, memo, useRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition, SwitchTransition } from 'react-transition-group'

import Button from '~/components/ui/Button'
import { useSendVerificationEmailMutation } from '~/store/api'

import './styles.scss'

const VerifyEmail = props => {
  const { className } = props
  const [sendVerificationEmail] = useSendVerificationEmailMutation()
  const [resendButtonClicked, setResendButtonClicked] = useState(false)

  const initialRef = useRef(null)
  const finalRef = useRef(null)
  const nodeRef = resendButtonClicked ? finalRef : initialRef

  const handleSendVerificationEmail = useCallback(async () => {
    try {
      setResendButtonClicked(true)
      await sendVerificationEmail()
    } catch (err) {
      setResendButtonClicked(false)
    }
  }, [sendVerificationEmail])

  return (
    <SwitchTransition data-testid="stellar-verify-email-prompt">
      <CSSTransition
        key={resendButtonClicked ? 'outro' : 'intro'}
        nodeRef={nodeRef}
        addEndListener={done => nodeRef.current.addEventListener('transitionend', done, false)}
        classNames="fade"
      >
        <span ref={nodeRef} className={className}>
          {resendButtonClicked
            ? 'Click the link in your email to verify your account.'
            : (
              <>
                If you didn&apos;t receive an email,
                {' '}
                <Button
                  size=""
                  onClick={handleSendVerificationEmail}
                  data-testid="stellar-verify-email-prompt-resend-button"
                >
                  click here
                </Button>
                {' '}
                to re-send.
              </>
            )}
        </span>
      </CSSTransition>
    </SwitchTransition>
  )
}
VerifyEmail.propTypes = {
  className: PropTypes.string,
}
VerifyEmail.defaultProps = {
  className: '',
}

export default memo(VerifyEmail)
