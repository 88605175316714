import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, Outlet } from 'react-router-dom'

import { ROUTES } from '~/pages/constants'
import { selectIsUserAuthorized } from '~/store/modules/auth/selectors'

const NonAuthRoute = props => {
  const { children } = props
  const location = useLocation()

  const isAuth = useSelector(selectIsUserAuthorized)
  if (isAuth) {
    return <Navigate to={ROUTES.DASHBOARD} state={{ from: location }} replace />
  }

  return children || <Outlet />
}
NonAuthRoute.propTypes = {
  children: PropTypes.node,
}
NonAuthRoute.defaultProps = {
  children: null,
}

export default React.memo(NonAuthRoute)
