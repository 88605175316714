import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import LinkBankAccount from '~/features/BankAccountManagement'
import {
  ALTERNATIVE_BANKING_PARTNER_CLICKED,
} from '~/services/segment'

const LinkAlternativeProvider = props => {
  const {
    onClose,
    onLoading
  } = props

  const handleSuccess = useCallback(() => {
    onClose()
  }, [onClose])

  const trackClickedAlternativePartner = useSegmentEvents([ALTERNATIVE_BANKING_PARTNER_CLICKED])

  const handleClick = useCallback(() => {
    trackClickedAlternativePartner()
  }, [trackClickedAlternativePartner])

  return (
    <div className="mt-5 mb-6 m-4">
      <LinkBankAccount
        onSuccess={handleSuccess}
        onLoading={onLoading}
        onClick={handleClick}
        disabled={false}
        variant="v7"
        btnText="If you&apos;re having issues, you can try our alternative banking partner, Plaid"
        loaderClassName="fixed text-center top-2/4 left-2/4 -translate-y-1/2 -translate-x-1/2"
        forceUsePlaid
      />
    </div>
  )
}

LinkAlternativeProvider.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired
}

export default LinkAlternativeProvider
