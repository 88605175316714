import { useEffect } from 'react'

export const useHideZendeskChat = hideChat => {
  useEffect(() => {
    let styleElement

    if (hideChat) {
      styleElement = document.createElement('style')
      styleElement.id = 'zendesk-hide-style'
      styleElement.textContent = `
        /* hide zendesk */
        iframe[title*='Button to launch messaging window'] {
            display: none !important;
        }
        iframe[title*='Number of unread messages'] {
            display: none !important;
        }
        iframe[title*='Close message'] {
            display: none !important;
        }
        iframe[title*='Message from company'] {
            display: none !important;
        }
      `
      document.body.appendChild(styleElement)
    }

    return () => {
      if (styleElement) {
        const styleToRemove = document.getElementById('zendesk-hide-style')
        if (styleToRemove) {
          styleToRemove.remove()
        }
      }
    }
  }, [hideChat])
}
