import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  setPreviousRoute,
} from '~/store/modules/ui'
import {
  selectIsMobile,
} from '~/store/modules/dimensions/selectors'

// enhances callback to store prev route in redux
export const useSetPrevRoute = (callback, storePrevRoute = true) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const isMobile = useSelector(selectIsMobile)

  const handleCallback = useCallback(e => {
    if (isMobile && storePrevRoute) {
      dispatch(setPreviousRoute(`${location.pathname}${location.search}`))
    }

    if (callback) {
      callback(e)
    }
  }, [
    isMobile,
    storePrevRoute,
    dispatch,
    location.pathname,
    location.search,
    callback,
  ])

  return handleCallback
}
