import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  useFetchDocumentRequestQuery,
} from '~/store/api'
import {
  selectIsMobile,
  selectIsTablet
} from '~/store/modules/dimensions/selectors'
import Loader from '~/components/ui/Loader'
import Link from '~/components/ui/Link'

const SocureSoftFail = props => {
  const {
    className,
  } = props

  const isMobile = useSelector(selectIsMobile)
  const isTablet = useSelector(selectIsTablet)

  const {
    data: documentRequestData = {},
    isLoading: isLoadingDocumentRequest,
    error: documentRequestError,
  } = useFetchDocumentRequestQuery()
  const { userDocumentRequest = {} } = documentRequestData

  return isLoadingDocumentRequest ? (
    <Loader className="mb-6" />
  ) : (
    <div className={className}>
      {documentRequestError ? (
        <p className="text-red text-center text-sm mb-2">
          {documentRequestError.data?.errors?.[0]?.message}
        </p>
      ) : (
        <>
          <h1 className="text-2xl font-bold md:mb-2">
            Please resubmit your identification documents
          </h1>
          <p className="mb-4">
            We were unable to verify your identity. In most cases, simply resubmitting your ID and selfie using the tips below will solve the issue.
            {!isMobile && !isTablet ? (
              <span className="ml-1 font-bold">
                Use the QR code try again.
              </span>
            ) : null}
          </p>
          <p className="mb-4 md:mb-6">
            Feel free to contact
            {' '}
            <span className="font-bold">Member Success team </span>
            {' '}
            with any questions.
          </p>
          {isMobile || isTablet ? (
            <div className="my-3">
              {userDocumentRequest.url ? (
                <Link
                  to={userDocumentRequest.url}
                  variant="contained"
                  className="w-full"
                  data-testid="stellar-modal-upload-socure-docs-mobile"
                >
                  Try again
                </Link>
              ) : null}
            </div>
          ) : (
            <div className="flex justify-center my-6">
              <img
                src={userDocumentRequest.qrcode}
                alt="socure_document_verification"
                className="max-w-66 place-self-center"
              />
            </div>
          )}
          <div className="mb-3">
            <p className="font-bold text-2xl">
              Selfie upload tips:
            </p>
            <ol className="list-decimal pl-5 font-bold">
              <li>
                Take a live photo of yourself.
                <span className="ml-1 font-normal">
                  Screenshots and image uploads are not accepted.
                </span>
              </li>
              <li>
                Retake your selfie if it’s blurry.
                <span className="ml-1 font-normal">
                  The auto-capture feature sometimes snaps a blurry image. Hold as still as possible and retake if your image appears blurry.
                </span>
              </li>
              <li>
                Include your entire face.
                <span className="ml-1 font-normal">
                  Hold the camera with your arm fully extended and your whole face visible in the frame. Retake if needed.
                </span>
              </li>
              <li>
                No hats, scarves, masks, etc.
                <span className="ml-1 font-normal">
                  Do not obstruct your face in any way.
                </span>
              </li>
              <li>
                Use a plain background.
                <span className="ml-1 font-normal">
                  Stand in front of a blank wall for your selfie if possible.
                </span>
              </li>
              <li>
                Use good lighting.
                <span className="ml-1 font-normal">
                  Position yourself so that the light or window is in FRONT of you, not behind you.
                </span>
              </li>
            </ol>
          </div>
          <div className="mb-3">
            <p className="font-bold text-2xl">
              Document upload tips:
            </p>
            <ol className="list-decimal pl-5 font-bold">
              <li>
                Take a LIVE photo of your document.
                <span className="ml-1 font-normal">
                  Screenshots are not accepted.
                </span>
              </li>
              <li>
                Upload a readable, undamaged image:
                <span className="ml-1 font-normal">
                  We need all areas of your document, including the photo, text, and any barcodes, to be completely clear, clean, and unobstructed.
                </span>
              </li>
              <li>
                Use an official ID.
                <span className="ml-1 font-normal">
                  Acceptable items include your State ID, Driver’s License or US Passport.
                </span>
              </li>
              <li>
                Check expiration dates.
                <span className="ml-1 font-normal">
                  Expired documents are not accepted.
                </span>
              </li>
            </ol>
          </div>
        </>
      )}
    </div>
  )
}
SocureSoftFail.propTypes = {
  className: PropTypes.string,
}
SocureSoftFail.defaultProps = {
  className: '',
}

const Memoized = React.memo(SocureSoftFail)
export default Memoized
