import { configureStore } from '@reduxjs/toolkit'
import reduxLogger from 'redux-logger'

import CONFIG from '~/config'
import rootReducer from '~/store/modules'
import api from '~/store/api'
import fetchMiddleware from '~/store/middlewares/fetchMiddleware'
import sentryMiddleware from '~/store/middlewares/sentry'

export const setupStore = () => configureStore({
  reducer: rootReducer,
  // TODO: fetchMiddleware was temporary added to support old redux codebase, especially
  // the fetching logic (action creators were written in a specific manner instead of classic way)
  middleware: getDefaultMiddleware => [
    fetchMiddleware,
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['ui/addModalToQueue'],
        ignoredPaths: ['ui.modalQueue'],
      },
    }).concat(api.middleware),
    sentryMiddleware,
    ...(CONFIG.REDUX_LOGGER ? [reduxLogger] : []),
  ],
})

const store = setupStore()

export default store
