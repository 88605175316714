import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectIsImpersonationAuthorized } from '~/store/modules/auth/selectors'

const CheckUserAuthMode = props => {
  const { user } = props
  const isImpersonationMode = useSelector(selectIsImpersonationAuthorized)

  return (
    <>
      {isImpersonationMode ? (
        <div className="sticky top-0 z-50 bg-yellow text-center font-medium p-1 ">
          <span>
            You are logged in (impersonating) as
            {' '}
            <span className="font-bold">
              {user.fullName}
              {' '}
              (
              {user.email}
              )
            </span>
            . Proceed with caution
          </span>
        </div>
      ) : null}
    </>
  )
}

CheckUserAuthMode.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string
  })
}

CheckUserAuthMode.defaultProps = {
  user: {
    fullName: '',
    email: ''
  }
}

export default React.memo(CheckUserAuthMode)
