import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from '~/components/ui/Button'
import Modal from '~/components/ui/Modal'
import Link from '~/components/ui/Link'
import Loader from '~/components/ui/Loader'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'
import { USER_AGREEMENT_ACCEPTED } from '~/services/segment'
import { usePostUserAgreementMutation } from '~/store/api'
import { removeModalFromQueue } from '~/store/modules/ui'
import { MODAL_IDS } from '~/constants'

function UserAgreementModal({ newVersion }) {
  const modal = useModal()
  const dispatch = useDispatch()
  const [acceptAgreement, acceptAgreementApiResult] = usePostUserAgreementMutation()
  const trackUserAgreementAccepted = useSegmentEvents([USER_AGREEMENT_ACCEPTED])

  const handleConfirm = useCallback(async () => {
    const apiResult = await acceptAgreement(newVersion)
    if (!apiResult.error) {
      modal.resolve()
      modal.remove()
      dispatch(removeModalFromQueue({ id: MODAL_IDS.USER_AGREEMENT }))
      trackUserAgreementAccepted()
    }
  }, [newVersion, acceptAgreement, modal, dispatch, trackUserAgreementAccepted])

  return (
    <Modal
      show
      size="sm"
    >
      <div className="p-3">
        <h3 className="mb-3">User Agreement Update</h3>
        <p className="mb-3">
          Our User Agreement has been updated. Please review and accept our
          {' '}
          <Link
            href="https://stellarfi.com/legal/stellar-user-agreement"
            size="md"
            target="_blank"
          >
            updated terms
          </Link>
          {' '}
          to continue.
        </p>
        {acceptAgreementApiResult.error ? (
          <p
            className="text-sm text-red mt-3"
            data-testid="stellar-reset-password-api-error"
          >
            {acceptAgreementApiResult.error?.data?.errors?.[0]?.message}
          </p>
        ) : null}
        {acceptAgreementApiResult.isLoading ? (
          <Loader size="small" />
        ) : null}
        <div className="flex items-center mt-6">
          <Button
            variant="contained"
            className="mr-2 flex-grow"
            onClick={handleConfirm}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  )
}

UserAgreementModal.propTypes = {
  newVersion: PropTypes.string.isRequired,
}

export default NiceModal.create(UserAgreementModal)
