import React from 'react'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'

// import { selectIsMobile} from '~/store/modules/dimensions/selectors'

import DesktopModal from './Desktop'
// import MobileModal from './Mobile'

const Modal = props => <DesktopModal {...props} />
// const isMobile = useSelector(selectIsMobile)
// TODO For future use once implementing new modal mobile design for all modals
// return isMobile
// ? <MobileModal {...props}/>
// : <DesktopModal {...props}/>

Modal.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node,
  withCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  size: PropTypes.oneOf(['', 'xs', 'sm', 'md', 'lg', 'fullScreen']),
  'data-testid': PropTypes.string,
  animation: PropTypes.string,
}
Modal.defaultProps = {
  show: false,
  children: null,
  withCloseButton: false,
  onClose: () => {},
  size: 'sm',
  'data-testid': '',
  animation: '',
}

export default Modal
