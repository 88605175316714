import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import Link from '~/components/ui/Link'
import Notifications from '~/features/Notifications'
import UserMenu from '~/features/Headers/App/UserMenu'

import {
  CLOUDFLARE_IMAGE_IDS,
} from '~/constants'
import {
  setPreviousRoute,
  SPECIAL_ROUTES,
} from '~/store/modules/ui'
import { selectBackButtonRoute } from '~/store/modules/ui/selectors'
import { useNonScrollablePage } from '~/hooks/useNonScrollablePage'
import { ROUTES } from '~/pages/constants'
import { checkIsZeroLimitUser, getCloudflareImageURL } from '~/utils'

import CreditViews from '~/features/Credit/Views'
import BackButtonHeader from './BackButtonHeader'
import UserMenuFullScreenAnimatedPage from '../UserMenu/FullScreen/AnimatedPage'

const MobileAppHeader = props => {
  const {
    user,
    withPadding,
    onClick,
    delayBackButtonClick,
    showLimitedHeader,
    limitedAccess,
  } = props

  const dispatch = useDispatch()
  const location = useLocation()
  const backButtonRoute = useSelector(selectBackButtonRoute)

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
  const handleOpenUserMenu = useCallback(() => setIsUserMenuOpen(true), [setIsUserMenuOpen])
  const handleCloseUserMenu = useCallback((setPrevRoute = true) => {
    if (setPrevRoute) {
      if (!location.pathname.includes('settings')) {
        dispatch(setPreviousRoute(location.pathname))
      }
      dispatch(setPreviousRoute(SPECIAL_ROUTES.USER_MENU))
    }
    setIsUserMenuOpen(false)
  }, [dispatch, location.pathname, setIsUserMenuOpen])

  useNonScrollablePage(isUserMenuOpen)

  const isZeroLimitUser = checkIsZeroLimitUser(user)
  const showCreditViews = location.pathname === ROUTES.DASHBOARD && user.status && !isZeroLimitUser

  return (
    <>
      <div
        className="w-screen"
        data-testid="stellar-headers-app-mobile"
      >
        <div
          data-testid="stellar-headers-app-mobile-container"
          className={classNames('relative w-full px-4', { 'sm:pb-3': withPadding })}
        >
          {backButtonRoute ? (
            <BackButtonHeader
              backButtonRoute={backButtonRoute}
              onOpenUserMenu={handleOpenUserMenu}
              onClick={onClick}
              delayBackButtonClick={delayBackButtonClick}
            />
          ) : (
            <div className={`flex items-center relative ${showLimitedHeader ? 'py-4' : 'py-4 justify-between '}`}>
              { showLimitedHeader
                ? (
                  <>
                    <img
                      src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.LOGO)}
                      alt="stellar"
                      className="h-10 w-10 absolute mx-auto top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 "
                    />
                    <div data-testid="stellar-app-header-app-mobile-special-header">
                      <UserMenu
                        isMobile
                        user={user}
                        isOpen={isUserMenuOpen}
                        onOpen={handleOpenUserMenu}
                        onClose={handleCloseUserMenu}
                        limitedAccess={limitedAccess}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <UserMenu
                      isMobile
                      user={user}
                      isOpen={isUserMenuOpen}
                      onOpen={handleOpenUserMenu}
                      onClose={handleCloseUserMenu}
                      limitedAccess={limitedAccess}
                    />
                    <Link
                      to={ROUTES.DASHBOARD}
                      data-testid="stellar-app-header-link"
                    >
                      <img
                        src={getCloudflareImageURL(CLOUDFLARE_IMAGE_IDS.LOGO)}
                        alt="stellar"
                        className="h-10"
                      />
                    </Link>
                    <Notifications />
                  </>
                ) }
            </div>
          )}
        </div>
      </div>
      {showCreditViews ? (
        <CreditViews
          user={user}
          chartOnly
          inHeader
        />
      ) : null}
      <UserMenuFullScreenAnimatedPage
        user={user}
        isOpen={isUserMenuOpen}
        onClose={handleCloseUserMenu}
        showLimitedHeader={showLimitedHeader}
      />
    </>
  )
}
MobileAppHeader.propTypes = {
  user: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  withPadding: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  delayBackButtonClick: PropTypes.number,
  showLimitedHeader: PropTypes.bool.isRequired,
  limitedAccess: PropTypes.bool.isRequired,
}

MobileAppHeader.defaultProps = {
  onClick: () => null,
  delayBackButtonClick: 0
}

export default MobileAppHeader
